import React, { useContext, useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import useAxiosApiRequest from "../../hook/useAxiosApiRequest";
import {
  Card,
  CardContent,
  CardHeader,
  Grid,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import Breadcrumb from "../../breadCrumbs/navigation";
import FirmwareCard from "./FirmwareCard";
import ChangeFirmwareStatus from "./ChangeFirmwareStatus";
import { DRAFT, INREVIEW } from "../../constants/constants";
import "./ListFirmware.css";
import { AuthContext } from "../../App";

function FirmwareTimeline() {
  const { firmwareId } = useParams();
  const { apiRequest } = useAxiosApiRequest();
  const tenantID = localStorage.getItem("TenantID");
  const tenant = tenantID ? JSON.parse(tenantID) : null;
  const { userName } = useContext(AuthContext);
  const [firmwareData, setFirmwareData] = useState([]);
  const [statusTransitions, setStatusTransitions] = useState([]);
  const [isApprover, setIsApprover] = useState(false);
  useEffect(() => {
    getStatusTransitions();
    getFirmwareList();
  }, [firmwareId]);

  const getStatusTransitions = async () => {
    try {
      const response = await apiRequest({
        endpoint: `/firmwareversionservice/statusTransitions`,
        method: "GET",
        additionalParams: {
          headers: {
            "tenant-id": tenant,
          },
        },
        body: {},
      });
      setStatusTransitions(response);
    } catch (error) {
      console.error(error);
    }
  };

  const getFirmwareList = async () => {
    try {
      const response = await apiRequest({
        endpoint: `/firmwareversionservice/firmwareversion/${firmwareId}`,
        method: "GET",
        additionalParams: {
          headers: {
            "tenant-id": tenant,
          },
        },
        body: {},
      });
      //check for user role and change accordingly
      // setIsApprover(isSame)
      let approverList = response?.finalApproverList.map((i) => i.username);
      if (approverList.includes(userName)) {
        setIsApprover(true);
      }
      setFirmwareData(response);
    } catch (error) {
      console.trace(error);
    }
  };

  const showStatusChange = () => {
    if (isApprover) {
      return true;
    } else {
      return (
        firmwareData.currStatus === DRAFT ||
        firmwareData.currStatus === INREVIEW
      );
    }
  };
  return (
    <>
      <Breadcrumb />
      <Grid container>
        <Grid item xs={12}>
          {showStatusChange() && (
            <ChangeFirmwareStatus
              isApprover={isApprover}
              statusTransitions={statusTransitions}
              currentStatus={firmwareData.currStatus}
              approvers={firmwareData.finalApproverList}
              tenant={tenant}
            />
          )}
        </Grid>
      </Grid>

      <FirmwareCard firmware={firmwareData} />
      <Card sx={{ margin: "12px 0 0 0" }}>
        <CardHeader title="Action Timeline" />
        <CardContent>
          <TableContainer>
            <Table>
              <TableHead className="table-head">
                <TableRow>
                  <TableCell>Changed By</TableCell>
                  <TableCell>Changed Date</TableCell>
                  <TableCell>Status</TableCell>
                  <TableCell>Comment</TableCell>
                  <TableCell>Approvers</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {firmwareData?.timeline?.map((items, index) => {
                  let date = new Date(items.date).toDateString();
                  let time = new Date(items.date).toLocaleTimeString();
                  let listOfApprover = items.approverList.length
                    ? items.approverList.map((i) => i.username).join(" ")
                    : "-";
                  return (
                    <TableRow className="tableRowShade" key={index}>
                      <TableCell>{items.changedBy}</TableCell>
                      <TableCell>{date + " " + time}</TableCell>
                      <TableCell>{items.status}</TableCell>
                      <TableCell>{items.comment}</TableCell>
                      <TableCell>{listOfApprover}</TableCell>
                    </TableRow>
                  );
                })}
              </TableBody>
            </Table>
          </TableContainer>
        </CardContent>
      </Card>
    </>
  );
}

export default FirmwareTimeline;
