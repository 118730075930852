import React, { useEffect, useState } from "react";

import { Typography } from "@mui/material";
import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";

const CampaignSummary = () => {
    const [campaignDetails, setCampaignDetials] = useState([]);
    useEffect(()=> {
        const defaultData= {
            data:[
                {
                    id:1,
                    status:'Completed',
                    device_model:'modelA',
                    device_type:'Dashcam',
                    firmware_version:'2.0',
                    number_devices:'120',
                    date:'23/02/24'
                },
                {
                    id:2,
                    status:'Completed',
                    device_model:'modelp',
                    device_type:'OBD',
                    firmware_version:'4.3',
                    number_devices:'76',
                    date:'20/02/24'
                }
            ]
        }
        setCampaignDetials(defaultData.data)
    },[])
  return (
    <div>
         <Typography sx={{fontWeight: "bold" }}>
                   Campaign Summary
                </Typography>
         <Paper sx={{ width: "100%", overflow: "hidden" }}>
        <TableContainer sx={{ maxHeight: 440 }}>
          <Table stickyHeader aria-label="sticky table">
            <TableHead sx={{ maxHeight: 20 }}>
              <TableRow>
              <TableCell sx={{ fontWeight: 700 }} align="center">
                  Campaign Status
                </TableCell>
                <TableCell sx={{ fontWeight: 700 }} align="center">
                  Device Type
                </TableCell>
                <TableCell sx={{ fontWeight: 700 }} align="center">
                 Device Model
                </TableCell>
                <TableCell sx={{ fontWeight: 700 }} align="center">
                  Firmware Version
                </TableCell>
                <TableCell sx={{ fontWeight: 700 }} align="center">
                  Number of Devices
                </TableCell>
                <TableCell sx={{ fontWeight: 700 }} align="center">
                  Initiated Date
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {campaignDetails && campaignDetails
                .map((campaign, index) => {
                  return (
                    <TableRow
                      style={
                        index % 2
                          ? { backgroundColor: "rgba(72, 34, 180, 0.04)" }
                          : { backgroundColor: "white" }
                      }
                      hover
                      role="checkbox"
                      tabIndex={-1}
                      key={campaign.id}
                    >
                      <TableCell align="center">
                        {campaign.status}
                      </TableCell>
                      <TableCell align="center">
                        {campaign.device_type}
                      </TableCell>
                      <TableCell align="center">
                        {campaign.device_model}
                      </TableCell>
                      <TableCell align="center">
                        {campaign.firmware_version}
                      </TableCell>
                      <TableCell align="center">
                        {campaign.number_devices}
                      </TableCell>
                      <TableCell align="center">
                        {campaign.date}
                      </TableCell>
                     </TableRow>
                  );
                })}
            </TableBody>
          </Table>
        </TableContainer>
      </Paper>
    </div>
  )
}

export default CampaignSummary