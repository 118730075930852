import "bootstrap/dist/css/bootstrap.min.css";
import React, { useContext, useEffect, useState } from "react";

import { AuthContext } from "../../App";
import Breadcrumb from "../../breadCrumbs/navigation";
import Handler from "../../components/handler";
import useAxiosApiRequest from "../../hook/useAxiosApiRequest";
import InfoModal from "../../modals/infoModal";
import SuccessCreateModal from "../../modals/successModalCreate";
import DisplayDeviceList from "./displayDeviceList";
import "./deviceGroup.css";

export default function AddDeviceGroup() {
  const { statusCode, errormsg, loading, apiRequest } = useAxiosApiRequest();
  const { userName } = useContext(AuthContext);

  const tenantID = localStorage.getItem("TenantID");
  const tenant = tenantID ? JSON.parse(tenantID) : null;

  const [deviceTypes, setDeviceTypes] = useState([]);
  const [searchData, setSearchData] = useState([]);
  const [modelNames, setModelNames] = useState([]);
  const [showSearchData, setShowSearchData] = useState(false);
  const [openDialog, setOpenDialog] = useState(false); //success dialog
  const [selectedDeviceType, setSelectedDeviceType] = useState("");
  const [selectedModelName, setSelectedModelName] = useState("");
  const [fieldErrors, setFieldErrors] = useState({});
  const [firmwareVersionList, setFirmwareVersionList] = useState([]);
  const [selectedFirmwareVersion, setSelectedFirmwareVersion] = useState("");
  const [yearList, setYearList] = useState([]);
  const [selectedYear, setSelectedYear] = useState("");
  const [url, setURL] = useState(null);
  const [EnableSubmit, setEnableSubmit] = useState(false);
  const [totalDevices, setTotalDevices] = useState(null);
  const [excludedDevices, setExcludedDevices] = useState(null);
  const [confirmDialog, setConfirmDialog] = useState({
    isOpen: false,
    title: "",
    subtitle1: "",
  });

  //initializing state with required fields for device model creation
  const [formData, updateFormData] = useState({
    groupName: "",
    deviceType: "",
    modelName: "",
    description: "",
    firmwareVersion: "",
    deviceIDStart: "",
    deviceIDEnd: "",
  });

  const {
    groupName,
    deviceType,
    firmwareVersion,
    modelName,
    description,
    deviceIDStart,
    deviceIDEnd,
  } = formData || {};

  const deviceGroupCreatePayload = {
    name: groupName,
    deviceGroupType: selectedDeviceType,
    deviceGroupModel: selectedModelName,
    description: description,
    deviceType: selectedDeviceType,
    deviceModelName: selectedModelName,
    createdBy: userName,
  };

  const [isSearchEnabled, setIsSearchEnabled] = useState(false);

  const buildUrl = () => {
    let baseUrl = ``;
    let params = [];
    if (selectedFirmwareVersion) {
      params.push(`currentFirmwareVersion=${selectedFirmwareVersion}`);
    }
    if (selectedYear) {
      params.push(`year=${selectedYear}`);
    }
    if (deviceIDEnd) {
      params.push(`endSearchString=${deviceIDEnd}`);
    }
    if (deviceIDStart) {
      params.push(`startSearchString=${deviceIDStart}`);
    }
    if (params.length > 0) {
      baseUrl += `${params.join("&")}`;
    }
    return baseUrl;
  };

  useEffect(() => {
    getDeviceType();
    getSearchFields();
  }, []);

  useEffect(() => {
    if (selectedDeviceType) {
      getModelName();
    }
  }, [selectedDeviceType]);

  useEffect(() => {
    const getURL = async () => {
      const url = buildUrl();
      setURL(url);
    };

    if (
      selectedFirmwareVersion ||
      selectedYear ||
      deviceIDStart ||
      deviceIDEnd
    ) {
      getURL();
      setIsSearchEnabled(true);
    } else {
      setIsSearchEnabled(false);
    }
  }, [selectedFirmwareVersion, selectedYear, deviceIDStart, deviceIDEnd]);

  const handleChange = (e) => {
    setSelectedYear(e.target.value);
    updateFormData({
      ...formData,
      [e.target.id]: e.target.value,
    });
  };

  const handleChangeFirmwareversion = (e) => {
    setSelectedFirmwareVersion(e.target.value);
    updateFormData({
      ...formData,
      [e.target.id]: e.target.value,
    });
  };

  const onHandleCloseDialog = () => {
    setOpenDialog(false);
  };

  //mandatory field validation
  const validateForm = () => {
    const newErrors = {};
    if (!formData.deviceType.trim()) {
      newErrors.deviceType = "Select Device Type";
    }
    if (!formData.groupName.trim()) {
      newErrors.groupName = "Enter Group Name";
    }
    if (!formData.description.trim()) {
      newErrors.description = "Enter Description";
    }
    if (!formData.modelName.trim()) {
      newErrors.modelName = "Enter Model Name";
    }
    setFieldErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  const onHandleChange = (e) => {
    updateFormData({
      ...formData,
      [e.target.id]: e.target.value,
    });
    setFieldErrors({
      ...fieldErrors,
      [e.currentTarget.id]: "",
    });
  };

  const onHandleDeviceTypeChange = (e) => {
    updateFormData({
      ...formData,
      [e.target.id]: e.target.value,
    });
    setSelectedDeviceType(e.target.value);
    setFieldErrors({
      ...fieldErrors,
      [e.currentTarget.id]: "",
    });
  };

  const onHandleModelNameChange = (e) => {
    updateFormData({
      ...formData,
      [e.target.id]: e.target.value,
    });
    setSelectedModelName(e.target.value);
    setFieldErrors({
      ...fieldErrors,
      [e.currentTarget.id]: "",
    });
  };

  const onHandleModelNumberChange = (e) => {
    updateFormData({
      ...formData,
      [e.target.id]: e.target.value,
    });
    setFieldErrors({
      ...fieldErrors,
      [e.currentTarget.id]: "",
    });
  };

  const getYears = (dateStrings) => {
    return dateStrings.map((dateString) => new Date(dateString).getFullYear());
  };

  const getSearchFields = async () => {
    const response = await apiRequest({
      endpoint: `/deviceservice/deviceattributedetails?attributes=currentFirmwareVersion%2Cyear`,
      method: "GET",
      additionalParams: {
        headers: {
          "tenant-id": tenant,
        },
      },
      body: {},
    });
    const yearList = response?.year;
    const firmwareVersionList = response?.currentFirmwareVersion;
    if (Array.isArray(firmwareVersionList)) {
      const years = getYears(yearList);
      setYearList(years);
      setFirmwareVersionList(firmwareVersionList);
    }
  };

  const getDeviceListSearch = async () => {
    const response = await apiRequest({
      endpoint: `/deviceservice/devicelist/10/1/null/desc?deviceType.name=${selectedDeviceType}&deviceModel=${selectedModelName}&${url}`,
      method: "GET",
      additionalParams: {
        headers: {
          "tenant-id": tenant,
        },
      },
      body: {},
    });
    if (response) {
      setSearchData(response);
      setTotalDevices(response.inactiveGroupDeviceCount);
      setExcludedDevices(response.activeGroupDeviceCount);
      setShowSearchData(true);
    }
    if (response.inactiveGroupDeviceCount > 0) {
      setEnableSubmit(true);
    }
  };

  const getDeviceType = async () => {
    const response = await apiRequest({
      endpoint: `/deviceservice/masterdata/deviceType`,
      method: "GET",
      additionalParams: {
        headers: {
          "tenant-id": tenant,
        },
      },
      body: {},
    });
    const Data = response?.deviceMasterData;
    if (Array.isArray(Data)) {
      setDeviceTypes(Data);
    }
  };

  const getModelName = async () => {
    const response = await apiRequest({
      endpoint: `/deviceservice/devicemodel/allmodels/60/1/deviceType.name/${selectedDeviceType}/null/desc`,
      method: "GET",
      additionalParams: {
        headers: {
          "tenant-id": tenant,
        },
      },
      body: {},
    });
    const Data = response?.deviceModels;
    if (Array.isArray(Data)) {
      const devicemodel = [...new Set(Data.map((item) => item.modelName))];
      setModelNames(devicemodel);
    }
  };

  //sending post request for creation of device model
  const submitDeviceGroup = async () => {
    if (selectedYear !== "") {
      deviceGroupCreatePayload.year = selectedYear;
    }
    if (selectedFirmwareVersion !== "") {
      deviceGroupCreatePayload.firmwareVersion = selectedFirmwareVersion;
    }
    if (deviceIDStart !== "") {
      deviceGroupCreatePayload.deviceIdStartsWith = deviceIDStart;
    }
    if (deviceIDEnd !== "") {
      deviceGroupCreatePayload.deviceIdEndsWith = deviceIDEnd;
    }

    const response = await apiRequest({
      endpoint: `/devicegroupservice/devicegroup`,
      method: "POST",
      additionalParams: {
        headers: {
          "tenant-id": tenant,
        },
      },
      body: deviceGroupCreatePayload,
    });
    if (response && statusCode === 200) {
      setOpenDialog(true);
    }
  };

  const onHandleSubmit = (e) => {
    e.preventDefault();
    setConfirmDialog({
      ...confirmDialog,
      isOpen: false,
    });
    if (validateForm()) {
      submitDeviceGroup();
      updateFormData({
        groupName: "",
        deviceType: "",
        modelName: "",
        description: "",
        firmwareVersion: "",
        deviceIDStart: "",
        deviceIDEnd: "",
      });
    }
  };

  return (
    <Handler statusCode={statusCode} loading={loading} errormsg={errormsg}>
      <div>
        {openDialog && (
          <SuccessCreateModal
            openDialog={openDialog}
            onHandleClose={onHandleCloseDialog}
          />
        )}
        <InfoModal
          confirmDialog={confirmDialog}
          setConfirmDialog={setConfirmDialog}
        />
        <Breadcrumb />
        <div>
          {/* form for getting user inputs */}
          <form>
            <div className="filters form-group row ">
              <h4 className="deviceHeading">Add New Device Group</h4>

              <div className="form-group col-md-4 mb-2">
                <label id="mandatorylabels">Group Name</label>
                <input
                  type="string"
                  className="form-control"
                  value={groupName}
                  id="groupName"
                  placeholder="Enter Group Name"
                  maxLength={100}
                  onChange={onHandleModelNumberChange}
                />
                {fieldErrors.groupName && (
                  <p className="error-message">{fieldErrors.groupName}</p>
                )}
              </div>
              <div className="fform-group col-md-4 mb-2">
                <label id="mandatorylabels">Select Device Type</label>
                <select
                  className="form-control"
                  id="deviceType"
                  value={deviceType}
                  onChange={onHandleDeviceTypeChange}
                >
                  <option selected disabled value={""}>
                    Select Device Type
                  </option>
                  {deviceTypes &&
                    deviceTypes.map((getDeviceName) => (
                      <option key={getDeviceName.id}>
                        {getDeviceName.name}
                      </option>
                    ))}
                </select>
                {fieldErrors.deviceType && (
                  <p className="error-message">{fieldErrors.deviceType}</p>
                )}
              </div>
              <div className="form-group col-md-4 mb-2">
                <label id="mandatorylabels">Select Model Name</label>
                <select
                  className="form-control"
                  id="modelName"
                  value={modelName}
                  onChange={onHandleModelNameChange}
                >
                  <option selected disabled value={""}>
                    Select Model Name
                  </option>
                  {modelNames &&
                    modelNames.map((value, index) => (
                      <option key={index} value={value}>
                        {value}
                      </option>
                    ))}
                </select>
                {fieldErrors.modelName && (
                  <p className="error-message">{fieldErrors.modelName}</p>
                )}
              </div>
              <div className="form-group row">
                <div className="form-group col-md-8 mb-2">
                  <label id="mandatorylabels">Description</label>
                  <textarea
                    className="form-control"
                    placeholder="Enter Description"
                    value={description}
                    id="description"
                    maxLength={1000}
                    rows={1}
                    onChange={onHandleChange}
                    style={{ minHeight: "150px", overflow: "auto" }}
                  />
                  {fieldErrors.description && (
                    <p className="error-message">{fieldErrors.description}</p>
                  )}
                </div>
              </div>
            </div>
            <div className="filters">
              <label style={{ fontWeight: "bold", marginBottom: "16px" }}>
                Search Devices
              </label>
              <div className="form-group row">
                <div className="form-group col-md-6 mb-2">
                  <label>Firmware Version</label>
                  <select
                    className="form-control"
                    id="firmwareVersion"
                    value={firmwareVersion}
                    onChange={handleChangeFirmwareversion}
                  >
                    <option selected disabled value="">
                      Select Firmware Version
                    </option>
                    {firmwareVersionList &&
                      firmwareVersionList.map((value, index) => (
                        <option key={index} value={value}>
                          {value}
                        </option>
                      ))}
                  </select>
                </div>
                <div className="form-group col-md-6 mb-2">
                  <label>Date</label>
                  <select
                    className="form-control"
                    id="year"
                    value={selectedYear}
                    onChange={handleChange}
                  >
                    <option selected disabled value="">
                      Select Year
                    </option>
                    {yearList &&
                      yearList.map((value, index) => (
                        <option key={index} value={value}>
                          {value}
                        </option>
                      ))}
                  </select>
                </div>
              </div>
              <div className="form-group row">
                <label style={{ fontWeight: "bold", marginBottom: "8px" }}>
                  Device ID
                </label>
                <div className="form-group col-md-6 mb-2">
                  <label>Starts With</label>
                  <input
                    type="string"
                    className="form-control"
                    value={deviceIDStart}
                    id="deviceIDStart"
                    placeholder="Enter starting of Device ID"
                    maxLength={100}
                    onChange={onHandleModelNumberChange}
                  />
                </div>
                <div className="form-group col-md-6 mb-2">
                  <label>Ends With</label>
                  <input
                    type="string"
                    className="form-control"
                    value={deviceIDEnd}
                    id="deviceIDEnd"
                    placeholder="Enter Ending of Device Id"
                    maxLength={100}
                    onChange={onHandleModelNumberChange}
                  />
                </div>
              </div>
              <div className="form-check form-check-inline">
                <input
                  className="form-check-input"
                  type="checkbox"
                  id="activegroup_check"
                  // value={true}
                  checked={true}
                  disabled
                  // onChange={handleCheckboxChange}
                />
                <label className="form-check-label">
                  Devices which are already part of active device group will not
                  be included
                </label>
              </div>
              {showSearchData && (
                <DisplayDeviceList
                  devices={searchData}
                  url={url}
                  // selectedFirmwareVersion={selectedFirmwareVersion}
                  // selectedYear={selectedYear}
                  // deviceIDStart={deviceIDStart}
                  // deviceIDEnd={deviceIDEnd}
                />
              )}
              <br />
              <div className="col-sm-12">
                <button
                  type="button"
                  className="btn btn-dark btn-block"
                  style={{ marginLeft: "1%", marginTop: "8px" }}
                  disabled={!isSearchEnabled}
                  onClick={getDeviceListSearch}
                >
                  Search
                </button>
              </div>
            </div>
            <br />
            <div className="form-group row">
              <div className="col-sm-12">
                <button
                  disabled={!EnableSubmit}
                  type="button"
                  className="btn btn-dark btn-block"
                  style={{ marginLeft: "1%" }}
                  onClick={() =>
                    setConfirmDialog({
                      isOpen: true,
                      title: `Devices selected for grouping : ${totalDevices}`,
                      subtitle1: `Excluded Devices : ${excludedDevices}`,
                      subtitle2: "Do you want to create group ?",
                      onConfirm: (e) => {
                        onHandleSubmit(e);
                      },
                    })
                  }
                >
                  Submit
                </button>
              </div>
            </div>
          </form>
        </div>
      </div>
    </Handler>
  );
}
