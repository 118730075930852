import Typography from '@mui/material/Typography';
import { PieChart, pieArcLabelClasses } from '@mui/x-charts/PieChart';
import * as React from 'react';

// Example API functions (you need to replace these with actual API calls)
const fetchTotalUsers = () => {
  // Fetch total users from API
  return 1000; // Example value, replace it with actual fetched data
};

const fetchUsersWithRoles = () => {
  // Fetch number of users with different roles from API
  return {
    admin: 300,
    moderator: 200,
    member: 500,
  }; // Example data, replace it with actual fetched data
};

const fetchUsersOnboardedLastWeek = () => {
  // Fetch total users onboarded last week from API
  return 150; // Example value, replace it with actual fetched data
};

export default function UserSummary() {
  const [totalUsers, setTotalUsers] = React.useState(0);
  const [usersWithRoles, setUsersWithRoles] = React.useState({});
  const [totalUsersLastWeek, setTotalUsersLastWeek] = React.useState(0);

  React.useEffect(() => {
    // Fetch data from APIs
    const fetchData = async () => {
      const totalUsersData = await fetchTotalUsers();
      const usersWithRolesData = await fetchUsersWithRoles();
      const totalUsersLastWeekData = await fetchUsersOnboardedLastWeek();

      setTotalUsers(totalUsersData);
      setUsersWithRoles(usersWithRolesData);
      setTotalUsersLastWeek(totalUsersLastWeekData);
    };

    fetchData();
  }, []);


  return (
    <div style={{ border: '1px solid #000',borderRadius:'5px', padding: '20px', marginBottom: '20px'}}>
      <Typography variant="h6">
        Users Overview
      </Typography>
      <div style={{marginTop:'40px'}}>
      <PieChart
        series={[
            {
               arcLabel: (item) => `(${item.value})`,
                data : [
                    { id:1, value: usersWithRoles.admin,label: 'Admin'},
                    { id:2, value: usersWithRoles.moderator,label: 'Analyst'},
                    { id:4, value: totalUsersLastWeek ,label: 'New Users'},
                  ],
          //         highlightScope: { faded: 'global', highlighted: 'item' },
          // faded: { innerRadius: 30, additionalRadius: -30, color: 'gray' },
            }
        ]}
        // id:0,labelKey="id:0,label"
        // valueKey="value"
        sx={{
          [`& .${pieArcLabelClasses.root}`]: {
            fill: 'white',
            fontWeight: 'bold',
          },
        }}
        width={400}
        height={200}
        colors={['#49abc8', '#004969', '#00628e', '#266774', '#8884d8']}
      />
      </div>
    </div>
  );
}
