import { Dialog, FormControlLabel, Switch, Typography } from '@mui/material';
import Button from '@mui/material/Button';
import DialogActions from '@mui/material/DialogActions';
import React, { useContext, useEffect, useState } from 'react';
import { AuthContext } from "../../App";
import Handler from "../../components/handler";
import useAxiosApiRequest from "../../hook/useAxiosApiRequest";

const DeviceModelDetailsModal = ({ isOpen, handleClose, DeviceModel, reloaDeviceData }) => {
  const { statusCode, loading, apiRequest } = useAxiosApiRequest();
  const { userName } = useContext(AuthContext);

  const tenantID = localStorage.getItem('TenantID');
  const tenant = tenantID ? JSON.parse(tenantID) : null;

  const [isActive, setIsActive] = useState(true);

  useEffect(() => {
    if (DeviceModel?.modelStatus === "ACTIVE") {
      setIsActive(true);
    } else {
      setIsActive(false);
    }
  }, [DeviceModel]);

  const dialogStyle = {
    width: '450px',
    height: '400px',
    // marginLeft:'20px',
    // marginTop:'10px',
    padding:'10px',
  };

  const data = {
    deviceType: DeviceModel?.deviceType,
    modelName: DeviceModel?.modelName,
    description: DeviceModel?.description,
    modelImage: DeviceModel?.modelImage,
    updatedBy : userName,
  }

  const modelNumber = DeviceModel?.modelNumber;

  //function to convert UTC to local time
  function convertUTCDateToLocalDate(val) {
    var dateLocal = new Date(val);
    var timestamp = new Date(
      dateLocal.getTime() - dateLocal.getTimezoneOffset()
    );
    var result = timestamp.toLocaleString();
    return result;
  }

  const handleToggleActive = async() => {
    setIsActive(prevState => !prevState);
    let updatedstatus = !isActive ? "ACTIVE" : "INACTIVE";
    deviceStatus(updatedstatus);
  };

  const deviceStatus = async(modelStatus) => {
    const response = await apiRequest({
      endpoint:
        `/deviceservice/devicemodel/modelNumber/${modelNumber}`,
      method: "PUT",
      additionalParams: {
        headers: {
          "tenant-id": tenant
        } 
      },
      body: {...data,modelStatus},
    })
      if(response) {
        handleClose();
          reloaDeviceData(1,'null','null', 'null','desc')
      }
  }

  return (
    <Handler statusCode={statusCode} loading={loading} >
    <Dialog
      style={{
        fontSize: "110%",
        fontWeight: "90%",
        borderRadius: "5%",
      }}
      open={isOpen}
      onClose={handleClose}
      aria-labelledby="responsive-dialog-title"
    >
      <div style={dialogStyle}>
        <div style={{display:'flex',justifyContent:'space-between'}}>
        <Typography fontWeight={"bold"} fontSize={'25px'} gutterBottom>
          {DeviceModel?.modelName}
        </Typography>
        <img
          src={DeviceModel?.modelImage?.imageFile}
          alt={DeviceModel?.modelImage?.fileName}
          style={{ width: "200px", height: "70px", objectFit: "contain" }}
        />
        </div>
        <Typography fontWeight={"bold"} gutterBottom>
          Device Model Status: {isActive ? "Active" : "Inactive"}
        </Typography>
        <Typography gutterBottom style={{ display: 'flex' }}>
          <p style={{fontWeight:'bold'}}>Created By :</p>
          <p>{ DeviceModel?.createdBy}</p>
        </Typography>
        <Typography gutterBottom style={{ display: 'flex' }}>
          <p style={{fontWeight:'bold'}}>Created At :</p>
          <p>{convertUTCDateToLocalDate(DeviceModel?.createdAt)}</p>
        </Typography>
        <Typography gutterBottom style={{ display: 'flex' }}>
          <p style={{fontWeight:'bold'}}>Updated By :</p>
          <p>{DeviceModel?.updatedBy}</p>
        </Typography>
        <Typography gutterBottom style={{ display: 'flex' }}>
          <p style={{fontWeight:'bold'}}>updated At :</p>
          <p>{convertUTCDateToLocalDate(DeviceModel?.updatedAt)}</p>
        </Typography>
        <FormControlLabel
          label={isActive ? "Deactivate Device Model" : "Activate Device Model"}
          control={<Switch checked={isActive} onChange={handleToggleActive} />}
        />
        <DialogActions style={{ justifyContent: 'center', display: 'flex' }}>
        <Button
          onClick={handleClose}
          variant="contained"
          color="primary"
          style={{ backgroundColor: "#19172C", }}
        >
          Close
        </Button>
        </DialogActions>
      </div>
    </Dialog>
    </Handler>
  );
};

export default DeviceModelDetailsModal;
