import React from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import '../pages/tenantOnboard/tenantData.css';
import { Typography,Paper } from '@mui/material';

export default function SuccessCreateModal({openDialog,onHandleClose}) 
{
    const dialogStyle = {
        width: '300px',
        height: '200px',
      };

  return (
    <div>
      <Dialog 
        // sx={{backgroundColor: 'rgba(84, 84, 84, 0.8)'}}
        open={openDialog}
        close={onHandleClose}
        aria-labelledby="responsive-dialog-title"
      >
        <Paper style={dialogStyle}>
         <div className="success-icon-container">
          <CheckCircleOutlineIcon sx={{ color: "#008E0E", fontSize:"50px" }} />
        </div>
        <DialogContent style={{ justifyContent: 'center', display: 'flex' }}>  
           <Typography variant='h6'>Created Successfully</Typography>       
        </DialogContent>
        <DialogActions style={{ justifyContent: 'center', display: 'flex' }}>
          <Button
            style={{
              backgroundColor: "#19172C",
            }}
            variant="contained"
            onClick={onHandleClose}
          >
            OK
          </Button>
        </DialogActions>
        </Paper>
      </Dialog>
    </div>
  );
}