/* eslint-disable react/jsx-max-depth */
import React, { useContext, useEffect, useState } from "react";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import ArrowDropUpIcon from "@mui/icons-material/ArrowDropUp";
import UpdateIcon from "@mui/icons-material/Update";
import FilterAltOutlinedIcon from "@mui/icons-material/FilterAltOutlined";
import ReplayIcon from "@mui/icons-material/Replay";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
} from "@mui/material";
import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import KeyboardArrowRightIcon from "@mui/icons-material/KeyboardArrowRight";
import KeyboardArrowLeftIcon from "@mui/icons-material/KeyboardArrowLeft";
import { FiEdit } from "react-icons/fi";
import ReactPaginate from "react-paginate";
// import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import Handler from "../../components/handler";
import useAxiosApiRequest from "../../hook/useAxiosApiRequest";

import "./ListFirmware.css";
import { AuthContext } from "../../App";

export default function ListFirmware() {
  // const userRole = useSelector((state) => state.tokens?.tokens?.userRole);
  // const finalRoles = userRole ? userRole.split("/") : null;
  // const CURRENT_USER_TYPE = finalRoles ? finalRoles[1] : null;
  const { userName } = useContext(AuthContext);
  const tenantID = localStorage.getItem("TenantID");
  const tenant = tenantID ? JSON.parse(tenantID) : null;
  // const adminrole = "adminrole";

  const navigate = useNavigate();
  const { statusCode, loading, apiRequest } = useAxiosApiRequest();

  const [deviceTypeData, setdeviceTypeData] = useState([]);
  const [modelNames, setmodelNames] = useState([]);
  // const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectedfilter, setSelectedfilter] = useState({
    device_type: "",
    model_name: "",
  });
  const [sortField, setSortField] = useState(null);
  const [sortOrder, setSortOrder] = useState("desc");
  const [firmwareData, setFirmwareData] = useState("");
  const [pageNumber, setPageNumber] = useState(0);
  const [recordsPerPage, setRecordsPerPage] = useState(10);
  const [totalRecords, setTotalRecords] = useState(0);
  const [totalPages, setTotalPages] = useState(1);
  const [open, setOpen] = useState(false);

  useEffect(() => {
    getFirmwareList();
    getDeviceTypeData();
  }, []);

  const handleOpenModal = (firmwareId) => {
    navigate(`/listFirmware/firmwareTimeline/${firmwareId}`);
  };

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleApplyFilters = () => {
    handleFilterSearch();
    handleClose();
  };
  // const handleCloseModal = () => {
  //   setIsModalOpen(false);
  // };

  const handleCreateOnClick = () => {
    navigate("/listFirmware/addEditFirmware");
  };

  const handleUpdateOnClick = (id, isEditDisabled) => {
    if (isEditDisabled) {
      navigate(`/listFirmware/addEditFirmware/${id}`);
    }
  };

  const HandleDeviceTypeFilter = (e) => {
    setSelectedfilter((prevState) => ({
      ...prevState,
      [e.target.id]: e.target.value,
    }));
    getModelNames(`deviceType.name`, e.target.value, "null", "desc");
  };

  const changePage = (selected) => {
    const page = selected?.selected;
    if (page !== undefined) {
      setPageNumber(page);
      getFirmwareList("", "", "", "", "", page + 1, recordsPerPage);
    }
  };
  const handleModelFilter = (e) => {
    setSelectedfilter((prev) => ({
      ...prev,
      [e.target.id]: e.target.value,
    }));
  };
  const handleFilterSearch = () => {
    getFirmwareList(
      selectedfilter.device_type,
      selectedfilter.model_name,
      null,
      null,
      null,
    );
  };
  const handleFilterReset = () => {
    setSelectedfilter({
      device_type: "",
      model_name: "",
    });
    getFirmwareList();
  };
  const handleSort = (field) => {
    let newOrder = "asc";
    if (sortField === field && sortOrder === "asc") {
      newOrder = "desc";
    }
    setSortField(field);
    setSortOrder(newOrder);
    getFirmwareList(null, null, null, field, sortOrder, 1, recordsPerPage);
  };
  const handleAssignedButton = () => {
    getFirmwareList(null, null, userName, null, null, 1, recordsPerPage);
  };

  const handleRecordsPerPage = (e) => {
    const records = Number(e.target.value);
    setRecordsPerPage(records);
    setPageNumber(0);
    getFirmwareList(
      selectedfilter.device_type,
      selectedfilter.model_name,
      null,
      null,
      null,
      1,
      records,
    );
  };
  const getDeviceTypeData = async () => {
    const response = await apiRequest({
      endpoint: `/deviceservice/masterdata/deviceType`,
      method: "GET",
      additionalParams: {
        headers: {
          "tenant-id": tenant,
        },
      },
      body: {},
    });
    const Data = response?.deviceMasterData;
    if (Array.isArray(Data)) {
      setdeviceTypeData(Data);
    }
  };

  const getModelNames = async (filterField, fieldValue, field, newOrder) => {
    const response = await apiRequest({
      endpoint: `/deviceservice/devicemodel/allmodels/10/1/${filterField}/${fieldValue}/${field}/${newOrder}`,
      method: "GET",
      additionalParams: {
        headers: {
          "tenant-id": tenant,
        },
      },
      body: {},
    });
    const Data = response?.deviceModels;
    if (Array.isArray(Data)) {
      setmodelNames(Data);
    }
  };
  const getFirmwareList = async (
    deviceType,
    deviceModel,
    approverName,
    sortBy,
    sortOrder,
    page,
    recordsPerPage = null,
  ) => {
    // ?deviceType=string&deviceModel=string&approverName=string&sortBy=string&sortOrder=asc&page=1&limit=10

    const baseUrl = `/firmwareversionservice/search`;
    const queryParams = [];
    if (deviceType)
      queryParams.push(`deviceType=${encodeURIComponent(deviceType)}`);
    if (deviceModel)
      queryParams.push(`deviceModel=${encodeURIComponent(deviceModel)}`);
    if (approverName)
      queryParams.push(`approverName=${encodeURIComponent(approverName)}`);
    if (sortBy) queryParams.push(`sortBy=${encodeURIComponent(sortBy)}`);
    if (sortOrder)
      queryParams.push(`sortOrder=${encodeURIComponent(sortOrder)}`);
    if (page !== undefined) {
      queryParams.push(`page=${encodeURIComponent(page)}`);
    }
    if (recordsPerPage) queryParams.push(`limit=${recordsPerPage}`);
    const url =
      queryParams.length > 0 ? `${baseUrl}?${queryParams.join("&")}` : baseUrl;

    // Perform the API request
    const response = await apiRequest({
      endpoint: url,
      method: "GET",
      additionalParams: {
        headers: {
          "tenant-id": tenant,
        },
      },
      body: {},
    });
    const totalRecords = response?.pagination.total;
    const pagesCount = response?.pagination.pages;
    setTotalPages(pagesCount);
    setTotalRecords(totalRecords);
    setFirmwareData(response?.data);
  };

  return (
    <Handler statusCode={statusCode} loading={loading}>
      <div className="heading">
        <h4>Firmware Management</h4>
        <button
          type="submit"
          className="btn btn-dark"
          style={{
            minWidth: "20%",
            marginBottom: "16px",
            padding: "12px 20px",
          }}
          onClick={handleCreateOnClick}
        >
          Add firmware
        </button>
      </div>
      <div
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
          width: "100%",
          marginBottom: "16px",
        }}
      >
        <h6 style={{ margin: 0 }}>Total Records: {totalRecords}</h6>
        <div
          style={{
            display: "flex",
            alignItems: "center",
            maxWidth: "500px",
            marginLeft: "10px",
          }}
        >
          <div onClick={handleAssignedButton}>
            <Button
              sx={{
                border: "1px solid",
                color: "#1442cc",
                textTransform: "none",
                padding: "12px 20px",
              }}
            >
              Assigned to me
            </Button>
          </div>
          <div>
            <div>
              <button onClick={handleClickOpen} className="actionButton">
                <FilterAltOutlinedIcon titleAccess="Filter" />
              </button>

              <Dialog
                open={open}
                onClose={handleClose}
                aria-labelledby="filter-dialog-title"
              >
                <DialogTitle id="filter-dialog-title">
                  Filter Options
                </DialogTitle>
                <DialogContent>
                  <div style={{ paddingRight: "20px", marginBottom: "10px" }}>
                    <FormControl style={{ width: "250px" }} size="small">
                      <select
                        className="form-control"
                        id="device_type"
                        value={selectedfilter.device_type}
                        onChange={(e) => {
                          setSelectedfilter({
                            ...selectedfilter,
                            device_type: e.target.value,
                          });
                          HandleDeviceTypeFilter(e);
                        }}
                      >
                        <option disabled value="">
                          Filter By Device Type
                        </option>
                        {deviceTypeData &&
                          deviceTypeData.map((getDeviceTypes) => (
                            <option key={getDeviceTypes.id}>
                              {getDeviceTypes.name}
                            </option>
                          ))}
                      </select>
                    </FormControl>
                  </div>
                  <div style={{ paddingRight: "20px", marginBottom: "10px" }}>
                    <FormControl style={{ width: "250px" }} size="small">
                      <select
                        className="form-control"
                        id="model_name"
                        disabled={!modelNames.length}
                        value={selectedfilter.model_name}
                        onChange={(e) => {
                          setSelectedfilter({
                            ...selectedfilter,
                            model_name: e.target.value,
                          });
                          handleModelFilter(e);
                        }}
                        required
                      >
                        <option disabled value="">
                          Filter By Model Name
                        </option>
                        {modelNames &&
                          modelNames.map((getModelName) => (
                            <option key={getModelName.id}>
                              {getModelName.modelName}
                            </option>
                          ))}
                      </select>
                    </FormControl>
                  </div>
                </DialogContent>
                <DialogActions>
                  <Button onClick={handleClose} color="primary">
                    Cancel
                  </Button>
                  <Button onClick={handleApplyFilters} color="primary">
                    Apply
                  </Button>
                </DialogActions>
              </Dialog>
            </div>
          </div>
          <button className="actionButton" onClick={handleFilterReset}>
            <ReplayIcon titleAccess="reset filter" />
          </button>
        </div>
      </div>
      <Paper sx={{ width: "100%", overflow: "hidden" }}>
        <TableContainer>
          <Table aria-label="firmware table">
            <TableHead
              className="table-head"
              sx={{
                maxHeight: 20,
                cursor: "pointer",
              }}
            >
              <TableRow>
                <TableCell
                  align="center"
                  onClick={() => handleSort("firmwareVersion")}
                >
                  Firmware Version
                  {sortField === "firmwareVersion" && sortOrder === "asc" ? (
                    <ArrowDropUpIcon />
                  ) : (
                    <ArrowDropDownIcon />
                  )}
                </TableCell>
                <TableCell align="center">Uploaded Date</TableCell>
                <TableCell
                  align="center"
                  onClick={() => handleSort("currStatus")}
                >
                  Current Status
                  {sortField === "currStatus" && sortOrder === "asc" ? (
                    <ArrowDropUpIcon />
                  ) : (
                    <ArrowDropDownIcon />
                  )}
                </TableCell>
                <TableCell align="center">Approved date</TableCell>
                <TableCell align="center">Approved By</TableCell>

                <TableCell align="center">Action</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {firmwareData &&
                firmwareData.map((items, index) => {
                  const editableFields = ["DRAFT"];
                  let isEditDisabled = editableFields.includes(
                    items.currStatus,
                  );
                  const uploadedDate = items.uploadedDate
                    ? new Date(items.uploadedDate).toDateString()
                    : null;
                  const approvedDate = items.approvedDate
                    ? new Date(items.approvedDate).toDateString()
                    : null;
                  const approvedBy = items.approvedBy
                    ? items.approvedBy.username
                    : null;
                  return (
                    <TableRow
                      className="tableRowShade"
                      tabIndex={-1}
                      key={items.id}
                    >
                      <TableCell align="center">
                        {items.firmwareVersion || "-"}
                      </TableCell>
                      <TableCell align="center">
                        {uploadedDate || "-"}
                      </TableCell>
                      <TableCell align="center">
                        {items.currStatus || "-"}
                      </TableCell>
                      <TableCell align="center">
                        {approvedDate || "-"}
                      </TableCell>
                      <TableCell align="center">{approvedBy || "-"}</TableCell>
                      <>
                        <TableCell
                          sx={{
                            display: "block",
                          }}
                        >
                          <FiEdit
                            style={{
                              marginRight: "10px",
                              fontSize: "110%",
                              color: isEditDisabled ? "#1442cc" : "grey",
                            }}
                            title={"Update"}
                            onClick={() =>
                              handleUpdateOnClick(items.id, isEditDisabled)
                            }
                          />
                          <UpdateIcon
                            style={{
                              fontSize: "120%",
                              color: "#1442cc",
                            }}
                            title={"Change Status"}
                            onClick={() => handleOpenModal(items.id)}
                          />
                        </TableCell>
                      </>
                      {/* )} */}
                    </TableRow>
                  );
                })}
            </TableBody>
          </Table>
        </TableContainer>
      </Paper>
      <br></br>
      <div className="pagenate">
        <h6>
          <strong>Total Records : {totalRecords}</strong>
        </h6>
        <div>
          <FormControl variant="outlined" size="small" sx={{ minWidth: 120 }}>
            <InputLabel id="recordsPerPage-label">Show</InputLabel>
            <Select
              labelId="recordsPerPage-label"
              id="recordsPerPage"
              value={recordsPerPage}
              onChange={(e) => {
                handleRecordsPerPage(e);
              }}
              label="Show"
            >
              <MenuItem value={5}>5</MenuItem>
              <MenuItem value={10}>10</MenuItem>
              <MenuItem value={20}>20</MenuItem>
            </Select>
          </FormControl>
        </div>

        <span>
          <ReactPaginate
            previousLabel={<KeyboardArrowLeftIcon />}
            nextLabel={<KeyboardArrowRightIcon />}
            breakLabel={"...."}
            marginPagesDisplayed={2}
            pageCount={totalPages}
            forcePage={pageNumber}
            onPageChange={changePage}
            containerClassName={"paginationBttns"}
            disabledClassName={"paginationDisabled"}
            activeClassName={"paginationActive"}
          />
        </span>
      </div>
    </Handler>
  );
}
