import React, { useRef } from "react";

import { ErrorBoundary } from "react-error-boundary";

import SideBar from "../components/navbarMapping";
import MainContent from "./pagesLayout";
import SideComponent from "./sideComponent";

const Fallback = ({ error, resetErrorBoundary }) => {
  // Call resetErrorBoundary() to reset the error boundary and retry the render.
  return (
    <div role="alert">
      <p>Something went wrong:</p>
      <pre style={{ color: "red" }}>{error.message}</pre>
    </div>
  );
};

//component which handles routes in side navigation bar
//here we will also handle routes based on user role
function NavbarRoutes({ role }) {
  const storedRole = role;

  //ref
  const sideComponentRef = useRef();

  const isToggled = () => {
    sideComponentRef.current.isToggled();
  };

  return (
    <div style={{ flexDirection: "row", display: "flex" }}>
      <SideBar role={storedRole} isToggled={isToggled} />

      <ErrorBoundary FallbackComponent={Fallback}>
        <SideComponent ref={sideComponentRef} />
        <MainContent role={storedRole} isToggled={isToggled} />
      </ErrorBoundary>
    </div>
  );
}

export default NavbarRoutes;
