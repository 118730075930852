import {
  Button,
  Card,
  CardContent,
  CardHeader,
  Checkbox,
  FormControl,
  Grid,
  InputLabel,
  ListItemText,
  MenuItem,
  Select,
  TextField,
} from "@mui/material";
import React, { useContext, useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import useAxiosApiRequest from "../../hook/useAxiosApiRequest";
import { AuthContext } from "../../App";
import Handler from "../../components/handler";
import { DRAFT, INREVIEW } from "../../constants/constants";

const ApproversDropdown = ({
  approvers,
  selectedApprovers,
  onApproverChange,
}) => {
  const selectedUsernames = selectedApprovers?.map(
    (approver) => approver.username,
  );

  return (
    <FormControl fullWidth margin="normal">
      <InputLabel id="approvers-label">Approvers:</InputLabel>
      <Select
        labelId="approvers-label"
        name="approvers"
        id="approvers"
        multiple
        required
        value={selectedUsernames}
        onChange={onApproverChange}
        renderValue={(selected) => (
          <div>
            {selected.map((username) => (
              <span key={username} style={{ marginRight: 4 }}>
                {username}
              </span>
            ))}
          </div>
        )}
        label="Approvers"
      >
        {approvers.map((approver, index) => (
          <MenuItem key={index} value={approver.username}>
            <Checkbox
              checked={selectedUsernames?.indexOf(approver?.username) > -1}
            />
            <ListItemText primary={approver.username} />
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
};

const ChangeFirmwareStatus = ({
  currentStatus,
  statusTransitions,
  approvers,
  tenant,
  isApprover,
}) => {
  const { firmwareId } = useParams();
  const navigate = useNavigate();
  const { statusCode, errormsg, loading, apiRequest } = useAxiosApiRequest();
  const { userName, email } = useContext(AuthContext);

  const [selectedStatus, setSelectedStatus] = useState("");
  const [approversList, setApproversList] = useState([]);
  const [selectedApprovers, setSelectedApprovers] = useState([]);
  const [options, setOptions] = useState([]);
  const [comment, setComment] = useState("");

  useEffect(() => {
    const transitionOptions = statusTransitions[currentStatus];
    setOptions(transitionOptions || []);
    // setApproversList(approvers); // this is activated when we will be getting list of approvers as a response from api itself
    let selectedApproverList = approvers
      ? approvers?.map(({ _id, ...rest }) => rest)
      : [];
    setSelectedApprovers(selectedApproverList);

    getApproversList();
  }, [statusTransitions, currentStatus, approvers]);

  const handleStatusChange = (event) => {
    setSelectedStatus(event.target.value);
  };
  const handleApproverChange = (event) => {
    const selectedUsernames = event.target.value;
    const selectedApprovers = approversList.filter((approver) =>
      selectedUsernames.includes(approver.username),
    );
    const cleanedApprovers = selectedApprovers.map(({ _id, ...rest }) => rest);
    setSelectedApprovers(cleanedApprovers);
  };
  const handleChange = (e) => {
    setComment(e.target.value);
  };
  const handleSubmit = async (event) => {
    event.preventDefault();
    let URL;
    let requestData;
    if (selectedStatus === INREVIEW) {
      URL = `/firmwareversionservice/changeStatus`;
      requestData = {
        id: firmwareId,
        newStatus: selectedStatus,
        updatedBy: {
          email: email,
          username: userName,
        },
        approverList: selectedApprovers,
        comment: comment,
      };
    } else if (!isApprover && currentStatus === INREVIEW) {
      URL = `/firmwareversionservice/firmwareversion`;
      requestData = {
        id: firmwareId,
        updatedBy: {
          email: email,
          username: userName,
        },
        finalApproverList: selectedApprovers,
        comment: comment,
      };
    } else {
      URL = `/firmwareversionservice/changeStatus`;
      requestData = {
        id: firmwareId,
        newStatus: selectedStatus,
        updatedBy: {
          email: email,
          username: userName,
        },
        comment: comment,
      };
    }

    try {
      const response = await apiRequest({
        endpoint: URL,
        method: "PATCH",
        additionalParams: {
          headers: {
            "tenant-id": tenant,
          },
        },
        body: requestData,
      });

      if (response && (!errormsg || statusCode === 200)) {
        navigate("/listFirmware");
      }
    } catch (error) {
      console.error("Error:", error);
    }
  };
  const showStatusDropdown = () => {
    if (isApprover) {
      return true;
    } else {
      return currentStatus === DRAFT;
    }
  };
  const getApproversList = async () => {
    try {
      const response = await apiRequest({
        endpoint: `/cognitoservice/userprofileinfo/allUsers/Admin/10/1/permissions/approver/null/null/desc`,
        method: "GET",
        additionalParams: {
          headers: {
            "tenant-id": tenant,
          },
        },
        body: {},
      });
      const length = response.totalDocumentCount;
      const transformedData = [];

      for (let i = 0; i < length; i++) {
        const email = response.userProfileDetails[i].userAttributes.email;
        const userName =
          response.userProfileDetails[i].userAttributes.userName ?? "NULL";
        transformedData.push({
          username: userName,
          email: email,
        });
      }
      setApproversList(transformedData);
    } catch (error) {
      console.error(error);
    }
  };
  return (
    <Handler statusCode={statusCode} loading={loading} errormsg={errormsg}>
      <form onSubmit={handleSubmit}>
        <Card>
          <CardHeader
            title={`Current Status: ${currentStatus?.slice(0, 1) + currentStatus?.slice(1).toLowerCase()}`}
          />
          <CardContent>
            <Grid
              container
              spacing={2}
              alignItems="center"
              style={{ width: "100%" }}
            >
              {showStatusDropdown() && (
                <Grid item xs>
                  <FormControl fullWidth margin="normal">
                    <InputLabel id="status-label">Status:</InputLabel>
                    <Select
                      labelId="status-label"
                      name="status"
                      id="status"
                      value={selectedStatus || ""}
                      onChange={handleStatusChange}
                      required
                      label="Status"
                    >
                      {options.map((status, index) => (
                        <MenuItem key={index} value={status}>
                          {status}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </Grid>
              )}

              {(selectedStatus === "INREVIEW" || !isApprover) && (
                <Grid item xs>
                  <ApproversDropdown
                    selectedApprovers={selectedApprovers}
                    onApproverChange={handleApproverChange}
                    approvers={approversList}
                  />
                </Grid>
              )}

              <Grid item xs>
                <TextField
                  label="Comment"
                  name="comment"
                  required
                  fullWidth
                  value={comment}
                  onChange={handleChange}
                  sx={{
                    marginTop: "8px",
                  }}
                />
              </Grid>
            </Grid>
            <Grid item xs={4}>
              <Button
                type="submit"
                variant="contained"
                color="primary"
                fullWidth
                style={{ marginTop: 16 }}
              >
                Submit
              </Button>
            </Grid>
          </CardContent>
        </Card>
      </form>
    </Handler>
  );
};

export default ChangeFirmwareStatus;
