import GroupsIcon from '@mui/icons-material/Groups';
import PersonIcon from '@mui/icons-material/Person';
import { Grid, ListItem, Typography } from "@mui/material";
import Card from "@mui/material/Card";
import { default as React } from "react";
import TenantSummary from "./tenantChart";
import UserSummary from "./userChart";

const SuperadminDashboard = () => {
  const cardtwoStyles = {
    backgroundColor: "#9dc6e0",
    height: "170px",
    border: "1px solid #a4a5a5a8",
    borderRadius: "5%",
  };

  const iconStyles = {
    fontSize: 40, 
    color: '#004c6d',
    backgroundColor:'#c1e7ff',
    borderRadius:"50%",
    padding: '8px',
    display: 'inline-block',
  }

  const cardtwoListStyles = {
    marginTop: "10px",
    marginLeft: "20px",
    display: "flex",
    flexDirection: "column",
    alignItems: "baseline",
  };
  return (
    <div>
      <Grid container spacing={2}>
        <Grid item xs={3}>
          <Card sx={cardtwoStyles}>
            <ListItem disableGutters sx={cardtwoListStyles}>
              <ListItem
                sx={{ display: "flex", justifyContent: "space-around" }}
              >
                <Typography sx={{ marginLeft: "-45px", fontWeight: "bold" }}>
                  Users
                </Typography>
                <GroupsIcon sx={iconStyles} />
              </ListItem>
              <Typography fontSize={"30px"} color="#002644">
                15
              </Typography>
              <Typography sx={{ fontWeight: "bold" }} variant="caption">
                Last Created:17/01/2024
              </Typography>
            </ListItem>
          </Card>
        </Grid>
        <Grid item xs={3}>
          <Card sx={cardtwoStyles}>
            <ListItem disableGutters sx={cardtwoListStyles}>
              <ListItem
                sx={{ display: "flex", justifyContent: "space-around" }}
              >
                <Typography sx={{ marginLeft: "-45px", fontWeight: "bold" }}>
                  Tenants
                </Typography>
                <PersonIcon sx={iconStyles} />
              </ListItem>
              <Typography fontSize={"30px"} color="#002644">
                6
              </Typography>
              <Typography sx={{ fontWeight: "bold" }} variant="caption">
                Last Onboarded:08/03/2024
              </Typography>
            </ListItem>
          </Card>
        </Grid>
      </Grid>
      <div
            style={{
              marginTop: "30px",
              display: "flex",
              justifyContent:'flex-start',
              alignItems: "center",
              gap:'10px'
            }}
          >
            <TenantSummary />
            <UserSummary />
          </div>
    </div>
  );
};

export default SuperadminDashboard;
