import { useDispatch } from "react-redux";
import { set_cred, set_tokens, set_user_type } from "../redux/action/action";
import { getTokenCredentials, getTokens, refreshToken } from "../services/aws";
import { getRefreshTime } from "../utilities/helper";

const useCredentials = () => {
  //redux
  const dispatch = useDispatch();

  //get all tokens from aws
  const setCredentials = async () => {
    const {
      accessToken,
      exp,
      idToken,
      refreshTokens,
      region,
      userRole,
      userrole,
    } = await getTokens();

    //update redux data
    dispatch(set_user_type(userrole));
    
    dispatch(
      set_tokens({ accessToken, exp, idToken, refreshTokens, region, userRole })
    );

    //calling token credential function
    setTokenCredentials();

    //checking token validity
    const refreshTime = getRefreshTime(exp, 5);
    setTimeout(() => {
      setRefreshToken();
      setTokenCredentials();
    }, refreshTime);
  };

  //token credential functions
  const setTokenCredentials = async () => {
    //get all credentials from aws
    const { accessKeyId, secretAccessKey, sessionToken } =
      await getTokenCredentials();

    //store data to redux
    dispatch(set_cred({ accessKeyId, secretAccessKey, sessionToken }));
  };

  //refresh token function
  const setRefreshToken = async () => {
    //refresh the token
    const { userrole, accessToken, idToken, region } = await refreshToken();

    //store data to redux
    dispatch(set_user_type(userrole));
    dispatch(set_tokens({ accessToken, idToken, region }));
  };

  return { setCredentials };
};

export default useCredentials;
