import React from "react";
import {
  Card,
  CardHeader,
  CardContent,
  Typography,
  List,
  ListItem,
  ListItemText,
  Divider,
  Grid,
} from "@mui/material";

const FirmwareCard = ({ firmware }) => {
  return (
    <Card style={{ overflow: "auto" }}>
      <CardHeader title={firmware?.firmwareName} />
      <CardContent>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Grid container spacing={2}>
              <Grid item xs>
                <Typography variant="subtitle1">
                  <strong>Firmware Version: </strong>
                  {firmware?.firmwareVersion}
                </Typography>
              </Grid>
              <Grid item xs>
                <Typography variant="subtitle1">
                  <strong>Status: </strong>
                  {firmware?.currStatus}
                </Typography>
              </Grid>
              <Grid item xs>
                <Typography variant="subtitle1">
                  <strong>Device Model: </strong>
                  {firmware?.deviceModel?.toUpperCase()}
                </Typography>
              </Grid>
              <Grid item xs>
                <Typography variant="subtitle1">
                  <strong>Device Type: </strong>
                  {firmware?.deviceType}
                </Typography>
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12}>
            <Divider style={{ margin: "20px 0" }} />
          </Grid>

          <Grid item xs={12}>
            <Grid container spacing={2}>
              <Grid item xs={6}>
                <Typography variant="h6" gutterBottom>
                  <strong>Release Notes</strong>
                </Typography>
                <div
                  style={{
                    overflow: "auto",
                    maxHeight: "300px",
                    padding: "10px",
                    border: "1px solid #e0e0e0",
                    borderRadius: "4px",
                  }}
                >
                  <div
                    dangerouslySetInnerHTML={{ __html: firmware?.releaseNotes }}
                  />
                </div>
              </Grid>

              <Grid item xs={6}>
                <Typography variant="h6" gutterBottom>
                  <strong>Files</strong>
                </Typography>
                <div
                  style={{
                    overflow: "auto",
                    maxHeight: "300px",
                    padding: "10px",
                    border: "1px solid #e0e0e0",
                    borderRadius: "4px",
                  }}
                >
                  <List component="div" disablePadding>
                    {firmware?.files?.map((file, index) => (
                      <ListItem key={index}>
                        <ListItemText
                          primary={`File Name: ${file.fileName}`}
                          secondary={`Size: ${file.size}`}
                        />
                      </ListItem>
                    ))}
                  </List>
                </div>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  );
};

export default FirmwareCard;
