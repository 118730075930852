import React, { useState, useEffect, useContext } from "react";
import log from "loglevel";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import { DialogContent, DialogTitle, Paper, Typography } from "@mui/material";
import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";

import "./tenantData.css";
import { AuthContext } from "../../App";

var apigClientFactory = require("aws-api-gateway-client").default;

export default function ViewTenantData({ tenantID, open, handleClose }) {
  const { cred } = useContext(AuthContext);
  const { tokens } = useContext(AuthContext);
  const accessKeyId = cred.accessKeyId;
  const SecretKey = cred.secretAccessKey;
  const SessionToken = cred.sessionToken;
  const region = tokens.region;

  const config = {
    invokeUrl: process.env.REACT_APP_SERVICE_URL_v2,
    region: region,
    accessKey: accessKeyId,
    secretKey: SecretKey,
    sessionToken: SessionToken,
  };

  const apigClient = apigClientFactory.newClient(config);

  const [tenantData, setTenantData] = useState([]);
  function convertUTCDateToLocalDate(val) {
    var dateLocal = new Date(val);
    var timestamp = new Date(
      dateLocal.getTime() - dateLocal.getTimezoneOffset(),
    );
    var result = timestamp.toLocaleString();
    return result;
  }

  const userRole = process.env.REACT_APP_USER_ROLE;

  useEffect(() => {
    async function getData() {
      var pathTemplate1 = "/tenantservice/tenantrequest";
      var pathTemplate2 = "/tenantservice/tenant";
      var method = "GET";
      var pathParams = {
        //This is where path request params go.
        //userId: '1234',
      };
      var additionalParams = {
        headers: {
          "Content-Type": "application/json",
          "tenant-id": userRole,
        },
      };

      var body = {
        //This is where you define the body of the request
      };
      try {
        const [table1, table2] = await Promise.all([
          apigClient.invokeApi(
            pathParams,
            pathTemplate1,
            method,
            additionalParams,
            body,
          ),
          apigClient.invokeApi(
            pathParams,
            pathTemplate2,
            method,
            additionalParams,
            body,
          ),
        ]);
        const combinedData = table1.data.map((request) => ({
          requested_on: request.requestedOn,
          requested_by: request.requestedBy,
          created_on: request.createdOn,
          created_by: request.createdBy,
          status: request.status,
          tenant_domain: table2.data.find(
            (tenant) => tenant.tenantStagingId === request.tenantStagingId,
          ).tenantDomain,
          tenant_description: table2.data.find(
            (tenant) => tenant.tenantStagingId === request.tenantStagingId,
          ).tenantDescription,
          tenant_name: table2.data.find(
            (tenant) => tenant.tenantStagingId === request.tenantStagingId,
          ).tenantName,
          tenant_owner_email_id: table2.data.find(
            (tenant) => tenant.tenantStagingId === request.tenantStagingId,
          ).tenantOwnerEmailId,
          tenant_password_length: table2.data.find(
            (tenant) => tenant.tenantStagingId === request.tenantStagingId,
          ).tenantPasswordLength,
          tenant_temp_pass_expiry: table2.data.find(
            (tenant) => tenant.tenantStagingId === request.tenantStagingId,
          ).tenantTempPassExpiry,
          tenant_mfa: table2.data.find(
            (tenant) => tenant.tenantStagingId === request.tenantStagingId,
          ).tenantMfa,
          tenant_remember_device: table2.data.find(
            (tenant) => tenant.tenantStagingId === request.tenantStagingId,
          ).tenantRememberDevice,
          tenant_web_client_access: table2.data.find(
            (tenant) => tenant.tenantStagingId === request.tenantStagingId,
          ).tenantWebClientAccess,
          tenantID: table2.data.find(
            (tenant) => tenant.tenantStagingId === request.tenantStagingId,
          ).tenantId,
        }));
        const latestRecord = combinedData.filter(
          (record) => record.tenantID === tenantID,
        );
        setTenantData(latestRecord);
      } catch (error) {
        log.error("Error fetching tenant Data", error);
      }
    }
    getData();
  }, []);

  const dialogStyle = {
    width: "500px",
    height: "1000px",
  };

  return (
    <div>
      <Dialog
        open={open}
        close={handleClose}
        aria-labelledby="responsive-dialog-title"
      >
        <div className="success-icon-container">
          <CheckCircleOutlineIcon fontSize="50px" className="success-icon" />
        </div>
        <Paper style={dialogStyle}>
          <DialogTitle sx={{ textAlign: "center" }}>
            <Typography variant="h6">Tenant Onboarding Initiated</Typography>
            <Typography fontWeight={"bold"} variant="body2" color={"green"}>
              Please Check Status of Corresponding Tenant to Verify if Onboaring
              was Successful!!
            </Typography>
          </DialogTitle>
          {tenantData.map((tenant, index) => (
            <div key={index}>
              <DialogContent>
                <h6>Tenant Details</h6>
                <p>Domain : {`${tenant.tenant_domain}`}</p>
                <p>Tenant Name : {`${tenant.tenant_name}`}</p>
                <p>Description : {`${tenant.tenant_description}`}</p>
                <p>Mail id : {`${tenant.tenant_owner_email_id}`}</p>
                <br />
                <h6>Password Policy</h6>
                <p>
                  Password Length : {`${tenant.tenant_password_length}`}{" "}
                  Characters
                </p>
                <p>
                  Password Expiration : {`${tenant.tenant_temp_pass_expiry}`}{" "}
                  Days
                </p>
                <p>Multifactor Authentication : {`${tenant.tenant_mfa}`}</p>
                <p>Remember Device : {`${tenant.tenant_remember_device}`}</p>
                <p>
                  Web Client Access : {`${tenant.tenant_web_client_access}`}
                </p>
                <br />
                <h6>Status</h6>
                <p>Status : {`${tenant.status}`}</p>
                <p>
                  Requested On :{" "}
                  {`${convertUTCDateToLocalDate(tenant.requested_on)}`}
                </p>
                <p>
                  Created On :{" "}
                  {`${convertUTCDateToLocalDate(tenant.created_on)}`}
                </p>
                {/* <h6>Requested By : {`${tenant.requested_by}`}</h6> */}
                {/* <h6>Created By : {`${tenant.created_by}`}</h6> */}
              </DialogContent>
            </div>
          ))}
          <DialogActions style={{ justifyContent: "center", display: "flex" }}>
            <Button
              style={{
                alignItems: "center",
                backgroundColor: "green",
              }}
              variant="contained"
              onClick={handleClose}
            >
              done
            </Button>
          </DialogActions>
        </Paper>
      </Dialog>
    </div>
  );
}
