import { Paper, Typography } from '@mui/material';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import React from 'react';
import Lottie from "react-lottie";
import fileUploadIconSuccess from "../assets/lottie/Uploadsucess.json";
import '../pages/tenantOnboard/tenantData.css';

export default function FileUploadSuccessModal({openDialog,onHandleClose,message}) 
{
    const dialogStyle = {
        width: '350px',
        height: '270px',
      };

      const defaultOptions = {
        loop: true,
        autoplay: true,
        animationData: fileUploadIconSuccess,
        rendererSettings: {
          preserveAspectRatio: "xMidYMid slice",
        },
      };

  return (
    <div>
      <Dialog 
        // sx={{backgroundColor: 'rgba(84, 84, 84, 0.8)'}}
        open={openDialog}
        close={onHandleClose}
        aria-labelledby="responsive-dialog-title"
      >
        <Paper style={dialogStyle}>
        <DialogContent style={{ justifyContent: 'center', display: 'flex', flexDirection:'column', alignItems:'center' }}>  
        <Lottie options={defaultOptions} height={80} width={120}/>
           <Typography variant='h6' fontWeight={'bold'} marginTop={"15px"}>{message}</Typography>   
           {/* <p>Total Documents Uploaded : 1</p>     */}
        </DialogContent>
        <DialogActions style={{ justifyContent: 'center', display: 'flex' }}>
          <Button
            style={{
              backgroundColor: "#19172C",
            }}
            variant="contained"
            onClick={onHandleClose}
          >
            OK
          </Button>
        </DialogActions>
        </Paper>
      </Dialog>
    </div>
  );
}