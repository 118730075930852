import * as type from '../type';

const initialState = {
    cred:null
};

export default function cred(state=initialState,action) {
    switch(action.type) {
        case type.set_cred:
            return {
                ...state,
                cred:action.payload,
            }
            default:
                return state
    }
}