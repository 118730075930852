import { useSelector } from "react-redux";

const UserRole = () => {
  const CURRENT_USER_TYPE = useSelector((state) => state.userType.userType);
  const superAdminRole = "superadminrole";
  const adminRole = "adminrole";
  const analystRole = "analystrole";

  const isSuperAdmin =
    CURRENT_USER_TYPE &&
    CURRENT_USER_TYPE.localeCompare(superAdminRole, undefined, {
      sensitivity: "base",
    }) === 0;

  const isAdmin =
    CURRENT_USER_TYPE &&
    CURRENT_USER_TYPE.localeCompare(adminRole, undefined, {
      sensitivity: "base",
    }) === 0;

  const isAnalyst =
    CURRENT_USER_TYPE &&
    CURRENT_USER_TYPE.localeCompare(analystRole, undefined, {
      sensitivity: "base",
    }) === 0;

  return { isAdmin, isAnalyst, isSuperAdmin };
};

export default UserRole;
