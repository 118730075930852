import * as type from '../type';

const initialState = {
    userType:null
};

export default function userType(state=initialState,action) {
    switch(action.type) {
        case type.set_user_type:
            return {
                ...state,
                userType:action.payload,
            }
            default:
                return state
    }
}