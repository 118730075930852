import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import ArrowDropUpIcon from "@mui/icons-material/ArrowDropUp";
import ReplayIcon from "@mui/icons-material/Replay";
import SearchIcon from "@mui/icons-material/Search";
import VisibilityIcon from "@mui/icons-material/Visibility";
import { FormControl } from "@mui/material";
import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import { FiEdit } from "react-icons/fi";
import Pagination from "../pageHelpers/pagination";

import Handler from "../../components/handler";
import useAxiosApiRequest from "../../hook/useAxiosApiRequest";
import "../deviceModel/viewDeviceModel.css";
import CustomButton from "../pageHelpers/button";
import UserRole from "../pageHelpers/userRole";
import CsvUpload from "./csvUpload";
import DeviceDataModal from "./viewDeviceData";

export default function ViewOnboardDevice() {
  const { statusCode, loading, apiRequest } = useAxiosApiRequest();
  const tenantId = JSON.parse(localStorage.getItem("TenantID"));
  const { isAdmin } = UserRole();

  //for search operation
  const [value, setvalue] = useState("");
  const [devices, setDevices] = useState([]);
  const [selectedDeviceModal, setSelectedDeviceModal] = useState(null);
  const [deviceTypeData, setdeviceTypeData] = useState([]);
  const [selectedDeviceType, setSelectedDeviceType] = useState("null");
  const [selctedFilterType, setSelectedFilterType] = useState("null");
  const [modelNames, setmodelNames] = useState([]);
  const [sortField, setSortField] = useState("null");
  const [sortOrder, setSortOrder] = useState("desc");
  const [pageNumber, setPageNumber] = useState(1);
  const [totalRecords, setTotalRecords] = useState(0);
  const [dataLength, setDataLength] = useState(0);
  const [invalidValue, setinvalidValue] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [ openFileUploadDialog, setOpenFileUploadDialog ] = useState(false);
  const [formData, setFormData] = useState({
    device_type: "",
    model_name: "",
  });
  const { device_type, model_name } = formData;

  useEffect(() => {
    if (value != " ") {
      setinvalidValue(true);
    }
  }, [value]);

  useEffect(() => {
    getOnboarddevices(1, null, null, "null", "desc", null);
    getDeviceTypeData();
    getModelNames();
  }, []);

  const devicesPerPage = 20;
  const navigate = useNavigate();

  const handleOpenModal = (device) => {
    setSelectedDeviceModal(device);
    setIsModalOpen(true);
  };

  const handleCloseModal = () => {
    setIsModalOpen(false);
  };

  const handleUploadFile = () => {
    setOpenFileUploadDialog(true);
  }

  const handleCloseFileUploadDialog = () => {
    setOpenFileUploadDialog(false);
  };

  const handleCreateOnClick = () => {
    navigate("/viewDevice/onboardDevice");
  };

  const handleInputChange = (e) => {
    setvalue(e.target.value);
  };

  const handleUpdateOnClick = (serialNumber,deviceStatus) => {
    if (deviceStatus != "INACTIVE") {
      navigate(`/viewDevice/Updatedevice/${serialNumber}`);
    }
  };

  const handleSearch = (e) => {
    getOnboarddevices(1, null, null, "null", "desc", value);
  };

  const handleResetFilters = () => {
    setFormData({
      device_type: "",
      model_name: "",
    });
    getOnboarddevices(1, null, null, "null", "desc", null);
  };

  const handleResetSearch = () => {
    setvalue("");
    getOnboarddevices(1, null, null, "null", "desc", null);
  };


  const handleSort = (field) => {
    console.log(field);
    let newOrder = "asc";
    if (sortField === field && sortOrder === "asc") {
      newOrder = "desc";
    }
    setSortField(field);
    setSortOrder(newOrder);
    getOnboarddevices(
      pageNumber,
      selctedFilterType,
      selectedDeviceType,
      field,
      newOrder,
      null
    );
  };

  const handleDeviceTypeChange = (e) => {
    setFormData((prevState) => ({
      ...prevState,
      [e.target.id]: e.target.value,
    }));
    setSelectedFilterType("deviceType.name");
    setSelectedDeviceType(e.target.value);
    getOnboarddevices(
      1,
      `deviceType.name`,
      e.target.value,
      "null",
      "desc",
      null
    );
  };

  const handleModelNameChange = (e) => {
    setFormData((prevState) => ({
      ...prevState,
      [e.target.id]: e.target.value,
    }));
    const selectedModelName = e.target.value;
    const selectedModelNameId = modelNames.find(
      (desc) => desc.modelName === selectedModelName
    );
    const modelID = selectedModelNameId?._id;
    setSelectedFilterType("deviceModel");
    setSelectedDeviceType(modelID);
    getOnboarddevices(1, `deviceModel`, modelID, "null", "desc", null);
  };

  const changePage = (selected) => {
    const page = selected?.nextSelectedPage;
    if (page != undefined) {
      setPageNumber(page + 1);
      getOnboarddevices(
        page + 1,
        selctedFilterType,
        selectedDeviceType,
        sortField,
        sortOrder,
        null
      );
    }
  };

  const getDeviceTypeData = async () => {
    const response = await apiRequest({
      endpoint: `/deviceservice/masterdata/deviceType`,
      method: "GET",
      additionalParams: {
        headers: {
          "tenant-id": tenantId
        } 
      },
      body: {},
    });
    const Data = response?.deviceMasterData;
    if (Array.isArray(Data)) {
      setdeviceTypeData(Data);
    }
  };

  const getModelNames = async () => {
    const response = await apiRequest({
      endpoint: `/deviceservice/devicemodel/allmodels/60/1/null/null/null/desc`,
      method: "GET",
      additionalParams: {
        headers: {
          "tenant-id": tenantId
        } 
      },
      body: {},
    });
    const Data = response?.deviceModels;
    if (Array.isArray(Data)) {
      setmodelNames(Data);
    }
  };

  const getOnboarddevices = async (
    selected,
    filtertype,
    filterTypeValue,
    field,
    newOrder,
    searchValue
  ) => {
    const response = await apiRequest({
      endpoint: `/deviceservice/device/alldevices/${devicesPerPage}/${selected}/${filtertype}/${filterTypeValue}/${searchValue}/${field}/${newOrder}`,
      method: "GET",
      additionalParams: {
        headers: {
          "tenant-id": tenantId
        } 
      },
      body: {},
    });
    const Data = response?.devices;
    const totalRecords = response?.totalPageCount;
    const length = response?.totalDocumentCount;
    if (Array.isArray(Data)) {
      setDevices(Data);
      setTotalRecords(totalRecords);
      setDataLength(length);
    }
  };


  return (
    <Handler statusCode={statusCode} loading={loading} >
    <div style={{paddingLeft:'35px'}}>
      <CsvUpload open={openFileUploadDialog}
       handleClose={handleCloseFileUploadDialog} 
       reloaDeviceData={getOnboarddevices} 
       />
      <DeviceDataModal
          isOpen={isModalOpen}
          handleClose={handleCloseModal}
          device={selectedDeviceModal}
          reloaDeviceData={getOnboarddevices}
        />
      <div className="heading">
        <h5>Onboarded Device</h5>
        <div style={{ display: "flex", alignItems: "center" }}>
          <div style={{ paddingRight: "20px" }}>
            <FormControl style={{ width: "250px" }} size="small">
              <select
                className="form-control"
                id="device_type"
                value={device_type}
                onChange={handleDeviceTypeChange}
              >
                <option disabled selected value={""}>
                  Filter By Device Type
                </option>
                {deviceTypeData &&
                  deviceTypeData.map((getDeviceTypes) => (
                    <option key={getDeviceTypes.id}>
                      {getDeviceTypes.name}
                    </option>
                  ))}
              </select>
            </FormControl>
          </div>
          <div style={{ paddingRight: "20px" }}>
            <FormControl style={{ width: "250px" }} size="small">
              <select
                className="form-control"
                id="model_name"
                value={model_name}
                onChange={handleModelNameChange}
                required
              >
                <option selected disabled value={""}>
                  Filter By Model Name
                </option>
                {modelNames &&
                  modelNames.map((getModelName) => (
                    <option key={getModelName.id}>
                      {getModelName.modelName}
                    </option>
                  ))}
              </select>
            </FormControl>
          </div>
          <button className="resetButton" onClick={handleResetFilters}>
            <ReplayIcon titleAccess="Reset Filters" />
          </button>
        </div>
      </div>
      <br />
      <div style={{ display: "flex", alignItems: "center", maxWidth:'700px' }}>
        <div style={{ position: "relative", flex: 1 }}>
          <input
            type="text"
            className="form-control"
            placeholder="Search data by VIN or MAC Address or IMEI or Serial Number..."
            value={value}
            onChange={handleInputChange}
            style={{
              // flex: "1",
              maxWidth: "100%",
              border: "1px solid #080808",
              borderRadius: "5px 0 0 5px",
              padding: "5px 35px 5px 35px",
            }}
          />
          <span
            style={{
              position: "absolute",
              left: "10px",
              top: "50%",
              transform: "translateY(-50%)",
              cursor: "pointer",
            }}
            onClick={handleSearch}
          >
            <SearchIcon />
          </span>
        </div>
        <button
          disabled={!invalidValue}
          style={{
            marginRight: "10px",
            padding: "5px 10px",
            border: "1px solid #080808",
            backgroundColor: "#080808",
            color: "#ffffff",
            borderRadius: "0 5px 5px 0",
          }}
          onClick={handleSearch}
        >
          Search
        </button>
        <button className="resetButton" onClick={handleResetSearch}>
          Clear search
        </button>
      </div>

      <br></br>
      <Paper sx={{ width: "100%", overflow: "hidden" }}>
        <TableContainer sx={{ maxHeight: 440 }}>
          <Table stickyHeader aria-label="sticky table">
            <TableHead sx={{ maxHeight: 20 }}>
              <TableRow sx={{ cursor: "pointer" }}>
                <TableCell
                  sx={{
                    fontWeight: 700,
                    whiteSpace: "nowrap",
                    overflow: "hidden",
                    textOverflow: "ellipsis"
                  }}
                  align="center"
                  onClick={() => handleSort("deviceType.name")}
                >
                  Device Type
                  {sortField === "deviceType.name" && sortOrder === "asc" ? (
                    <ArrowDropUpIcon />
                  ) : (
                    <ArrowDropDownIcon />
                  )}
                </TableCell>
                <TableCell
                  sx={{
                    fontWeight: 700,
                    whiteSpace: "nowrap",
                    overflow: "hidden",
                    textOverflow: "ellipsis"
                  }}
                  align="center"
                  onClick={() => handleSort("deviceModel")}
                >
                  Model Name
                  {sortField === "deviceModel" && sortOrder === "asc" ? (
                    <ArrowDropUpIcon />
                  ) : (
                    <ArrowDropDownIcon />
                  )}
                </TableCell>
                <TableCell
                  sx={{
                    fontWeight: 700,
                    whiteSpace: "nowrap",
                    overflow: "hidden",
                    textOverflow: "ellipsis"
                  }}
                  align="center"
                  onClick={() => handleSort("deviceIdentifiers.serialNumber")}
                >
                  Serial Number
                  {sortField === "deviceIdentifiers.serialNumber" &&
                  sortOrder === "asc" ? (
                    <ArrowDropUpIcon />
                  ) : (
                    <ArrowDropDownIcon />
                  )}
                </TableCell>
                <TableCell
                  sx={{
                    fontWeight: 700,
                    whiteSpace: "nowrap",
                    overflow: "hidden",
                    textOverflow: "ellipsis"
                  }}
                  align="center"
                  onClick={() => handleSort("deviceIdentifiers.VIN")}
                >
                  VIN
                  {sortField === "deviceIdentifiers.VIN" &&
                  sortOrder === "asc" ? (
                    <ArrowDropUpIcon />
                  ) : (
                    <ArrowDropDownIcon />
                  )}
                </TableCell>
                <TableCell
                  sx={{
                    fontWeight: 700,
                    whiteSpace: "nowrap",
                    overflow: "hidden",
                    textOverflow: "ellipsis"
                  }}
                  align="center"
                  onClick={() => handleSort("currentFirmwareVersion")}
                >
                  Firmware Version
                  {sortField === "currentFirmwareVersion" &&
                  sortOrder === "asc" ? (
                    <ArrowDropUpIcon />
                  ) : (
                    <ArrowDropDownIcon />
                  )}
                </TableCell>
                <TableCell
                  sx={{
                    fontWeight: 700,
                    whiteSpace: "nowrap",
                    overflow: "hidden",
                    textOverflow: "ellipsis"
                  }}
                  align="center"
                  onClick={() => handleSort("deviceIdentifiers.imei")}
                >
                  IMEI
                  {sortField === "deviceIdentifiers.imei" &&
                  sortOrder === "asc" ? (
                    <ArrowDropUpIcon />
                  ) : (
                    <ArrowDropDownIcon />
                  )}
                </TableCell>
                <TableCell
                  sx={{
                    fontWeight: 700,
                    whiteSpace: "nowrap",
                    overflow: "hidden",
                    textOverflow: "ellipsis"
                  }}
                  align="center"
                  onClick={() => handleSort("deviceIdentifiers.macAddress")}
                >
                  MAC
                  {sortField === "deviceIdentifiers.macAddress" &&
                  sortOrder === "asc" ? (
                    <ArrowDropUpIcon />
                  ) : (
                    <ArrowDropDownIcon />
                  )}
                </TableCell>
                {isAdmin ? (
                  <TableCell sx={{ fontWeight: 700 }} align="center">
                        Action
                  </TableCell>
                ) : null}
              </TableRow>
            </TableHead>
            <TableBody>
              {devices &&
                devices
                  .map((device, index) => {
                    return (
                      <TableRow
                        style={
                          index % 2
                            ? { backgroundColor: "rgba(72, 34, 180, 0.04)" }
                            : { backgroundColor: "white" }
                        }
                        hover
                        role="checkbox"
                        tabIndex={-1}
                        key={device.id}
                      >
                        <TableCell align="center">
                          {device.deviceType?.name || "--"}
                        </TableCell>
                        <TableCell align="center">
                          {device.deviceModel[0]?.modelName || "--"}
                        </TableCell>
                        <TableCell
                          align="center"
                        >
                            {device.deviceType?.deviceIdType==='serialnumber'? device.deviceId : device.deviceIdentifiers?.serialNumber || "-"}
                            {/* {device.deviceIdentifiers?.serialNumber} */}
                        </TableCell>
                        <TableCell align="center">
                            {/* {data.deviceID === 'vin' ? data.vin : device.deviceIdentifiers?.VIN} */}
                            {device.deviceType?.deviceIdType==='vin'? device.deviceId : device.deviceIdentifiers?.VIN || "-"}
                            {/* {device.deviceIdentifiers?.VIN} */}
                        </TableCell>
                        <TableCell align="center">
                            {device.currentFirmwareVersion}
                          </TableCell>
                        <TableCell align="center">
                        {device.deviceType?.deviceIdType==='imei'? device.deviceId : device.deviceIdentifiers?.imei || "-"}
                        </TableCell>
                        <TableCell align="center">
                        {device.deviceType?.deviceIdType==='macaddress'? device.deviceId : device.deviceIdentifiers?.macAddress || "-"}
                        </TableCell>
                        {isAdmin ? (
                            <TableCell  sx={{
                              display: "block",
                            }} >
                            <FiEdit
                              style={{
                                  marginRight: "10px",
                                  fontSize: "110%",
                                color:
                                    device.deviceStatus === "INACTIVE"
                                      ? "grey"
                                      : "#4822B4",
                              }}
                              title={"Update"}
                              onClick={() =>
                                handleUpdateOnClick(
                                    device?.deviceId,
                                  device?.deviceStatus
                                )
                              }
                            />
                              <VisibilityIcon
                               onClick={() => handleOpenModal(device)}
                                style={{
                                  fontSize: "120%",
                                  color: "#4822B4",cursor: "pointer"
                                }}
                                title={"View"}
                              />
                          </TableCell>
                        ) : null}
                      </TableRow>
                    );
                  })}
            </TableBody>
          </Table>
        </TableContainer>
        <br></br>
            {/* pagination for table */}
            <Pagination
            tablehd={"Available Devices"}
            dataLength={dataLength}
            totalRecords={totalRecords}
            onClick={changePage}
          />
      </Paper>
      <br></br>
      <div className="buttongroup">
      <CustomButton onClick={handleCreateOnClick}>Onboard Device</CustomButton>
      <CustomButton onClick={handleUploadFile}>Onboard Bulk Device</CustomButton>
      </div>
    </div>
    </Handler>
  );
}
