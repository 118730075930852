import React from "react";

function NotFound() {
  return (
    <div style={{ textAlign: "center", marginTop: "50px" }}>
      <h1>404 Not Found</h1>
      <p>The page youre looking for doesnt exist</p>
    </div>
  );
}

export default NotFound;
