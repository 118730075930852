import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import useAxiosApiRequest from "../../hook/useAxiosApiRequest";
import {
  Box,
  Button,
  Typography,
  FormControl,
  FormGroup,
  FormControlLabel,
  Checkbox,
} from "@mui/material";
import { ADMIN_PERMISSIONS } from "../../constants/constants";
import Handler from "../../components/handler";
import CheckIcon from "@mui/icons-material/Check";
import { ClipLoader } from "react-spinners";
import Breadcrumb from "../../breadCrumbs/navigation";

function EditUser() {
  const { userName } = useParams();
  const navigate = useNavigate();
  const [userDetails, setUserDetails] = useState(null);
  const [permissions, setPermissions] = useState([]);
  const [loadingIcon, setLoadingIcon] = useState(false);
  const { statusCode, errormsg, loading, apiRequest } = useAxiosApiRequest();
  const tenantID = localStorage.getItem("TenantID");
  const tenant = tenantID ? JSON.parse(tenantID) : null;
  let userPool = JSON.parse(
    localStorage.getItem("amplifyConfig"),
  ).aws_user_pools_id;
  useEffect(() => {
    getUserData(userName);
  }, [userName]);

  const getUserData = async (userName) => {
    try {
      setLoadingIcon(true);
      const response = await apiRequest({
        endpoint: `/cognitoservice/userprofileinfo/allUsers/Admin/10/1/null/null/${userName}/null/desc`,
        method: "GET",
        additionalParams: {
          headers: {
            "tenant-id": tenant,
          },
        },
        body: {},
      });

      // Ensure response and userProfileDetails exist
      if (
        response?.userProfileDetails &&
        response.userProfileDetails.length > 0
      ) {
        setLoadingIcon(false);
        const user = response.userProfileDetails[0].userAttributes;
        setUserDetails(user);
        setPermissions(user.permissions || []); // Default to an empty array if undefined
      } else {
        setLoadingIcon(false);
        console.error("No user details found");
      }
    } catch (e) {
      setLoadingIcon(false);
      console.error(e);
    }
  };

  const handlePermissionChange = (event) => {
    const { value, checked } = event.target;
    setPermissions((prev) =>
      checked ? [...prev, value] : prev.filter((perm) => perm !== value),
    );
  };

  const handleSubmit = async () => {
    // Handle form submission logic here
    try {
      const response = await apiRequest({
        endpoint: `/cognitoservice/webuserprofileinfo/Admin/${userName}`,
        method: "PUT",
        additionalParams: {
          headers: {
            "tenant-id": tenant,
            "userpool-id": userPool,
          },
        },
        body: {
          updatedPermissions: permissions,
        },
      });
      // console.log(response);
      if (response) navigate("/user/userList");
    } catch (e) {
      console.error(e);
    }
  };

  return (
    <div>
      <Handler statusCode={statusCode} loading={loading} errormsg={errormsg} />
      {loadingIcon && <div className="spinner-overlay"></div>}
      <div className="spinner-container">
        {loadingIcon && <ClipLoader color="#000000" size={50} />}
      </div>
      <Breadcrumb />
      <Box sx={{ padding: 2 }}>
        <Typography variant="h4">User Details</Typography>
        <Typography variant="body1">
          Username: {userDetails?.userName}{" "}
          {userDetails?.accountConfirmed && (
            <CheckIcon
              titleAccess="Verified account"
              sx={{ color: "green", marginLeft: 1 }}
            />
          )}
        </Typography>
        <Typography variant="body1">
          Email: {userDetails?.email}{" "}
          {userDetails?.emailVerified && (
            <CheckIcon
              titleAccess="Verified email"
              sx={{ color: "green", marginLeft: 1 }}
            />
          )}
        </Typography>
        <Typography variant="body1">
          Status: {userDetails?.cognitoUserStatus}
        </Typography>

        <FormControl fullWidth component="fieldset" sx={{ marginTop: 2 }}>
          <Typography variant="h6">Permissions</Typography>
          <FormGroup>
            {ADMIN_PERMISSIONS.map((permission) => (
              <FormControlLabel
                key={permission}
                control={
                  <Checkbox
                    checked={permissions.includes(permission.toLowerCase())}
                    onChange={handlePermissionChange}
                    value={permission.toLowerCase()}
                  />
                }
                label={permission}
              />
            ))}
          </FormGroup>
        </FormControl>

        <Button
          variant="contained"
          color="primary"
          onClick={handleSubmit}
          sx={{ marginTop: 2 }}
        >
          Submit Changes
        </Button>
      </Box>
    </div>
  );
}

export default EditUser;
