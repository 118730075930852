import React from "react";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import { Paper, DialogTitle } from "@mui/material";
import UploadFirmware from "./UploadFirmware";

export default function UploadModal({
  openDialog,
  handleUploadFirmware,
  onHandleClose,
  editData,
}) {
  return (
    <div>
      <Dialog
        open={openDialog}
        onClose={onHandleClose}
        aria-labelledby="responsive-dialog-title"
        // style={dialogStyle}
        fullWidth
      >
        <Paper>
          <DialogTitle
            id="responsive-dialog-title"
            style={{
              backgroundColor: "#1e0109",
              color: "#fff",
              marginBottom: "8px",
            }}
          >
            {editData === null ? "Upload File" : "Edit File"}
          </DialogTitle>
          <DialogContent
            style={{ justifyContent: "center", display: "flex-inline" }}
          >
            <UploadFirmware
              editData={editData}
              onHandleClose={onHandleClose}
              handleUploadFirmware={handleUploadFirmware}
            />
          </DialogContent>
        </Paper>
      </Dialog>
    </div>
  );
}
