import CommuteIcon from "@mui/icons-material/Commute";
import DirectionsCarIcon from "@mui/icons-material/DirectionsCar";
import HomeRoundedIcon from "@mui/icons-material/HomeRounded";
import HubIcon from "@mui/icons-material/Hub";
import NoteAddIcon from "@mui/icons-material/NoteAdd";
import RestartAltOutlinedIcon from "@mui/icons-material/RestartAltOutlined";
import MemoryIcon from '@mui/icons-material/Memory';
import React from "react";

//NavbarData is defined in three functions which can be used to update UI based on user role
//NavbarDataAdmin will be for the admin UI
export const NavbarDataAdmin = [
  {
    title: "Dashboard",
    icon: <HomeRoundedIcon />,
    path: "/dashboard",
  },
  {
    title: "Device Models",
    icon: <DirectionsCarIcon />,
    path: "/deviceModels",
  },
  {
    title: "Devices",
    icon: <CommuteIcon />,
    path: "/viewDevice",
  },
  {
    title: "Firmware Management",
    icon: <MemoryIcon />,
    path: "/listFirmware",
  },
  {
    title: "DeviceGroup",
    icon: <CommuteIcon />,
    path: "/viewDeviceGroup",
  },
  //   {
  //   title: "Campaign",
  //   icon: <CampaignIcon />,
  //   path: "/viewCampaign",
  // },
  {
    title: "Add User",
    icon: <NoteAddIcon />,
    path: "/user/addUser",
  },
  {
    title: "View Users",
    icon: <RestartAltOutlinedIcon />,
    path: "/user/userList",
  },
];

//NavbarDataSuperAdmin will be only for SuperAdmin
export const NavbarDataSuperAdmin = [
  {
    title: "Dashboard",
    icon: <HomeRoundedIcon />,
    path: "/dashboard",
  },
  {
    title: "Add User",
    icon: <NoteAddIcon />,
    path: "/user/addUser",
  },
  {
    title: "View Users",
    icon: <RestartAltOutlinedIcon />,
    path: "/user/userList",
  },
  {
    title: "Cluster Monitoring",
    icon: <HubIcon fontSize="small" />,
    path: "/clusterMonitoring",
  },
  {
    title: "Onboard Tenant",
    icon: <NoteAddIcon />,
    path: "/onboardTenant",
  },
  {
    title: "Tenant Status",
    icon: <RestartAltOutlinedIcon />,
    path: "/OnboardStatus",
  },
];

//NavbarDataAnalyst will be displayed for Analyst
export const NavbarDataAnalyst = [
  {
    title: "Dashboard",
    icon: <HomeRoundedIcon />,
    path: "/dashboard",
  },
  {
    title: "Device Models",
    icon: <DirectionsCarIcon />,
    path: "/deviceModels",
  },
  {
    title: "Onboard Device",
    icon: <CommuteIcon />,
    path: "/viewDevice",
  },
  {
    title: "DeviceGroup",
    icon: <CommuteIcon />,
    path: "/viewDeviceGroup",
  },
  {
    title: "View Users",
    icon: <RestartAltOutlinedIcon />,
    path: "/user/userList",
  },
];
