import React from "react";
import { FaBackward, FaForward } from "react-icons/fa";
import ReactPaginate from "react-paginate";
import "../deviceModel/viewDeviceModel.css";

const Pagination = ({ tablehd,dataLength, onClick, totalRecords }) => {
  return (
    <div className="pagenate">
        <h6>{tablehd}</h6>
        <div>
      {/* pagination for table */}
      <ReactPaginate
        previousLabel={<FaBackward />}
        nextLabel={<FaForward />}
        breakLabel={"...."}
        marginPagesDisplayed={2}
        pageCount={totalRecords}
        // onPageChange={changePage}
        containerClassName={"paginationBttns"}
        previousLinkClassName={"previousBttns"}
        disabledClassName={"paginationDisabled"}
        activeClassName={"paginationActive"}
        onClick={onClick}
      ></ReactPaginate>
      </div>
      <h6>Total Records : {dataLength}</h6>
    </div>
  );
};

export default Pagination;
