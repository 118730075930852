import React, { useEffect, useState, useContext } from "react";
import log from "loglevel";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import useMediaQuery from "@mui/material/useMediaQuery";
import { useTheme } from "@mui/material/styles";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";

import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";
import HighlightOffIcon from "@mui/icons-material/HighlightOff";
import ScheduleIcon from "@mui/icons-material/Schedule";
import { DialogContentText } from "@mui/material";
import { AuthContext } from "../../App";

var apigClientFactory = require("aws-api-gateway-client").default;

export default function ViewProgress({ tenantID, open, handleClose }) {
  const { cred } = useContext(AuthContext);
  const { tokens } = useContext(AuthContext);
  const accessKeyId = cred.accessKeyId;
  const SecretKey = cred.secretAccessKey;
  const SessionToken = cred.sessionToken;
  const region = tokens.region;

  const config = {
    invokeUrl: process.env.REACT_APP_SERVICE_URL_v2,
    region: region,
    accessKey: accessKeyId,
    secretKey: SecretKey,
    sessionToken: SessionToken,
  };

  const apigClient = apigClientFactory.newClient(config);

  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down("md"));

  const [statusHistory, setStatusHistory] = useState([]);

  function convertUTCDateToLocalDate(val) {
    var dateLocal = new Date(val);
    var timestamp = new Date(
      dateLocal.getTime() - dateLocal.getTimezoneOffset(),
    );
    var result = timestamp.toLocaleString();
    return result;
  }

  useEffect(() => {
    async function getData() {
      var pathTemplate1 = "/tenantservice/tenantrequest";
      var pathTemplate2 = "/tenantservice/tenant";
      var method = "GET";
      var pathParams = {
        //This is where path request params go.
        //userId: '1234',
      };
      var additionalParams = {
        headers: {
          "Content-Type": "application/json",
          "tenant-id": tenantID,
        },
      };

      var body = {
        //This is where you define the body of the request
      };
      try {
        const [table1, table2] = await Promise.all([
          apigClient.invokeApi(
            pathParams,
            pathTemplate1,
            method,
            additionalParams,
            body,
          ),
          apigClient.invokeApi(
            pathParams,
            pathTemplate2,
            method,
            additionalParams,
            body,
          ),
        ]);
        const combinedData = table1.data.map((request) => ({
          requestedOn: request.requestedOn,
          tenantDomain: table2.data.find(
            (tenant) => tenant.tenantStagingId === request.tenantStagingId,
          ).tenantName,
          tenantDescription: table2.data.find(
            (tenant) => tenant.tenantStagingId === request.tenantStagingId,
          ).tenantDescription,
          tenantID: table2.data.find(
            (tenant) => tenant.tenantStagingId === request.tenantStagingId,
          ).tenantId,
        }));
        // const latestRecord = combinedData.filter((record) => record.tenantID === tenantID);
        setStatusHistory(combinedData);
      } catch (error) {
        log.error("Errr fetching tenant data", error);
      }
    }
    getData();
  }, []);

  const [messages, setMessages] = useState([]);

  useEffect(() => {
    const responseJson = localStorage.getItem(tenantID);
    const response = responseJson ? JSON.parse(responseJson) : null;
    if (response) {
      setMessages(response);
    } else {
      setMessages([]);
    }
  }, [messages]);

  // const expectedStrings =
  // [
  // { id: `tenant_id:${tenantID}`, string: "UserPoolTask" },
  // { id: `tenant_id:${tenantID}`, string: "IdentityPoolTask" },
  // { id: `tenant_id:${tenantID}`, string: "UserPoolDomainTask" },
  // { id: `tenant_id:${tenantID}`, string: "StoreTenantDataTask" },
  // { id: `tenant_id:${tenantID}`, string: "UserPoolGroupTask" },
  // { id: `tenant_id:${tenantID}`, string: "UserPoolClientTask" },
  // ];

  const expectedStrings = [
    "UserPoolTask",
    "IdentityPoolTask",
    "UserPoolDomainTask",
    "StoreTenantDataTask",
    "UserPoolGroupTask",
    "UserPoolClientTask",
  ];

  const iconStates = {
    UserPoolTask: false,
    IdentityPoolTask: false,
    UserPoolDomainTask: false,
    StoreTenantDataTask: false,
    UserPoolGroupTask: false,
    UserPoolClientTask: false,
  };

  for (let i = 0; i < messages.length; i++) {
    for (let j = 0; j < expectedStrings.length; j++) {
      if (JSON.stringify(messages[i]).includes(expectedStrings[j])) {
        iconStates[expectedStrings[j]] = true;
      }
    }
  }

  // for (let i = 0; i < messages.length; i++) {
  //   for (let j = 0; j < expectedStrings.length; j++) {
  //     const { id, string } = expectedStrings[j];
  //     if (
  //       JSON.stringify(messages[i]).includes(string) &&
  //       JSON.stringify(messages[i]).includes(id)
  //     ) {
  //       iconStates[string] = true;
  //     }
  //   }
  // }

  return (
    <div>
      <Dialog
        fullScreen={fullScreen}
        open={open}
        close={handleClose}
        aria-labelledby="responsive-dialog-title"
        // maxWidth='xl'
      >
        <Paper sx={{ width: "100%", overflow: "hidden" }}>
          <TableContainer sx={{ maxHeight: 440 }}>
            <Table stickyHeader aria-label="sticky table">
              <TableHead sx={{ maxHeight: 20 }}>
                <TableRow>
                  <TableCell sx={{ fontWeight: 700 }} align="center">
                    Tenant Name
                  </TableCell>
                  <TableCell sx={{ fontWeight: 700 }} align="center">
                    Description
                  </TableCell>
                  <TableCell sx={{ fontWeight: 700 }} align="center">
                    Requested On
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {statusHistory.map((tenant) => {
                  return (
                    <TableRow
                      hover
                      role="checkbox"
                      tabIndex={-1}
                      key={tenant.id}
                    >
                      <TableCell align="center">
                        {tenant.tenantDomain}
                      </TableCell>
                      <TableCell align="center">
                        {tenant.tenantDescription}
                      </TableCell>
                      <TableCell align="center">
                        {convertUTCDateToLocalDate(tenant.requestedOn)}
                      </TableCell>
                    </TableRow>
                  );
                })}
              </TableBody>
            </Table>
          </TableContainer>
        </Paper>
        <DialogTitle>Task Status</DialogTitle>
        <DialogContentText style={{ fontWeight: 500 }}>
          <DialogContent
            sx={{
              fontWeight: "bold",
              backgroundColor: "rgba(72, 34, 180, 0.04)",
            }}
          >
            {iconStates.UserPoolTask === true ? (
              <CheckCircleOutlineIcon sx={{ color: "#008E0E" }} />
            ) : Response === "Pending" ? (
              <ScheduleIcon />
            ) : (
              <HighlightOffIcon sx={{ color: "#FF0000" }} />
            )}
            Configure UserPool
          </DialogContent>
          <DialogContent sx={{ fontWeight: "bold" }}>
            {iconStates.UserPoolClientTask === true ? (
              <CheckCircleOutlineIcon sx={{ color: "#008E0E" }} />
            ) : Response === "Pending" ? (
              <ScheduleIcon />
            ) : (
              <HighlightOffIcon sx={{ color: "#FF0000" }} />
            )}{" "}
            Configure UserPool Client
          </DialogContent>
          <DialogContent
            sx={{
              fontWeight: "bold",
              backgroundColor: "rgba(72, 34, 180, 0.04)",
            }}
          >
            {iconStates.IdentityPoolTask === true ? (
              <CheckCircleOutlineIcon sx={{ color: "#008E0E" }} />
            ) : Response === "Pending" ? (
              <ScheduleIcon />
            ) : (
              <HighlightOffIcon sx={{ color: "#FF0000" }} />
            )}{" "}
            Configure IdentityPool
          </DialogContent>
          <DialogContent sx={{ fontWeight: "bold" }}>
            {iconStates.UserPoolDomainTask === true ? (
              <CheckCircleOutlineIcon sx={{ color: "#008E0E" }} />
            ) : Response === "Pending" ? (
              <ScheduleIcon />
            ) : (
              <HighlightOffIcon sx={{ color: "#FF0000" }} />
            )}{" "}
            Configure Tenant Domain
          </DialogContent>
          <DialogContent
            sx={{
              fontWeight: "bold",
              backgroundColor: "rgba(72, 34, 180, 0.04)",
            }}
          >
            {iconStates.StoreTenantDataTask === true ? (
              <CheckCircleOutlineIcon sx={{ color: "#008E0E" }} />
            ) : Response === "Pending" ? (
              <ScheduleIcon />
            ) : (
              <HighlightOffIcon sx={{ color: "#FF0000" }} />
            )}{" "}
            Configure DB Schema
          </DialogContent>
        </DialogContentText>
        {/* <DialogContent sx={{ fontWeight: 500 }}>
          {iconStates.UserPoolGroupTask === true ? (
            <CheckCircleOutlineIcon sx={{ color: "#008E0E" }} />
          ) : Response === "Pending" ? (
            <ScheduleIcon />
          ) : (
            <HighlightOffIcon sx={{ color: "#FF0000" }} />
          )}{" "}
          Configure UserPool Groups
        </DialogContent> */}

        <DialogActions>
          <Button
            style={{
              backgroundColor: "#19172C",
            }}
            variant="contained"
            color="warning"
            onClick={handleClose}
          >
            CLOSE
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}
