import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import CommuteIcon from "@mui/icons-material/Commute";
import GroupIcon from '@mui/icons-material/Group';
import { Grid, ListItem, Typography } from '@mui/material';
import Card from '@mui/material/Card';
import { default as React, useEffect, useState } from 'react';

import Handler from "../../components/handler";
import useAxiosApiRequest from "../../hook/useAxiosApiRequest";

const CardsAdmin = () => {

  const { statusCode, loading, apiRequest } = useAxiosApiRequest();
  const tenantId = JSON.parse(localStorage.getItem('TenantID'));

  const [dataLength, setDataLength ] = useState(0);
  const [lastonboardDate, setLastOnboardDate] = useState("");

  useEffect(() => {
    getOnboarddevices();
  },[]);

  const iconStyles = {
    fontSize: 40, 
    color: '#004c6d',
    backgroundColor:'#c1e7ff',
    borderRadius:"50%",
    padding: '8px',
    display: 'inline-block',
  }

  const cardtwoStyles = {
    backgroundColor:'#9dc6e0',
    height:'170px',
    border: '1px solid #a4a5a5a8',
    borderRadius: '5%',
    
  }

  const cardtwoListStyles = {
    marginTop:'10px',
    marginLeft:'20px',
    display:'flex',
    flexDirection:"column",
    alignItems:'baseline',
  }

  function convertUTCDateToLocalDate(val) {
    var dateLocal = new Date(val);
    var timestamp = new Date(
      dateLocal.getTime() - dateLocal.getTimezoneOffset()
    );
    var result = timestamp.toLocaleString();
    return result;
  }

  const getOnboarddevices = async () => 
  {
    const response = await apiRequest({
      endpoint: `/deviceservice/device/alldevices/60/1/null/null/null/null/desc`,
      method: "GET",
      additionalParams: {
        headers: {
          "tenant-id": tenantId
        } 
      },
      body: {},
    });
    const Data = response?.devices;
    if (Array.isArray(Data)) {
      const length = response?.totalDocumentCount;
      const lastDate = convertUTCDateToLocalDate(Data[0]?.createdAt);
      const modifiedDate = lastDate?.split(",");
      setDataLength(length);
      setLastOnboardDate(modifiedDate[0]);
    }
  };
  
  return (
    <div>
      <Handler statusCode={statusCode} loading={loading} />
        <Grid container spacing={2}>
        <Grid item xs={3}>
          <Card sx={cardtwoStyles}>
            <ListItem disableGutters sx={cardtwoListStyles}>
              <ListItem
                sx={{ display: "flex", justifyContent: "space-around" }}
              >
                <Typography sx={{ marginLeft: "-45px", fontWeight: "bold" }}>
                   Users
                </Typography>
                <GroupIcon sx={iconStyles} />
              </ListItem>
              <Typography fontSize={"30px"} color="#002644">
                15
              </Typography>
              <Typography sx={{ fontWeight: "bold" }} variant="caption">
                Last Created:17/01/2024
              </Typography>
            </ListItem>
          </Card>
        </Grid>
        <Grid item xs={3}>
          <Card sx={cardtwoStyles}>
            <ListItem disableGutters sx={cardtwoListStyles}>
              <ListItem
                sx={{ display: "flex", justifyContent: "space-around" }}
              >
                <Typography sx={{ marginLeft: "-45px", fontWeight: "bold" }}>
                   Devices
                </Typography>
                <CommuteIcon sx={iconStyles} />
              </ListItem>
              <Typography fontSize={"30px"} color="#002644">
                {dataLength}
              </Typography>
              <Typography sx={{ fontWeight: "bold" }} variant="caption">
                Last Onboarded:{lastonboardDate}
              </Typography>
            </ListItem>
          </Card>
        </Grid>
        <Grid item xs={3}>
          <Card sx={cardtwoStyles}>
            <ListItem disableGutters sx={cardtwoListStyles}>
              <ListItem
                sx={{ display: "flex", justifyContent: "space-around" }}
              >
                <Typography sx={{ marginLeft: "-45px", fontWeight: "bold" }}>
                   Campaigns
                </Typography>
                <CloudUploadIcon sx={iconStyles} />
              </ListItem>
              <Typography fontSize={"30px"} color="#002644">
                04
              </Typography>
              <Typography sx={{ fontWeight: "bold" }} variant="caption">
                Last Camapign:21/09/2023
              </Typography>
            </ListItem>
          </Card>
        </Grid>
      </Grid>
    </div>
  )
}

export default CardsAdmin