import { Dialog, Typography } from '@mui/material';
import Button from '@mui/material/Button';
import DialogActions from '@mui/material/DialogActions';
import React, { useContext, useEffect, useState } from 'react';
import { AuthContext } from "../../App";
import Handler from "../../components/handler";
import useAxiosApiRequest from "../../hook/useAxiosApiRequest";

const DeviceDataModal = ({ isOpen, handleClose, device, reloaDeviceData }) => {
  const { statusCode, loading, apiRequest } = useAxiosApiRequest();
  const { userName } = useContext(AuthContext);

  const tenant = JSON.parse(localStorage.getItem('TenantID'));
  const [isActive, setIsActive] = useState(true);

  useEffect(() => {
    if (device?.deviceStatus === "INACTIVE") {
      setIsActive(false);
    } else {
      setIsActive(true);
    }
  }, [device]);

  const dialogStyle = {
    width: '500px',
    height: '500px',
    padding:'15px',
  };

  const updatedDeviceData = {
    deviceType:device?. deviceType,
    deviceModel: device?.deviceModel,
    manufacturerName: device?.manufacturerName,
    originalFirmwareVersion: device?.originalFirmwareVersion,
    currentFirmwareVersion: device?.currentFirmwareVersion,
    year: device?.year,
    hardwareVersion: device?.hardwareVersion,
    thingName: null,
    updatedBy: userName,
    
  };

  //function to convert UTC to local time
  function convertUTCDateToLocalDate(val) {
    var dateLocal = new Date(val);
    var timestamp = new Date(
      dateLocal.getTime() - dateLocal.getTimezoneOffset()
    );
    var result = timestamp.toLocaleString();
    return result;
  }

  const handleToggleActive = async() => {
    setIsActive(prevState => !prevState);
    let updatedstatus = !isActive ? "ACTIVE" : "INACTIVE";
    deviceStatusUpdate(updatedstatus);
  };

  const deviceStatusUpdate = async(deviceStatus) => {
    const response = await apiRequest({
        endpoint: `/deviceservice/device/deviceId/${device?.deviceId}`,
        method: "PUT",
        additionalParams: {
          headers: {
            "tenant-id": tenant
          } 
        },
        body: {...updatedDeviceData,deviceStatus},
      });
      if(response) {
        handleClose();
          reloaDeviceData(1, null, null, "null", "desc", null)
      } else {
        handleClose();
      }
  }

  return (
    <Handler statusCode={statusCode} loading={loading} >
    <Dialog
      style={{
        fontSize: "110%",
        fontWeight: "90%",
        borderRadius: "5%",
      }}
      open={isOpen}
      onClose={handleClose}
      aria-labelledby="responsive-dialog-title"
    >
      <div style={dialogStyle}>
        <div style={{display:'flex',justifyContent:'space-between'}}>
        <Typography fontWeight={"bold"} fontSize={'25px'} color={"#4822B4"} gutterBottom>
          {device?.deviceId}
        </Typography>
        <button 
        type="button" 
        className="btn btn-dark"
        onClick={handleToggleActive}>
          {isActive ? "Deactivate Device Model" : "Activate Device Model"}
          </button>
        </div>
        <Typography fontWeight={"bold"} style={{ display: 'flex' }} gutterBottom >
          Device Status : 
          <p>{device?.deviceStatus}</p>
        </Typography>
        <Typography gutterBottom style={{ display: 'flex' }}>
          <p style={{fontWeight:'bold'}}>Manufacturer :</p>
          <p>{device?.manufacturerName}</p>
        </Typography>
        <Typography gutterBottom style={{ display: 'flex' }}>
          <p style={{fontWeight:'bold'}}>Year :</p>
          <p>{device?.year}</p>
        </Typography>
        <Typography gutterBottom style={{ display: 'flex' }}>
          <p style={{fontWeight:'bold'}}>Hardware Version :</p>
          <p>{device?.hardwareVersion}</p>
        </Typography>
        <Typography gutterBottom style={{ display: 'flex' }}>
          <p style={{fontWeight:'bold'}}>Original Firmware Version :</p>
          <p>{device?.originalFirmwareVersion}</p>
        </Typography>
        <Typography gutterBottom style={{ display: 'flex' }}>
          <p style={{fontWeight:'bold'}}>Current Firmware Version :</p>
          <p>{device?.currentFirmwareVersion}</p>
        </Typography>
        <Typography gutterBottom style={{ display: 'flex' }}>
          <p style={{fontWeight:'bold'}}>Created By :</p>
          <p>{ device?.createdBy}</p>
        </Typography>
        <Typography gutterBottom style={{ display: 'flex' }}>
          <p style={{fontWeight:'bold'}}>Created At :</p>
          <p>{convertUTCDateToLocalDate(device?.createdAt)}</p>
        </Typography>
        <Typography gutterBottom style={{ display: 'flex' }}>
          <p style={{fontWeight:'bold'}}>Updated By :</p>
          <p>{device?.updatedBy}</p>
        </Typography>
        <Typography gutterBottom style={{ display: 'flex' }}>
          <p style={{fontWeight:'bold'}}>updated At :</p>
          <p>{convertUTCDateToLocalDate(device?.updatedAt)}</p>
        </Typography>
        <DialogActions style={{ justifyContent: 'center', display: 'flex' }}>
        <Button
          onClick={handleClose}
          variant="contained"
          color="primary"
          style={{ backgroundColor: "#19172C", }}
        >
          Close
        </Button>
        </DialogActions>
      </div>
    </Dialog>
    </Handler>
  );
};

export default DeviceDataModal;
