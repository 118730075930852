import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import React, { useState } from "react";
import { FiEdit } from "react-icons/fi";
import "./ListFirmware.css";
function ListFirmwareFiles({ formData, handleEditRow }) {
  const [files] = useState(formData.files);
  const KEYS = Object.keys(files[0]);

  const handleRowEdit = (index) => {
    handleEditRow(index);
  };
  return (
    <TableContainer>
      <Table>
        <TableHead
          className="table-head"
          sx={{
            maxHeight: 20,
            cursor: "pointer",
          }}
        >
          <TableRow>
            {KEYS.map((i) => (
              <TableCell key={i}>{i.toUpperCase()}</TableCell>
            ))}
            <TableCell key="action">Edit</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {files.map((items, index) => {
            return (
              <TableRow key={Math.random()} className="tableRowShade">
                {KEYS.map((key) => (
                  <TableCell key={Math.random()}>{items[key]}</TableCell>
                ))}
                <TableCell
                  sx={{
                    display: "block",
                  }}
                >
                  <FiEdit
                    style={{
                      marginRight: "10px",
                      fontSize: "110%",
                    }}
                    title={"edit"}
                    onClick={() => handleRowEdit(index)}
                  />
                </TableCell>
              </TableRow>
            );
          })}
        </TableBody>
      </Table>
    </TableContainer>
  );
}

export default ListFirmwareFiles;
