import React, { useEffect, useState } from "react";
import ErrorDialog from "../../modals/errorDialog";
import Loader from "../loader/loader";

function index({ statusCode,errormsg, loading, children }) {
  const [open, setOpen] = useState(false);
  const onHandleClose = () => {
    setOpen(false);
  };

  useEffect(() => {
    if (statusCode !== 200 && statusCode > 299) {
      setOpen(true);
    }
  },[statusCode])

  return (
    <>
      {children}
      {open && (
        <ErrorDialog open={open} handleClose={onHandleClose} statusCode={statusCode} errormsg={errormsg}>
          <p>{statusCode}</p>
        </ErrorDialog>
      )}
      {loading && <Loader />}
    </>
  );
}

export default index;
