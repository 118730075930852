import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';
import { Paper } from '@mui/material';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import React from 'react';
import '../pages/tenantOnboard/tenantData.css';

export default function UserConfigErrorModal({openDialogError,onHandleCloseErrorDialog}) 
{
    const dialogStyle = {
        width: '320px',
        height: '270px',
      };

  return (
    <div>
      <Dialog 
        // sx={{backgroundColor: 'rgba(84, 84, 84, 0.8)'}}
        open={openDialogError}
        close={onHandleCloseErrorDialog}
        aria-labelledby="responsive-dialog-title"
      >
        <Paper style={dialogStyle}>
         <div className="success-icon-container">
          <ErrorOutlineIcon sx={{ color: "red", fontSize:"50px" }} />
        </div>
        <DialogContent style={{ justifyContent: 'center', display: 'flex', flexDirection: 'column' }}>  
        <h6 style={{ fontWeight:'bold',paddingLeft:'40px'}}>User Configuration Missing</h6>
           <p style={{ paddingLeft:'50px'}}>Please Login Again or </p>  
           <p style={{paddingLeft:'50px'}}> Contact Administrator</p>               
        </DialogContent>
        <DialogActions style={{ justifyContent: 'center', display: 'flex' }}>
          <Button
            style={{
              backgroundColor: "#19172C",
            }}
            variant="contained"
            onClick={onHandleCloseErrorDialog}
          >
            OK
          </Button>
        </DialogActions>
        </Paper>
      </Dialog>
    </div>
  );
}