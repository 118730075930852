import "bootstrap/dist/css/bootstrap.min.css";
import React, { useContext, useEffect, useState } from "react";
import { useParams } from "react-router-dom";

import { AuthContext } from "../../App";
import Breadcrumb from "../../breadCrumbs/navigation";
import Handler from "../../components/handler";
import useAxiosApiRequest from "../../hook/useAxiosApiRequest";
import SuccessModal from "../../modals/successModal";
import "./createDeviceModel.css";

const UpdateDeviceModel = () => {
  const { statusCode, loading, apiRequest } = useAxiosApiRequest();
  const { userName } = useContext(AuthContext);

  const tenantID = localStorage.getItem('TenantID');
  const tenant = tenantID ? JSON.parse(tenantID) : null;

  const [deviceTypes, setDeviceTypes] = useState([]);
  const [formData, updateFormData] = useState({
    deviceType: "",
    modelName: "",
    description: "",
  });

  const [openDialog, setOpenDialog] = useState(false); //success dialog
  const [fieldErrors, setFieldErrors] = useState({});
  const [errorMsg, setErrorMsg] = useState(false);
  const [isSuccess, setIsSuccess] = useState(true);
  const [isFormValid, setIsFormValid] = useState(false);
  const [deviceTypeData, setDeviceTypeData] = useState({});
  const [modelNameError, setModelNameError] = useState("");
  const [imageData, setImageData] = useState({});
  
  useEffect(() => {
    loadDeviceModels();
    getDeviceType();
  }, []);

  useEffect(() => {
    setIsFormValid(!modelNameError && isSuccess);
  }, [modelNameError, errorMsg]);

  const { modelNumber } = useParams();

  const {
    deviceType,
    // modelNumber,
    modelName,
    description,
    model_image,
  } = formData;

  const updatedData = {
    deviceType: deviceTypeData,
    modelName: modelName,
    description: description,
    modelImage: imageData,
    modelStatus: "ACTIVE",
    updatedBy: userName,
  };

  const onHandleCloseDialog = () => {
    setOpenDialog(false);
  };

  //updating form data with the data entered by user
  const onHandleChange = (e) => {
    updateFormData({
      ...formData,
      [e.target.id]: e.target.value,
    });
    setFieldErrors({
      ...fieldErrors,
      [e.currentTarget.id]: "",
    });
  };

  const onHandleModelNameChange = (e) => {
    const { value } = e.target;
    const regex = /^[a-zA-Z0-9_]+$/;
    if (regex.test(value)) {
      setModelNameError("");
    } else {
      setModelNameError("Special characters are not allowed for model name");
    }
    updateFormData({
      ...formData,
      [e.target.id]: e.target.value,
    });
    setFieldErrors({
      ...fieldErrors,
      [e.currentTarget.id]: "",
    });
  };

  const onHandleDeviceTypeChange = (e) => {
    updateFormData({
      ...formData,
      [e.target.id]: e.target.value,
    });
    const selectedDeviceType = e.target.value;
    const selectedDeviceTypeDescription = deviceTypes.find(
      (desc) => desc.name === selectedDeviceType
    );
    if (selectedDeviceTypeDescription) {
      const description = selectedDeviceTypeDescription.description;
      const deviceIDType = selectedDeviceTypeDescription?.deviceIdType;
      const deviceData = {
        name: selectedDeviceType,
        description: description,
        deviceIdType:deviceIDType,
      };
      setDeviceTypeData(deviceData);
    }
    setFieldErrors({
      ...fieldErrors,
      [e.currentTarget.id]: "",
    });
  };

  const onHandleFileChange = (e) => {
    updateFormData({
      ...formData,
      [e.target.id]: e.target.value,
    });
    const image = e.target.files[0];
    const fileName = e.target.files[0].name;
    if (image) {
      const selectedFileSize = e.target.files[0].size;
      const reader = new FileReader();
      reader.readAsDataURL(image);
      reader.onloadend = () => {
        const baseImage = reader.result;
        validateSelectedFile(selectedFileSize, fileName, baseImage);
      };
    } else {
      setIsSuccess(true);
    }
    setFieldErrors({
      ...fieldErrors,
      [e.currentTarget.id]: "",
    });
  };

  const validateSelectedFile = (fileSize, fileName, baseImage) => {
    // const MIN_FILE_SIZE = 10 // 1MB
    const MAX_FILE_SIZE = 5 * 1024; //5mb in bytes
    const allowedFormats = ["jpeg", "png", "jpg", "svg"];
    const fileSizeKiloBytes = fileSize / 1024;
    if (fileSizeKiloBytes > MAX_FILE_SIZE) {
      setErrorMsg("File size is greater than maximum limit");
      setIsSuccess(false);
      return;
    }

    const fileExtension = fileName.split(".").pop().toLowerCase();
    if (!allowedFormats.includes(fileExtension)) {
      setErrorMsg(
        "File format is not supported. Upload files of only above mentioned formats."
      );
      setIsSuccess(false);
      return;
    } else {
      setIsSuccess("File size and format is validated");
      setErrorMsg(true);
      const imageData = {
        fileName: fileName,
        fileSize: fileSize.toString(),
        imageFile: baseImage,
        fileExtension: fileExtension,
      };
      setImageData(imageData);
    }
  };

  //mandatory field validation
  const validateForm = () => {
    const newErrors = {};
    if (!formData.modelName.trim()) {
      newErrors.modelName = "Enter Model Name";
    }
    setFieldErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  const getDeviceType = async () => {
    const response = await apiRequest({
      endpoint: `/deviceservice/masterdata/deviceType`,
      method: "GET",
      additionalParams: {
        headers: {
          "tenant-id": tenant
        } 
      },
      body: {},
    });
    const Data = response?.deviceMasterData;
    if (Array.isArray(Data)) {
      setDeviceTypes(Data);
    }
  };

  const loadDeviceModels = async () => {
    const response = await apiRequest({
      endpoint: `/deviceservice/devicemodel/modelNumber/${modelNumber}`,
      method: "GET",
      additionalParams: {
        headers: {
          "tenant-id": tenant
        } 
      },
      body: {},
    });
    const Data = response;
    if (Data) {
      updateFormData(Data);
      setImageData(Data.modelImage);
      setDeviceTypeData(Data.deviceType);
    }
  };

  const AddUpdatedDeviceModels = async () => {
    const response = await apiRequest({
      endpoint: `/deviceservice/devicemodel/modelNumber/${modelNumber}`,
      method: "PUT",
      additionalParams: {
        headers: {
          "tenant-id": tenant
        } 
      },
      body: updatedData,
    });
    if (response && statusCode === 200) {
      setOpenDialog(true);
    }
  };

  const onHandleSubmit = (e) => {
    e.preventDefault();
    if (validateForm()) {
      AddUpdatedDeviceModels();
    }
  };

  return (
    <Handler statusCode={statusCode} loading={loading}>
      <>
        <Breadcrumb />
        {openDialog && (
          <SuccessModal
            openDialog={openDialog}
            onHandleClose={onHandleCloseDialog}
          />
        )}
        <h2>Update Device Model</h2>
        <div className="createvehicle">
          <form className="mt-3 mb-3" onSubmit={(e) => onHandleSubmit(e)}>
            <div className="col-sm-8 mb-2">
              <label>Model Number</label>
              <input
                type="string"
                className="form-control"
                disabled
                id="modelNumber"
                value={modelNumber}
              />
            </div>
            <div className="col-sm-8 mb-2">
              <label>Select Device Type</label>
              <select
                className="form-control"
                id="deviceType"
                value={deviceType}
                onChange={onHandleDeviceTypeChange}
              >
                <option selected disabled value={""}>
                  Select Device Type
                </option>
                {deviceTypes &&
                  deviceTypes.map((getDeviceName) => (
                    <option key={getDeviceName.id}>{getDeviceName.name}</option>
                  ))}
              </select>
            </div>
            <div className="col-sm-8 mb-2">
              <label>Model Name</label>
              <input
                type="string"
                className="form-control"
                id="modelName"
                value={modelName}
                placeholder="Enter Model Name"
                maxLength={100}
                onChange={onHandleModelNameChange}
              />
              {modelNameError && (
                <div className="error-message">{modelNameError}</div>
              )}
              {fieldErrors&&fieldErrors.modelName && (
                <p className="error-message">{fieldErrors.modelName}</p>
              )}
            </div>
            <div className="col-sm-8 mb-2">
              <label>Description</label>
              <textarea
                className="form-control"
                placeholder="Enter Description"
                value={description}
                id="description"
                as="textarea"
                maxLength={1000}
                rows={3}
                onChange={onHandleChange}
              />
            </div>
            <div className="col-sm-8 mb-2">
              <label>Upload Image</label>
              <div className="col-sm-12">
                <input
                  type="file"
                  className="form-control"
                  value={model_image}
                  id="model_image"
                  // accept="application/gzip"
                  onChange={onHandleFileChange}
                />
                <small id="passwordHelpBlock" className="form-text text-muted">
                  Please Upload File of Format JPEG/JPG, PNG, SVG and File Size
                  Below 5MB
                </small>
                {errorMsg && <p className="error-message">{errorMsg}</p>}
                {isSuccess && <p className="success-message">{isSuccess}</p>}
                <br />
              </div>
            </div>
            <div className="form-group row">
              <div className="col-sm-12">
                <button
                  disabled={!isFormValid}
                  type="submit"
                  className="btn btn-dark btn-block"
                >
                  Submit
                </button>
              </div>
            </div>
          </form>
        </div>
      </>
    </Handler>
  );
};

export default UpdateDeviceModel;
