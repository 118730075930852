import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import React, { useEffect, useState } from "react";

import Handler from "../../components/handler";
import useAxiosApiRequest from "../../hook/useAxiosApiRequest";

const DisplayDeviceList = ({devices,url}) => {

  const tenantID = localStorage.getItem("TenantID");
  const tenant = tenantID ? JSON.parse(tenantID) : null;
  const { statusCode, errormsg, loading, apiRequest } = useAxiosApiRequest();
  const [deviceList, setDeviceList] = useState([]);
  const [downloadData, setDownloadData] = useState([]);
  const [downloadLinkDisplay, setDownloadLinkDisplay] = useState(false);

    useEffect(() => {
      if(devices?.totalDocumentCount > 0) {
        downloadDeviceListCsv();
        setDownloadLinkDisplay(true);
      }
      setDeviceList(devices?.devices);
    },[devices]);

    const downloadDeviceListCsv = async () => {
      const response = await apiRequest({
        endpoint: `/deviceservice/exportdevicedata?${url}`,
        method: "GET",
        additionalParams: {
          headers: {
            'Accept': 'text/csv',
            "tenant-id": tenant
          }
        },
        body: {},
      });
      if (response) {
        setDownloadData(response);
      }
    };

  return (
    <div>
      <Handler statusCode={statusCode} loading={loading} errormsg={errormsg} />
      <div className="rowalign">
        <div>
        <div className="columnalign">
            <p>Total Devices : </p>
            <p className="boldText">{devices?.totalDocumentCount}</p>
          </div>
          <div className="columnalign">
            <p>Devices selected for grouping: </p>
            <p className="boldText">{devices?.inactiveGroupDeviceCount}</p>
          </div>
          <div className="columnalign">
            <p>Devices excluded as part of active device group : </p>
            <p className="boldText">{devices?.activeGroupDeviceCount}</p>
          </div>
        </div>
        {downloadLinkDisplay && (
          <a
            href={`data:text/csv;charset=utf-8,${downloadData}`}
            download="deviceLists.csv"
            style={{fontWeight:'bold', color:'#4822B4'}}
          >
            Download Devices List
          </a>
        )}
        {/* <button type="button" className="btn btn-dark" onClick={downloadDeviceListCsv}>Download Devices List</button> */}
      </div>
      {deviceList && deviceList.length ? (
        <Paper
          sx={{ width: "100%", overflow: "hidden", border: "1px solid black" }}
        >
          <TableContainer sx={{ maxHeight: 440 }}>
            <Table stickyHeader aria-label="sticky table">
              <TableHead sx={{ maxHeight: 20, cursor: "pointer" }}>
                <TableRow>
                  <TableCell sx={{ fontWeight: 700 }} align="center">
                    Device ID
                  </TableCell>
                  <TableCell sx={{ fontWeight: 700 }} align="center">
                    Thing Name
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {deviceList &&
                  deviceList.map((deviceGroup, index) => {
                    return (
                      <TableRow
                        style={
                          index % 2
                            ? { backgroundColor: "rgba(72, 34, 180, 0.04)" }
                            : { backgroundColor: "white" }
                        }
                        hover
                        role="checkbox"
                        tabIndex={-1}
                        key={deviceGroup.id}
                      >
                        <TableCell align="center">{deviceGroup.deviceId}</TableCell>
                        <TableCell align="center">{deviceGroup.thingName || '-'}</TableCell>
                      </TableRow>
                    );
                  })}
              </TableBody>
            </Table>
          </TableContainer>
        </Paper>
      ) : (
        <Paper className="nodata">No Devices Available</Paper>
      )}
    </div>
  );
};

export default DisplayDeviceList;
