import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import React from 'react';

export default function BasicModal({children,open,handleClose}) 
{
  return (
    <div>
      <Dialog style={{
            fontSize: "110%",
            fontWeight:"90%",
            borderRadius:"5%",
          }}
        open={open}
        close={handleClose}
        aria-labelledby="responsive-dialog-title"
      >
         <div className="success-icon-container">
          <ErrorOutlineIcon sx={{ color: "red", fontSize:"50px" }} />
        </div>
        <DialogContent style={{ justifyContent: 'center', display: 'flex', flexDirection: 'column' }}>  
           <h6 style={{fontWeight:'bold', paddingLeft:'30px'}}>{children}</h6>
           <p style={{fontWeight:'bold', paddingLeft:'30px'}}>Please Try Again!!</p>      
        </DialogContent>
        <DialogActions style={{ justifyContent: 'center', display: 'flex' }}>
          <Button
            style={{
              backgroundColor: "#19172C",
            }}
            variant="contained"
            onClick={handleClose}
          >
            OK
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}