import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import React, { useEffect, useState } from 'react';

export default function ErrorDialog({open,handleClose,statusCode,errormsg}) 
{

  const [error1, setError1 ] =useState("");
  const [error2,setError2] = useState("");
  const message = {
    "ERR_NETWORK": "Could not reach server",
    400: "Invalid Data Sent To Server",
    401: "Unauthorized",
    402: "Payment Required",
    403: "Forbidden",
    404: "Resource Not Found",
    406: "Not Acceptable",
    408: "Request Timeout",
    429: "Too Many Request",
    500: "Internal Server Error",
  };

  useEffect(() => {
    if (errormsg) {
      const errorMessage = errormsg?.split(':');
      setError1(errorMessage[0]);
      setError2(errorMessage[1]);
    }
  },[errormsg]);


  return (
    <div>
      <Dialog style={{
            fontSize: "110%",
            fontWeight:"90%",
            borderRadius:"5%",
          }}
        open={open}
        close={handleClose}
        aria-labelledby="responsive-dialog-title"
      >
         <div className="success-icon-container">
          <ErrorOutlineIcon sx={{ color: "red", fontSize:"50px" }} />
        </div>
        <DialogContent style={{ justifyContent: 'center',alignItems:'center', display: 'flex', flexDirection: 'column' }}>  
           <h6 style={{fontWeight:'bold', padding:'5px'}}> {errormsg ? error1 : message[statusCode]}</h6>
           <h6 style={{fontWeight:'bold', padding:'5px'}}> {errormsg ? error2 : "Please Try Again!!"}</h6>
           {/* <p style={{fontWeight:'bold', padding:'5px'}}>Please Try Again!!</p>       */}
        </DialogContent>
        <DialogActions style={{ justifyContent: 'center', display: 'flex' }}>
          <Button
            style={{
              backgroundColor: "#19172C",
            }}
            variant="contained"
            onClick={handleClose}
          >
            OK
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}