import React from "react";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import "./ListFirmware.css";
// add validation in addeditfirmware.js
// test this properly:

const RichTextEditor = ({ value, handleEditor }) => {
  const modules = {
    toolbar: [
      [{ header: "1" }, { header: "2" }],
      ["bold", "italic", "underline", "strike", "blockquote"],
      [
        { list: "ordered" },
        { list: "bullet" },
        { indent: "-1" },
        { indent: "+1" },
      ],
    ],
  };

  const formats = [
    "header",
    "bold",
    "italic",
    "underline",
    "strike",
    "blockquote",
    "list",
    "bullet",
    "indent",
  ];
  const handleEditorChange = (input, delta, source, editor) => {
    // console.log(input,a,b,editor.getText());
    handleEditor(input, editor.getText());
  };

  return (
    <ReactQuill
      theme="snow"
      id="editor"
      modules={{ ...modules, clipboard: { matchVisual: false } }}
      formats={formats}
      value={value}
      onChange={handleEditorChange}
    />
  );
};

export default RichTextEditor;
