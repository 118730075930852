import React from "react";
import { useLocation, useNavigate } from "react-router-dom";
import ArrowLeftIcon from "@mui/icons-material/ArrowLeft";
import "./navigation.css";

function Breadcrumb() {
  const location = useLocation();
  // const pathnames = location.pathname.split('/').filter((x) => x);
  const navigate = useNavigate();

  const handleGoBack = () => {
    navigate(-1);
  };

  return (
    <nav aria-label="breadcrumb">
      {location.pathname !== "/" && (
        <div className="navigation">
          <p onClick={handleGoBack}>
            <ArrowLeftIcon /> Back to Previous Page
          </p>
        </div>
      )}
    </nav>
  );
}

export default Breadcrumb;
