import React, { useEffect, useState } from "react";
import { Button } from "@mui/material";
import useAxiosApiRequest from "../../hook/useAxiosApiRequest";

function UploadFirmware({ handleUploadFirmware, onHandleClose, editData }) {
  const [hashAlgo, setHashAlgo] = useState([]);
  const tenantID = localStorage.getItem("TenantID");
  const tenant = tenantID ? JSON.parse(tenantID) : null;
  const { apiRequest } = useAxiosApiRequest();
  const [errors, setError] = useState({
    fileName: "",
    hashAlgo: "",
    digest: "",
    size: "",
    s3URL: "",
  });

  const [formData, updateFormData] = useState({
    fileName: editData !== null ? editData.fileName : "",
    hashAlgo: editData !== null ? editData.hashAlgo : "",
    digest: editData !== null ? editData.digest : "",
    size: editData !== null ? editData.size : "",
    s3URL: editData !== null ? editData.s3URL : "",
  });

  useEffect(() => {
    gethashList();
  }, []);

  const NAMES = {
    fileName: "File Name",
    hashAlgo: "Hash algorithm",
    digest: "Digest",
    size: "Size (MB)",
    s3URL: "S3 path",
  };

  const gethashList = async () => {
    try {
      const response = await apiRequest({
        endpoint: `/firmwareversionservice/getHashAlgo`,
        method: "GET",
        additionalParams: {
          headers: {
            "tenant-id": tenant,
          },
        },
        body: {},
      });
      setHashAlgo(response.hashalgoList[0].description);
    } catch (error) {
      console.error(error);
    }
  };
  const handleSUBMIT = () => {
    let flag = true;
    let err = {};
    for (let key in formData) {
      let value = formData[key];
      if (value === "" || value.length < 1) {
        flag = false;
        err[key] = "This field cannot be empty ";
      }
    }
    setError({ ...errors, ...err });
    if (flag) {
      handleUploadFirmware(formData);
      onHandleClose();
    }
  };
  const handleOnChange = (event) => {
    const { id, value } = event.target;

    if (value === "" || value.length < 1) {
      setError({ ...errors, [id]: "This field cannot be empty" });
    } else {
      setError({ ...errors, [id]: "" });
    }
    if (event.target.id === "size") {
      updateFormData({
        ...formData,
        [event.target.id]: Number(event.target.value),
      });
    }
    updateFormData({
      ...formData,
      [event.target.id]: event.target.value,
    });
  };
  return (
    <div className="uploadDialog">
      <label id="mandatorylabels">Enter {NAMES.fileName}</label>
      <input
        type="string"
        className="form-control"
        placeholder={`Enter ${NAMES.fileName}`}
        id="fileName"
        value={formData.fileName}
        onChange={handleOnChange}
      />
      {errors.fileName && <p className="error-message">{errors.fileName}</p>}
      <label id="mandatorylabels">{NAMES.hashAlgo}</label>
      <select
        type="string"
        className="form-control"
        id="hashAlgo"
        value={formData.hashAlgo}
        onChange={handleOnChange}
      >
        <option value={""}>Select {NAMES.hashAlgo}</option>
        {hashAlgo.map((item, index) => {
          return (
            <option key={index} value={item}>
              {item}
            </option>
          );
        })}
      </select>
      {errors.hashAlgo && <p className="error-message">{errors.hashAlgo}</p>}
      <label id="mandatorylabels">{NAMES.digest}</label>
      <input
        type="string"
        className="form-control"
        id="digest"
        value={formData.digest}
        placeholder={`Enter ${NAMES.digest}`}
        onChange={handleOnChange}
      />
      {errors.digest && <p className="error-message">{errors.digest}</p>}
      <label id="mandatorylabels">{NAMES.size}</label>
      <input
        type="number"
        placeholder={`Enter ${NAMES.size}`}
        className="form-control"
        id="size"
        value={formData.size}
        onChange={handleOnChange}
      />
      {errors.size && <p className="error-message">{errors.size}</p>}
      <label id="mandatorylabels">{NAMES.s3URL}</label>
      <input
        id="s3URL"
        type="url"
        value={formData.s3URL}
        placeholder={`Enter ${NAMES.s3URL}`}
        className="form-control"
        onChange={handleOnChange}
      />
      {errors.s3URL && <p className="error-message">{errors.s3URL}</p>}
      <Button
        style={{
          width: "100%",
          margin: "16px 0 0 0",
        }}
        variant="contained"
        onClick={handleSUBMIT}
      >
        OK
      </Button>
    </div>
  );
}

export default UploadFirmware;
