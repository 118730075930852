import WarningAmberIcon from '@mui/icons-material/WarningAmber';
import { Dialog, DialogActions, DialogContent, Typography } from '@mui/material';
import Button from '@mui/material/Button';
import React from 'react';
import '../pages/tenantOnboard/tenantData.css';

const ConfirmationModal = (props) => 
{
  const { confirmDialog, setConfirmDialog } = props; 
  return (
    <div>
      <Dialog open={confirmDialog.isOpen}>
      <div className="success-icon-container">
          <WarningAmberIcon sx={{ color: "rgba(148, 6, 6, 0.984)", fontSize:"40px" }} />
        </div>
        <DialogContent sx={{display:'flex', flexDirection:'column', alignItems:'center',justifyContent:'center'}}>
          <Typography variant="h6">{confirmDialog.title}</Typography>
          <Typography style={{fontWeight:'bold'}}>{confirmDialog.subtitle1}</Typography>
          {/* <DialogContent>{confirmDialog.subtitle2}</DialogContent> */}
        </DialogContent>
        <DialogActions style={{ justifyContent: 'center', display: 'flex' }}>
          <Button
            style={{
              backgroundColor: "black",
            }}
            variant="contained"
            onClick={() =>
              setConfirmDialog({ ...confirmDialog, isOpen: false })
            }
          >
            No
          </Button>
          <Button
            style={{
              backgroundColor: "rgba(148, 6, 6, 0.984)",
            }}
            variant="contained"
            onClick={confirmDialog.onConfirm}
          >
            Yes
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default ConfirmationModal;
