import "bootstrap/dist/css/bootstrap.min.css";
import log from "loglevel";
import React, { useContext, useEffect, useState } from "react";
import { AuthContext } from "../../App";
import Handler from "../../components/handler";
import useAxiosApiRequest from "../../hook/useAxiosApiRequest";
import BasicModal from "../../modals/basicModal";
import ErrorModal from "../../modals/errorModal";
import SuccessCreateModal from "../../modals/successModalCreate";
import UserConfigErrorModal from "../../modals/userConfigError";
import "../deviceModel/createDeviceModel.css";
import { ADMIN_PERMISSIONS } from "../../constants/constants";

var apigClientFactory = require("aws-api-gateway-client").default;

export default function User() {
  const { statusCode, errormsg, loading, apiRequest } = useAxiosApiRequest();
  const { cred } = useContext(AuthContext);
  const { tokens } = useContext(AuthContext);
  const awsKey = cred.accessKeyId;
  const awsSecret = cred.secretAccessKey;
  const sessionToken = cred.sessionToken;
  const awsRegion = tokens.region;
  const CognitoRole = tokens.userrole;
  const CURRENT_USER_TYPE = CognitoRole;
  const superAdminRole = "superAdminRole";
  const adminrole = "adminrole";

  const config = {
    invokeUrl: process.env.REACT_APP_SERVICE_URL_v2,
    region: awsRegion,
    accessKey: awsKey,
    secretKey: awsSecret,
    sessionToken: sessionToken,
  };
  const [selectedPermissions, setSelectedPermissions] = useState([]);
  const apigClient = apigClientFactory.newClient(config);
  const userRole = process.env.REACT_APP_USER_ROLE;
  const [userNameError, setUserNameError] = useState("");
  const [emailError, setEmailError] = useState("");
  const [tenantData, setTenantData] = useState([]);
  const [selectedTenantID, setSelectedTenantID] = useState("");
  const [userPoolId, setUserPoolId] = useState([]);
  const [formData, updateFormData] = useState({
    userName: "",
    emailId: "",
    temporaryPassword: "",
    userPoolId: "",
    user_role: "Admin",
  });

  const { userName, emailId, temporaryPassword, tenant_name, user_role } =
    formData;

  const onHandleChange = (e) => {
    updateFormData({
      ...formData,
      [e.target.id]: e.target.value,
    });
  };

  const onHandleUserNameChange = (e) => {
    updateFormData({
      ...formData,
      [e.target.id]: e.target.value,
    });
    const { value } = e.target;
    const regex = /^[a-zA-Z0-9_]+$/;
    if (regex.test(value)) {
      setUserNameError("");
    } else {
      setUserNameError("Special characters are not allowed for user name");
    }
  };

  const handleCheckboxChange = (event) => {
    const { value } = event.target;
    let tempArr = [...selectedPermissions];
    const valueIndex = tempArr.indexOf(value);
    if (valueIndex > -1) {
      tempArr.splice(valueIndex, 1);
    } else {
      tempArr.push(value);
    }
    setSelectedPermissions(tempArr);
  };
  const handleEmailChange = (e) => {
    updateFormData({
      ...formData,
      [e.currentTarget.id]: e.currentTarget.value,
    });
    const emailValue = e.currentTarget.value;
    if (emailValue && !validateEmail(emailValue)) {
      setEmailError("Invalid email format,Please enter valid Email");
    } else {
      setEmailError("");
    }
  };

  const validateEmail = (emailValue) => {
    // eslint-disable-next-line no-useless-escape
    const emailRegex = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;
    const consecutiveDotsRegex = /\.{2,}/;
    return (
      emailRegex.test(emailValue) && !consecutiveDotsRegex.test(emailValue)
    );
  };

  const handleTenantName = (e) => {
    updateFormData({
      ...formData,
      [e.target.id]: e.target.value,
    });
    const selectedModelName = e.target.value;
    const selectedModel = tenantData.find(
      (model) => model.tenantName === selectedModelName,
    );
    if (selectedModel) {
      setSelectedTenantID(selectedModel.tenantID);
    }
  };

  const [open, setOpen] = useState(false); //state to handle dialog box to display responses
  const children = " "; //state to handle responses
  const [openDialog, setOpenDialog] = useState(false); //success dialog
  const [openErrorDialog, setOpenErrorDialog] = useState(false); //Error dialog
  const [userConfigErrorDialog, setUserConfigErrorDialog] = useState(false);

  const onHandleCloseUserConfigErrorDialog = () => {
    setUserConfigErrorDialog(false);
  };

  const onHandleClose = () => {
    setOpen(false); //closes the dialog box
  };

  const onHandleCloseDialog = () => {
    setOpenDialog(false);
  };

  const onHandleCloseErrorDialog = () => {
    setOpenErrorDialog(false);
  };

  const getUserPoolConfig = () => {
    try {
      const storedTenantId = localStorage.getItem("TenantID");
      const tenantId = storedTenantId ? JSON.parse(storedTenantId) : null;
      if (tenantId) {
        setSelectedTenantID(tenantId);
      } else {
        throw new Error("Tenant ID not found in local storage");
      }
      const storedAmplifyConfig = localStorage.getItem("amplifyConfig");
      const amplifyConfig = storedAmplifyConfig
        ? JSON.parse(storedAmplifyConfig)
        : null;
      const UserPoolIDConfig = amplifyConfig.aws_user_pools_id;
      if (UserPoolIDConfig) {
        setUserPoolId(UserPoolIDConfig);
      } else {
        throw new Error("Userpool ID not found in local storage");
      }
    } catch (error) {
      console.error(error);
      setUserConfigErrorDialog(true);
    }
  };

  useEffect(() => {
    GetTenantList();
    if (CURRENT_USER_TYPE.toLowerCase() === adminrole.toLowerCase()) {
      getUserPoolConfig();
    }
  }, []);

  useEffect(() => {
    if (CURRENT_USER_TYPE.toLowerCase() === adminrole.toLowerCase()) {
      if (selectedTenantID) {
        getPasswordPolicy();
      } else {
        log.error("Error fetching tenant id");
      }
    }
  }, [selectedTenantID]);

  useEffect(() => {
    if (
      CURRENT_USER_TYPE.toLowerCase() === superAdminRole.toLowerCase() &&
      selectedTenantID
    ) {
      GetTenantDataMongo();
      getPasswordPolicy();
    }
  }, [selectedTenantID, CURRENT_USER_TYPE]);

  const userData = {
    userName: userName,
    email: emailId,
    temporaryPassword: temporaryPassword,
    userPoolId: userPoolId,
    permissions: selectedPermissions,
  };

  const [passwordPolicy, setPasswordPolicy] = useState([]);
  const [isLengthValid, setIsLengthValid] = useState(false);
  const [isUppercaseValid, setIsUppercaseValid] = useState(false);
  const [isNumbersValid, setIsNumbersValid] = useState(false);
  const [isLowercaseValid, setIsLowercaseValid] = useState(false);
  const [isSpecialCharValid, setIsSpecialCharValid] = useState(false);

  const getPasswordPolicy = async () => {
    var pathParams = {};
    var pathTemplate = "/tenantservice/tenant";
    var method = "GET";
    var additionalParams = {
      headers: {
        "Content-Type": "application/json",
        "tenant-id": selectedTenantID,
      },
    };
    var body = {};
    try {
      await apigClient
        .invokeApi(pathParams, pathTemplate, method, additionalParams, body)
        .then((resp) => {
          // const themeData = resp.data[0].
          const Data = resp.data[0];
          setPasswordPolicy(Data);
        });
    } catch (err) {
      log.error("Error retrieving password policy", err);
    }
  };

  const handlePasswordChange = (e) => {
    updateFormData({
      ...formData,
      [e.target.id]: e.target.value,
    });
    const password = e.target.value;
    setIsLengthValid(password.length >= passwordPolicy.tenantPasswordLength);
    setIsUppercaseValid(password.match(/[A-Z]/) !== null);
    setIsLowercaseValid(password.match(/[a-z]/) !== null);
    setIsNumbersValid(password.match(/[0-9]/) !== null);
    setIsSpecialCharValid(password.match(/[!@#$%^&*]/) !== null);
  };

  const isPasswordValidForUser = (password) => {
    const {
      tenantPasswordLength,
      tenantPasswordRequireUppercase,
      tenantPasswordRequireLowercase,
      tenantPasswordRequireNumber,
      tenantPasswordRequireSpecial,
    } = passwordPolicy;
    const isLengthValid = password.length >= tenantPasswordLength;
    const isUppercaseValid = tenantPasswordRequireUppercase
      ? password.match(/[A-Z]/) !== null
      : true;
    const isLowercaseValid = tenantPasswordRequireLowercase
      ? password.match(/[a-z]/) !== null
      : true;
    const isNumbersValid = tenantPasswordRequireNumber
      ? password.match(/[0-9]/) !== null
      : true;
    const isSpecialCharValid = tenantPasswordRequireSpecial
      ? password.match(/[!@#$%^&*]/) !== null
      : true;

    return (
      isLengthValid &&
      isUppercaseValid &&
      isLowercaseValid &&
      isNumbersValid &&
      isSpecialCharValid
    );
  };

  const isPasswordValid = isPasswordValidForUser(temporaryPassword);

  const GetTenantList = async () => {
    var pathTemplate1 = "/tenantservice/tenantrequest";
    var pathTemplate2 = "/tenantservice/tenant";
    var method = "GET";
    var pathParams = {
      //This is where path request params go.
      //userId: '1234',
    };
    var additionalParams = {
      headers: {
        "Content-Type": "application/json",
        "tenant-id": userRole,
      },
    };

    var body = {
      //This is where you define the body of the request
    };

    try {
      await apigClient
        .invokeApi(pathParams, pathTemplate1, method, additionalParams, body)
        .then(async (resp) => {
          const Res = resp.data;
          await apigClient
            .invokeApi(
              pathParams,
              pathTemplate2,
              method,
              additionalParams,
              body,
            )
            .then((resp2) => {
              // console.log(resp2);
              const Res2 = resp2.data;
              // console.log(Res2);
              const combinedData = Res.map((request) => ({
                status: request.status,
                tenantName: Res2.find(
                  (tenant) =>
                    tenant.tenantStagingId === request.tenantStagingId,
                ).tenantName,
                tenantID: Res2.find(
                  (tenant) =>
                    tenant.tenantStagingId === request.tenantStagingId,
                ).tenantId,
              }));
              const filteredData = combinedData.filter(
                (item) => item.status === "S",
              );
              setTenantData(filteredData);
            })
            .catch((error) => {
              log.error("Error fetching tenant Data", error);
            });
        })
        .catch((error) => {
          log.error("Error fetching tenant Data", error);
        });
    } catch (error) {
      log.error("Error fetching tenant Data", error);
    }
  };

  const GetTenantDataMongo = async () => {
    var pathParams = {};
    var pathTemplate = "/mongoservice/";
    var method = "GET";
    var additionalParams = {
      headers: {
        "tenant-id": selectedTenantID,
      },
    };
    var body = {};

    try {
      await apigClient
        .invokeApi(pathParams, pathTemplate, method, additionalParams, body)
        .then((resp) => {
          const userpoolid = resp.data[0].userPoolId;
          setUserPoolId(userpoolid);
        });
    } catch (err) {
      log.error("Error getting data from mongodb", err);
    }
  };

  const createUser = async () => {
    const response = await apiRequest({
      endpoint: `/cognitoservice/createuser/${user_role}`,
      method: "POST",
      additionalParams: {
        headers: {
          "tenant-id": selectedTenantID,
        },
      },
      body: userData,
    });
    if (response) {
      setOpenDialog(true);
    }
  };

  const [isFormValid, setIsFormValid] = useState(false);

  useEffect(() => {
    setIsFormValid(!userNameError && !emailError && isPasswordValid);
  }, [emailError, userNameError, isPasswordValid]);

  const onHandleSubmit = (e) => {
    e.preventDefault();
    createUser();
  };

  return (
    <div>
      <Handler statusCode={statusCode} loading={loading} errormsg={errormsg} />
      {open && (
        <BasicModal open={open} handleClose={onHandleClose}>
          <p>{children}</p>
        </BasicModal>
      )}
      {openDialog && (
        <SuccessCreateModal
          openDialog={openDialog}
          onHandleClose={onHandleCloseDialog}
        />
      )}
      {openErrorDialog && (
        <ErrorModal
          openDialogError={openErrorDialog}
          onHandleCloseErrorDialog={onHandleCloseErrorDialog}
        />
      )}
      {userConfigErrorDialog && (
        <UserConfigErrorModal
          openDialogError={userConfigErrorDialog}
          onHandleCloseErrorDialog={onHandleCloseUserConfigErrorDialog}
        />
      )}
      <h4>Add New User</h4>
      <br></br>
      <div className="createvehicle">
        <form className="mt-3 mb-3" onSubmit={(e) => onHandleSubmit(e)}>
          <div className="col-sm-8 mb-2">
            <label id="mandatorylabels">User Name</label>
            <input
              type="string"
              className="form-control"
              id="userName"
              value={userName}
              placeholder="Enter User Name"
              maxLength={50}
              required
              onChange={onHandleUserNameChange}
            />
            {userNameError && (
              <div className="text-danger">{userNameError}</div>
            )}
          </div>
          <div className="col-sm-8 mb-2">
            <label id="mandatorylabels">Email Id</label>
            <input
              type="email"
              className="form-control"
              value={emailId}
              id="emailId"
              placeholder="Enter User Email Id"
              required
              onChange={handleEmailChange}
            />
            {emailError && <div className="text-danger">{emailError}</div>}
          </div>
          <small id="passwordHelpBlock" className="form-text text-muted">
            Login Credentials Will Be Sent To The Above Given emailId
          </small>
          {CognitoRole &&
            CURRENT_USER_TYPE.localeCompare(superAdminRole, undefined, {
              sensitivity: "base",
            }) === 0 && (
              <div className="col-sm-8 mb-2">
                <label id="mandatorylabels">Select Tenant</label>
                <select
                  className="form-control"
                  id="tenant_name"
                  value={tenant_name}
                  required
                  onChange={handleTenantName}
                >
                  <option>Select Tenant Name</option>
                  {tenantData.map((getTenantName) => (
                    <option key={getTenantName.id}>
                      {getTenantName.tenantName}
                    </option>
                  ))}
                </select>
              </div>
            )}
          <div className="col-sm-8 mb-2">
            <label id="mandatorylabels">Temporary Password</label>
            <input
              type="string"
              className="form-control"
              value={temporaryPassword}
              id="temporaryPassword"
              placeholder="Enter Password"
              maxLength={50}
              required
              onChange={handlePasswordChange}
            />
          </div>
          <ul>
            {passwordPolicy && passwordPolicy.tenantPasswordRequireUppercase ? (
              <li style={{ color: isUppercaseValid ? "green" : "red" }}>
                Password Should Contain Uppercase letters
              </li>
            ) : null}
            {passwordPolicy && passwordPolicy.tenantPasswordRequireLowercase ? (
              <li style={{ color: isLowercaseValid ? "green" : "red" }}>
                Password Should Contain Lowercase letters
              </li>
            ) : null}
            {passwordPolicy.tenantPasswordRequireNumber ? (
              <li style={{ color: isNumbersValid ? "green" : "red" }}>
                Password Should Contain Numbers
              </li>
            ) : null}
            {passwordPolicy && passwordPolicy.tenantPasswordRequireSpecial ? (
              <li style={{ color: isSpecialCharValid ? "green" : "red" }}>
                Password Should Contain Special characters
              </li>
            ) : null}
            {passwordPolicy && passwordPolicy.tenantPasswordLength ? (
              <li style={{ color: isLengthValid ? "green" : "red" }}>
                Minimum length: {passwordPolicy.tenantPasswordLength}
              </li>
            ) : null}
          </ul>
          <div className="col-sm-8 mb-2">
            <label className="col-form-label" id="mandatorylabels">
              Select User Role
            </label>
            <div className="col-sm-8">
              <div className="form-check form-check-inline">
                <input
                  className="form-check-input"
                  type="radio"
                  name="inlineRadioOptions2"
                  id="user_role"
                  value="Admin"
                  defaultChecked
                  onChange={onHandleChange}
                />
                <label className="form-check-label" htmlFor="inlineRadio1">
                  Admin
                </label>
              </div>
              <div className="form-check form-check-inline">
                <input
                  className="form-check-input"
                  type="radio"
                  name="inlineRadioOptions2"
                  id="user_role"
                  value="Analyst"
                  onChange={onHandleChange}
                />
                <label className="form-check-label" htmlFor="inlineRadio2">
                  Analyst
                </label>
              </div>
            </div>
          </div>
          <br />
          {formData.user_role === "Admin" && (
            <div className="col-sm-8 mb-2">
              <label className="col-form-label">Select User Type</label>

              <div className="col-sm-8">
                {ADMIN_PERMISSIONS.map((item, index) => (
                  <div key={index} className="form-check form-check-inline">
                    <input
                      className="form-check-input"
                      type="checkbox"
                      name="permissions"
                      id={`permission-${index}`}
                      value={item.toLowerCase()}
                      checked={
                        !!selectedPermissions.includes(item.toLowerCase())
                      }
                      onChange={handleCheckboxChange}
                    />
                    <label
                      className="form-check-label"
                      htmlFor={`permission-${index}`}
                    >
                      {item}
                    </label>
                  </div>
                ))}
              </div>
              <small id="permissionsBlock" className="form-text text-muted">
                By default the user will be a non-approver
              </small>
            </div>
          )}
          <br />
          <div className="form-group row">
            <div className="col-sm-12">
              <button
                disabled={!isFormValid}
                type="submit"
                className="btn btn-dark btn-block"
              >
                Submit
              </button>
            </div>
          </div>
        </form>
      </div>
    </div>
  );
}
