import Paper from "@mui/material/Paper";
import React, { useContext, useEffect, useState } from "react";
import { CSVLink } from "react-csv";
import { useLocation, useParams } from "react-router-dom";
import { AuthContext } from "../../App";
import Breadcrumb from "../../breadCrumbs/navigation";
import Handler from "../../components/handler";
import useAxiosApiRequest from "../../hook/useAxiosApiRequest";
import SuccessModal from "../../modals/successModal";
import "./deviceGroup.css";
import DisplayDeviceList from "./displayDeviceList";
import { Button, Grid } from "@mui/material";

const UpdateDeviceGroup = () => {
  const { statusCode, errormsg, loading, apiRequest } = useAxiosApiRequest();
  const { userName } = useContext(AuthContext);
  const location = useLocation();
  const { devices } = location.state;
  const { groupName } = useParams();
  const tenantID = localStorage.getItem("TenantID");
  const tenant = tenantID ? JSON.parse(tenantID) : null;

  const [firmwareVersionList, setFirmwareVersionList] = useState([]);
  const [selectedFirmwareVersion, setSelectedFirmwareVersion] = useState("");
  const [yearList, setYearList] = useState([]);
  const [selectedYear, setSelectedYear] = useState("");
  const [showSearchData, setShowSearchData] = useState(false);
  const [searchData, setSearchData] = useState([]);
  const [openDialog, setOpenDialog] = useState(false);
  const [isSearchEnabled, setIsSearchEnabled] = useState(false);
  const [EnableSubmit, setEnableSubmit] = useState(false);
  const [url, setURL] = useState(null);
  const [formData, updateFormData] = useState({
    firmwareVersion: "",
    deviceIDStart: "",
    deviceIDEnd: "",
  });

  const { firmwareVersion, deviceIDStart, deviceIDEnd } = formData || {};

  useEffect(() => {
    getSearchFields();
  }, []);
  useEffect(() => {
    const getURL = async () => {
      const url = buildUrl();
      setURL(url);
    };

    if (
      selectedFirmwareVersion ||
      selectedYear ||
      deviceIDStart ||
      deviceIDEnd
    ) {
      getURL();
      setIsSearchEnabled(true);
    } else {
      setIsSearchEnabled(false);
    }
  }, [selectedFirmwareVersion, selectedYear, deviceIDStart, deviceIDEnd]);

  const getYears = (dateStrings) => {
    return dateStrings.map((dateString) => new Date(dateString).getFullYear());
  };

  const buildUrl = () => {
    let baseUrl = ``;
    let params = [];
    if (selectedFirmwareVersion) {
      params.push(`currentFirmwareVersion=${selectedFirmwareVersion}`);
    }
    if (selectedYear) {
      params.push(`year=${selectedYear}`);
    }
    if (deviceIDEnd) {
      params.push(`endSearchString=${deviceIDEnd}`);
    }
    if (deviceIDStart) {
      params.push(`startSearchString=${deviceIDStart}`);
    }
    if (params.length > 0) {
      baseUrl += `${params.join("&")}`;
    }
    return baseUrl;
  };

  const handleChange = (e) => {
    setSelectedYear(e.target.value);
    updateFormData({
      ...formData,
      [e.target.id]: e.target.value,
    });
  };

  const onHandleCloseDialog = () => {
    setOpenDialog(false);
  };

  const handleChangeFirmwareversion = (e) => {
    setSelectedFirmwareVersion(e.target.value);
    updateFormData({
      ...formData,
      [e.target.id]: e.target.value,
    });
  };

  const onHandleModelNumberChange = (e) => {
    updateFormData({
      ...formData,
      [e.target.id]: e.target.value,
    });
  };

  const getDeviceListSearch = async () => {
    const response = await apiRequest({
      endpoint: `/deviceservice/devicelist/10/1/null/desc?deviceType.name=${devices.type}&deviceModel=${devices.model}&${url}`,
      method: "GET",
      additionalParams: {
        headers: {
          "tenant-id": tenant,
        },
      },
      body: {},
    });
    const Data = response;
    if (response) {
      setSearchData(Data);
      setShowSearchData(true);
    }
    if (Data.inactiveGroupDeviceCount > 0) {
      setEnableSubmit(true);
    }
  };

  const getSearchFields = async () => {
    const response = await apiRequest({
      endpoint: `/deviceservice/deviceattributedetails?attributes=currentFirmwareVersion%2Cyear`,
      method: "GET",
      additionalParams: {
        headers: {
          "tenant-id": tenant,
        },
      },
      body: {},
    });
    const yearList = response?.year;
    const firmwareVersionList = response?.currentFirmwareVersion;
    if (Array.isArray(firmwareVersionList)) {
      const years = getYears(yearList);
      setYearList(years);
      setFirmwareVersionList(firmwareVersionList);
    }
  };

  const updatepayload = {
    name: groupName,
    deviceType: devices.type,
    deviceModelName: devices.model,
    updatedBy: userName,
  };

  const updateDeviceGroup = async () => {
    if (selectedYear !== "") {
      updatepayload.year = selectedYear;
    }
    if (selectedFirmwareVersion !== "") {
      updatepayload.firmwareVersion = selectedFirmwareVersion;
    }
    if (deviceIDStart !== "") {
      updatepayload.deviceIdStartsWith = deviceIDStart;
    }
    if (deviceIDEnd !== "") {
      updatepayload.deviceIdEndsWith = deviceIDEnd;
    }
    const response = await apiRequest({
      endpoint: `/devicegroupservice/devicegroup`,
      method: "PUT",
      additionalParams: {
        headers: {
          "tenant-id": tenant,
        },
      },
      body: updatepayload,
    });
    if (response && statusCode === 200) {
      setOpenDialog(true);
    }
  };

  return (
    <>
      <Handler
        statusCode={statusCode}
        loading={loading}
        errormsg={errormsg}
      ></Handler>
      <div>
        {openDialog && (
          <SuccessModal
            openDialog={openDialog}
            onHandleClose={onHandleCloseDialog}
          />
        )}
        <Breadcrumb />
        <Paper className="updatedata">
          <h4 className="deviceHeading">Update Device Group</h4>
          {devices.devices?.length ? (
            <>
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <Grid container alignItems="center">
                    <Grid item xs>
                      <strong>Group Name:</strong>
                      {devices.name}
                    </Grid>
                    <Grid item xs>
                      <strong>Number of devices available: </strong>
                      {devices.devices?.length}
                    </Grid>
                  </Grid>
                </Grid>

                <Grid item xs={12}>
                  <CSVLink
                    data={devices.devices}
                    style={{ textDecoration: "none" }}
                  >
                    <Button sx={{ border: "1px solid", color: "#1442cc" }}>
                      Export Available Device List
                    </Button>
                  </CSVLink>
                </Grid>
              </Grid>
            </>
          ) : (
            <label className="emptyData">No Devices Available</label>
          )}
        </Paper>
        {/* <br /> */}
        <div className="filters">
          <label style={{ fontWeight: "bold", marginBottom: "16px" }}>
            Search Devices
          </label>
          <div className="form-group row">
            <div className="form-group col-md-6 mb-2">
              <label id="mandatorylabels">Firmware Version</label>
              <select
                className="form-control"
                id="firmwareVersion"
                value={firmwareVersion}
                onChange={handleChangeFirmwareversion}
              >
                <option selected disabled value="">
                  Select Firmware Version
                </option>
                {firmwareVersionList &&
                  firmwareVersionList.map((value, index) => (
                    <option key={index} value={value}>
                      {value}
                    </option>
                  ))}
              </select>
            </div>
            <div className="form-group col-md-6 mb-2">
              <label id="mandatorylabels">Date</label>
              <select
                className="form-control"
                id="year"
                value={selectedYear}
                onChange={handleChange}
              >
                <option selected disabled value="">
                  Select Year
                </option>
                {yearList &&
                  yearList.map((value, index) => (
                    <option key={index} value={value}>
                      {value}
                    </option>
                  ))}
              </select>
            </div>
          </div>
          <div className="form-group row">
            <label style={{ fontWeight: "bold", marginBottom: "8px" }}>
              Device ID
            </label>
            <div className="form-group col-md-6 mb-2">
              <label id="mandatorylabels">Starts With</label>
              <input
                type="string"
                className="form-control"
                value={deviceIDStart}
                id="deviceIDStart"
                placeholder="Enter starting of Device ID"
                maxLength={100}
                onChange={onHandleModelNumberChange}
              />
            </div>
            <div className="form-group col-md-6 mb-2">
              <label id="mandatorylabels">Ends With</label>
              <input
                type="string"
                className="form-control"
                value={deviceIDEnd}
                id="deviceIDEnd"
                placeholder="Enter Ending of Device Id"
                maxLength={100}
                onChange={onHandleModelNumberChange}
              />
            </div>
          </div>
          <div className="form-check form-check-inline">
            <input
              className="form-check-input"
              type="checkbox"
              id="activegroup_check"
              // value={true}
              checked={true}
              disabled
              // onChange={handleCheckboxChange}
            />
            <label className="form-check-label">
              Devices which are already part of active device group will not be
              included
            </label>
          </div>
          {showSearchData && (
            <DisplayDeviceList devices={searchData} url={url} />
          )}
          <br />
          <div className="col-sm-12">
            <button
              type="button"
              className="btn btn-dark btn-block"
              disabled={!isSearchEnabled}
              onClick={getDeviceListSearch}
              style={{ marginLeft: "1%", marginTop: "8px" }}
            >
              Search
            </button>
          </div>
        </div>
        <div className="form-group row">
          <div className="col-sm-12">
            <button
              disabled={!EnableSubmit}
              type="button"
              onClick={updateDeviceGroup}
              style={{ marginLeft: "1%", marginTop: "8px" }}
              className="btn btn-dark btn-block"
            >
              Update
            </button>
          </div>
        </div>
      </div>
    </>
  );
};

export default UpdateDeviceGroup;
