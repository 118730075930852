import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import React, { useEffect, useState } from "react";

export default function ViewDeviceData({ open, handleClose, deviceData }) {
  const [user, setuser] = useState([]);

  useEffect(() => {
    if (deviceData) {
      setuser(deviceData);
    }
  },[deviceData]);
  
  return (
    <div>
      <Dialog
        style={{
          borderRadius: "5%",
        }}
        maxWidth='xl'
        open={open}
        close={handleClose}
        aria-labelledby="responsive-dialog-title"
      >
        <DialogTitle>
            <div style={{display:'flex', alignItems:'center', justifyContent:'space-between', color:'#db3d5a'}}>
                <p>Device Data</p>
                <p>Total Devices : {user?.length}</p>
            </div>
        </DialogTitle>
        <DialogContent>
          <Paper>
            <TableContainer>
              <Table stickyHeader aria-label="sticky table">
                <TableHead>
                  <TableRow>
                    <TableCell
                      sx={{
                        fontWeight: 700,
                        whiteSpace: "nowrap",
                        overflow: "hidden",
                        textOverflow: "ellipsis",
                      }}
                    >
                      DeviceType
                    </TableCell>
                    <TableCell
                      sx={{
                        fontWeight: 700,
                        whiteSpace: "nowrap",
                        overflow: "hidden",
                        textOverflow: "ellipsis",
                      }}
                    >
                      Model Name
                    </TableCell>
                    <TableCell
                      sx={{
                        fontWeight: 700,
                        whiteSpace: "nowrap",
                        overflow: "hidden",
                        textOverflow: "ellipsis",
                      }}
                    >
                      Model Number
                    </TableCell>
                    <TableCell
                      sx={{
                        fontWeight: 700,
                        whiteSpace: "nowrap",
                        overflow: "hidden",
                        textOverflow: "ellipsis",
                      }}
                    >
                      Device Id
                    </TableCell>
                    <TableCell
                      sx={{
                        fontWeight: 700,
                        whiteSpace: "nowrap",
                        overflow: "hidden",
                        textOverflow: "ellipsis",
                      }}
                    >
                      Firmware Version
                    </TableCell>
                    <TableCell
                      sx={{
                        fontWeight: 700,
                        whiteSpace: "nowrap",
                        overflow: "hidden",
                        textOverflow: "ellipsis",
                      }}
                    >
                      Status
                    </TableCell>
                    <TableCell
                      sx={{
                        fontWeight: 700,
                        whiteSpace: "nowrap",
                        overflow: "hidden",
                        textOverflow: "ellipsis",
                      }}
                    >
                      Last Seen
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {user &&
                    user.map((userList, index) => {
                      return (
                        <TableRow
                          style={
                            index % 2
                              ? { backgroundColor: "rgba(72, 34, 180, 0.04)" }
                              : { backgroundColor: "white" }
                          }
                          hover
                          role="checkbox"
                          tabIndex={-1}
                          key={userList.id}
                        >
                          <TableCell align="center">
                            {userList.deviceId?.deviceType?.name || "-"}
                          </TableCell>
                          <TableCell align="center">
                            {userList.deviceId?.deviceModel[0]?.modelName ||"-"}
                          </TableCell>
                          <TableCell align="center">
                            {userList.deviceId?.deviceModel[0]?.modelNumber ||"-"}
                          </TableCell>
                          <TableCell align="center">
                            {userList.deviceId?.deviceId ||"-"}
                          </TableCell>
                          <TableCell align="center">
                            {userList.deviceId?.currentFirmwareVersion ||"-"}
                          </TableCell>
                          <TableCell align="center">
                            {userList.status ||"-"}
                          </TableCell>
                          <TableCell align="center">
                            {userList.lastSeen ||"-"}
                          </TableCell>
                        </TableRow>
                      );
                    })}
                </TableBody>
              </Table>
            </TableContainer>
          </Paper>
        </DialogContent>
        <DialogActions style={{ justifyContent: "center", display: "flex" }}>
          <Button
            style={{
              backgroundColor: "#19172C",
            }}
            variant="contained"
            onClick={handleClose}
          >
            Close
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}
