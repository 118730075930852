import React, { useState, useEffect, useContext } from "react";
import { useParams, useNavigate } from "react-router-dom";
import "bootstrap/dist/css/bootstrap.min.css";
import "./onboard.css";
import log from "loglevel";
import SuccessModal from "../../modals/successModal";
import { AuthContext } from "../../App";

var apigClientFactory = require('aws-api-gateway-client').default;

export default function UpdateTenantData() {

    const {cred} = useContext(AuthContext);
    const {tokens} = useContext(AuthContext);
    const accessKeyId = cred.accessKeyId;
    const SecretKey = cred.secretAccessKey;
    const SessionToken = cred.sessionToken;
    const region = tokens.region;
  
    const config = {
      invokeUrl: process.env.REACT_APP_SCOKET_URL,
      region: region,
      accessKey: accessKeyId,
      secretKey: SecretKey,
      sessionToken: SessionToken,
    };
  
    const apigClient = apigClientFactory.newClient(config);
  

    const [ openDialog, setOpenDialog ] = useState(false); 
    const onHandleCloseDialog =() => {
        setOpenDialog(false);
      }
 const { tenant_id } = useParams();
 const navigate = useNavigate();
 
  //initializing formdata with required fields for tenant onboarding
  const [formdata, updateFormdata] = useState({
    tenantDomain: "",
    tenantName: "",
    tenantOwnerEmailId: "",
    tenantDescription: "",
    tenantLogoFilename: "",
    tenantTheme: "",
    tenantTempPassExpiry: 1,
    tenantPasswordLength: 1,
    tenantPasswordRequireNumber: false,
    tenantPasswordRequireSpecial: false,
    tenantPasswordRequireUppercase: false,
    tenantPasswordRequireLowercase: false,
    tenantMfa: "",
    tenantRememberDevice: false,
    tenantWebClientAccess: false,
    created_at:'2023-07-02 15:02:20.952+00',
    updated_at:'2023-07-02 15:02:20.952+00',
  });

  const {
    tenantDomain,
    tenantName,
    tenantOwnerEmailId,
    tenantDescription,
    tenantTheme,
    tenantTempPassExpiry,
    tenantPasswordLength,
    tenantPasswordRequireNumber,
    tenantPasswordRequireSpecial,
    tenantPasswordRequireUppercase,
    tenantPasswordRequireLowercase,
    tenantMfa,
    tenantRememberDevice,
    tenantWebClientAccess,
  } = formdata;

  const [email, setEmail] = useState('');
  const [emailError, setEmailError] = useState('');

  const handleEmailChange = (e) => {
    updateFormdata({
        ...formdata,
        [e.currentTarget.id]: e.currentTarget.value,
      });
    const emailValue = e.target.value;
    setEmail(emailValue);

    if (emailValue && !validateEmail(emailValue)) {
      setEmailError('Invalid email format. Please enter valid Email');
    } else {
      setEmailError('');
    }
  };

  const validateEmail = () => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailRegex.test(email);
  };

  const [errorMsg, setErrorMsg] = useState(false);
  const [isSuccess, setIsSuccess] = useState(false);
  const [selectedFile, setSelectedFile] = useState({
    tenant_logo_filename: "",   //state for handling tenant logo file
  });
  const [ fileSize, setFileSize ] = useState();

  const onHandleFile = (e) => {
    const file = e.target.files[0];
    console.log(e.target.files);
   if(file) {
    const selectedFileSize = e.target.files[0].size;
    setFileSize(selectedFileSize);
    validateSelectedFile(selectedFileSize);
   }
   if(file) {
    const reader = new FileReader();   //to convert file format to base64 String format
    reader.readAsDataURL(file);        //will be useful when passing files to backend
    reader.onloadend = () => {
      setSelectedFile(reader.result);
    };
   }
  };

  const validateSelectedFile = (fileSize) => {
    // const MIN_FILE_SIZE = 10 // 1MB
    console.log("validate file calleddddd")
    const MAX_FILE_SIZE = 100;
    const fileSizeKiloBytes = fileSize / 1024;
    console.log(fileSizeKiloBytes)
   if (fileSizeKiloBytes > MAX_FILE_SIZE) {
      setErrorMsg("File size is greater than maximum limit");
      setIsSuccess(false);
      return;
    } else {
      setIsSuccess("File size and format is validated");
      setErrorMsg(true);
    }
  };

  const tenantStagingRequest = {
    tenantId: tenant_id,
    tenantName: `${tenantName}`,
    tenantDescription: `${tenantDescription}`,
    tenantDomain: `${tenantDomain}`,
    tenantOwnerEmailId: `${tenantOwnerEmailId}`,
    tenantLogoFilename: selectedFile,
    tenantTheme: `${tenantTheme}`,
    callbackUrl: process.env.REACT_APP_REDIRECT_URL,
    logoutUrl: process.env.REACT_APP_REDIRECT_URL,
    tenantPasswordLength: parseInt(tenantPasswordLength),
    tenantPasswordRequireNumber:
    tenantPasswordRequireNumber === "true" ? true : false,
    tenantPasswordRequireSpecial:
      tenantPasswordRequireSpecial === "true" ? true : false,
      tenantPasswordRequireLowercase:
      tenantPasswordRequireLowercase === "true" ? true : false,
      tenantPasswordRequireUppercase:
      tenantPasswordRequireUppercase === "true" ? true : false,
      tenantTempPassExpiry: parseInt(tenantTempPassExpiry),
      tenantMfa: `${tenantMfa}`,
      tenantRememberDevice: tenantRememberDevice === "true" ? true : false,
      tenantWebClientAccess:
      tenantWebClientAccess === "true" ? true : false,
      created_at:'2023-07-02 15:02:20.952+00',
      updated_at:'2023-07-02 15:02:20.952+00',
  };


  const [stagingID, setStagingID] = useState("");

  const getTenantData = async () => 
  {
    var pathParams = {};
      var pathTemplate = '/tenantservice/tenant'
      var method = 'GET';
      var additionalParams = 
      {
        headers: {
          "tenant-id": tenant_id
        }  
      };
       
      //var additionalParams = {};
      var body = {};
     try {
      await apigClient.invokeApi(pathParams, pathTemplate, method, additionalParams, body)
      .then((resp) => {
        console.log(resp);
        const Data = resp.data[0];
        const stagingId = Data.tenantStagingId;
        setStagingID(stagingId);
        updateFormdata(Data);
      });
    }
    catch (err) {
      log.error("Error fetching tenant data",err);
    }
  };

  useEffect(() => {
    getTenantData();
  }, []);

  const AddUpdatedVehicleModels = async () => 
  {
    var pathParams = {};
      var pathTemplate = `/tenantservice/tenant/${stagingID}`
      var method = 'PUT';
      var additionalParams = 
      {
        headers: {
          "tenant-id": tenant_id
        }  
      };
       
      //var additionalParams = {};
      var body = tenantStagingRequest;
    try {
      await apigClient.invokeApi(pathParams, pathTemplate, method, additionalParams, body)
      .then((resp) => {
        setOpenDialog(true);
        console.log("uError sending request", resp);
      });
    }
      catch (err) {
        log.error("Axios error",err);
        log.error("Axios error",err.message);
      }
  };

  const onHandleSubmit = (e) => {
    e.preventDefault();
    AddUpdatedVehicleModels();
  };


  const onHandleChange = (e) => {     //handles input entered by user
    updateFormdata({
      ...formdata,
      [e.currentTarget.id]: e.currentTarget.value,
    });
  };


  return (
    <div>
      <form>
        <h2>Branding</h2>
        <div className="brand">
          <div className="form-group row">
            <label className="col-sm-2 col-form-label">Domain</label>
            <div className="input-group mb-3">
              <div className="input-group-prepend">
                <span className="input-group-text" id="basic-addon3">
                  https://embfota-
                </span>
              </div>
              <div className="col-sm-3">
                <input
                  type="string"
                  className="form-control"
                  id="tenantDomain"
                  value={tenantDomain}
                  placeholder="Enter Domain"
                  maxLength={20}
                  disabled
                  onChange={onHandleChange}
                />
              </div>
              <div className="input-group-append">
                <span className="input-group-text" id="basic-addon2">
                  .auth.ap.south-1.amazoncognito.com
                </span>
              </div>
              <br />
              <div>
              </div>
            </div>
          </div>
          <br></br>
          <div className="form-group row">
            <div className="form-group col-md-6">
              <label>Brand Name</label>
              <input
                type="string"
                className="form-control"
                value={tenantName}
                id="tenantName"
                disabled
                required
                onChange={onHandleChange}
              />
            </div>
            <div className="form-group col-md-6">
              <label>Mail ID</label>
              <input
                type="email"
                className="form-control"
                value={tenantOwnerEmailId}
                id="tenantOwnerEmailId"
                placeholder="Enter Brand Owner Mail ID"
                required
                onChange={handleEmailChange}
              />
               {emailError && <div className="text-danger">{emailError}</div>}
            </div>
          </div>
          <br></br>
          <div className="form-group row">
            <label>Description</label>
            <div className="col-sm-12">
              <textarea
                className="form-control"
                placeholder="Enter Description"
                value={tenantDescription}
                id="tenantDescription"
                as="textarea"
                maxLength={50}
                rows={3}
                onChange={onHandleChange}
              />
            </div>
          </div>
          <br></br>
          <div className="form-group row">
            <label>Brand Logo</label>
            <div className="col-sm-9">
              <input
                type="file"
                className="form-control"
                required
                disabled
                // value={tenantLogoFilename}
                id="tenantLogoFilename"
                accept=".jpeg,.png"
                onChange={onHandleFile}
              />
              <small id="passwordHelpBlock" className="form-text text-muted">
                Please Upload File of Format JPEG/PNG and File Size Below 100KB
              </small>
              <p className="error-message">{errorMsg}</p>
              <p className="success-message">{isSuccess}</p>
            </div>
          </div>
          <br></br>
          <fieldset className="form-group">
            <div className="row">
              <legend className="col-form-label">Theme</legend>
              <div className="col-sm-10">
                <div className="form-check form-check-inline">
                  <input
                    className="form-check-input"
                    type="radio"
                    name="gridRadios"
                    id="tenantTheme"
                    checked={formdata.tenantTheme === "red-black"}
                    value={"red-black"}
                    onChange={onHandleChange}
                  />
                  <label className="form-check-label">Red & Black</label>
                </div>
                <div className="form-check form-check-inline">
                  <input
                    className="form-check-input"
                    type="radio"
                    name="gridRadios"
                    id="tenantTheme"
                    checked={formdata.tenantTheme === "blue-green"}
                    value={"blue-green"}
                    onChange={onHandleChange}
                  />
                  <label className="form-check-label">Blue & Green</label>
                </div>
                <div className="form-check form-check-inline">
                  <input
                    className="form-check-input"
                    type="radio"
                    name="gridRadios"
                    id="tenantTheme"
                    checked={formdata.tenantTheme === "ocean-green-yellow"}
                    value={"ocean-green-yellow"}
                    onChange={onHandleChange}
                  />
                  <label className="form-check-label">
                    Ocean Green & Yellow
                  </label>
                </div>
              </div>
            </div>
          </fieldset>
        </div>
        <br></br>
        <h2>Access Policy</h2>
        <br></br>
        <div className="policy">
          <div className="form-group row">
            <label className=" col-form-label">
              Password Length (Characters)
            </label>
            <div className="col-sm">
              <input
                type="number"
                min={6}
                max={99}
                className="form-control"
                value={tenantPasswordLength}
                id="tenantPasswordLength"
                placeholder="Enter password character length"
                required
                onChange={onHandleChange}
              />
            </div>
            <small id="passwordHelpBlock" className="form-text text-muted">
              Password length should be minimum of 6 characters to maximum of 99
              characters
            </small>
          </div>
          <br></br>
          <div className="form-group row">
            <div className="col-sm-10">
              <div className="form-check form-check-inline">
                <input
                  className="form-check-input"
                  type="checkbox"
                  id="tenantPasswordRequireNumber"
                  value={true}
                  checked={formdata.tenantPasswordRequireNumber === true}
                  onChange={onHandleChange}
                />
                <label className="form-check-label">Numbers</label>
              </div>
              <div className="form-check form-check-inline">
                <input
                  className="form-check-input"
                  type="checkbox"
                  id="tenantPasswordRequireSpecial"
                  value={true}
                  checked={formdata.tenantPasswordRequireSpecial === true}
                  onChange={onHandleChange}
                />
                <label className="form-check-label">Special Characters</label>
              </div>
              <div className="form-check form-check-inline">
                <input
                  className="form-check-input"
                  type="checkbox"
                  id="tenantPasswordRequireUppercase"
                  value={true}
                  checked={formdata.tenantPasswordRequireUppercase === true}
                  onChange={onHandleChange}
                />
                <label className="form-check-label">Uppercase letters</label>
              </div>
              <div className="form-check form-check-inline">
                <input
                  className="form-check-input"
                  type="checkbox"
                  id="tenantPasswordRequireLowercase"
                  value={true}
                  checked={formdata.tenantPasswordRequireLowercase === true}
                  onChange={onHandleChange}
                />
                <label className="form-check-label">Lowercase letters</label>
              </div>
            </div>
            <small id="passwordHelpBlock" className="form-text text-muted">
              Password should contain atleast one of the above given options
            </small>
          </div>
          <br></br>
          <div className="form-group row">
            <label className="col-form-label">
              Temporary Password Expiration (Days)
            </label>
            <div className="col-sm">
              <input
                type="number"
                min={1}
                className="form-control"
                value={tenantTempPassExpiry}
                id="tenantTempPassExpiry"
                placeholder="Enter Duration of Temporary Password in Days"
                required
                onChange={onHandleChange}
              />
            </div>
          </div>
          <br></br>
          <div className="form-group row">
            <div className="form-group col-md-6">
              <label className="col-form-label">
                Multi Factor Authentication
              </label>
              <div className="col-sm-8">
                <div className="form-check form-check-inline">
                  <input
                    className="form-check-input"
                    type="radio"
                    name="inlineRadioOptions1"
                    id="tenantMfa"
                    value={"OPTIONAL"}
                    checked={formdata.tenantMfa === "OPTIONAL"}
                    // checked={true}
                    onChange={onHandleChange}
                  />
                  <label className="form-check-label">Optional</label>
                </div>
                <div className="form-check form-check-inline">
                  <input
                    className="form-check-input"
                    type="radio"
                    name="inlineRadioOptions1"
                    id="tenantMfa"
                    value={"ON"}
                    checked={formdata.tenantMfa === "ON"}
                    onChange={onHandleChange}
                  />
                  <label className="form-check-label">Required</label>
                </div>
              </div>
            </div>
            <div className="form-group col-md-6">
              <label className="col-form-label">Remember user device</label>
              <div className="col-sm-8">
                <div className="form-check form-check-inline">
                  <input
                    className="form-check-input"
                    type="radio"
                    name="inlineRadioOptions2"
                    id="tenantRememberDevice"
                    value={true}
                    checked={formdata.tenantRememberDevice === true}
                    onChange={onHandleChange}
                  />
                  <label className="form-check-label" htmlFor="inlineRadio1">
                    Required
                  </label>
                </div>
                <div className="form-check form-check-inline">
                  <input
                    className="form-check-input"
                    type="radio"
                    name="inlineRadioOptions2"
                    id="tenantRememberDevice"
                    value={true}
                    checked={formdata.tenantRememberDevice === true}
                    onChange={onHandleChange}
                  />
                  <label className="form-check-label" htmlFor="inlineRadio2">
                    Not Required
                  </label>
                </div>
              </div>
            </div>
          </div>
          <br></br>
          <div className="form-group col-md-6">
            <label className="col-form-label">Access To Web Client</label>
            <div className="col-sm-8">
              <div className="form-check form-check-inline">
                <input
                  className="form-check-input"
                  type="radio"
                  name="inlineRadioOptions3"
                  id="tenantWebClientAccess"
                  value={true}
                  checked={formdata.tenantWebClientAccess === true}
                  onChange={onHandleChange}
                />
                <label className="form-check-label">Required</label>
              </div>
              <div className="form-check form-check-inline">
                <input
                  className="form-check-input"
                  type="radio"
                  name="inlineRadioOptions3"
                  id="tenantWebClientAccess"
                  value={false}
                  checked={formdata.tenantWebClientAccess === false}
                  onChange={onHandleChange}
                />
                <label className="form-check-label">Not Required</label>
              </div>
            </div>
          </div>
        </div>
        <br></br>
        <div className="buttons2">
          <div>
            <button
              onClick={() => navigate("/OnboardStatus")}
              type="button"
              className="btn btn-dark"
            >
              Cancel
            </button>
          </div>
          <div className="buttons1">
            <button
              onClick={(e) => onHandleSubmit(e)}
              type="button"
              className="btn btn-dark"
            >
              Submit
            </button>
          </div>
        </div>
      </form>
      {
      openDialog && (
        <SuccessModal openDialog={openDialog} onHandleClose ={onHandleCloseDialog} />
      )}
    </div>
  );
}