import "bootstrap/dist/css/bootstrap.min.css";
import React, { useContext, useEffect, useState } from "react";

import { AuthContext } from "../../App";
import Breadcrumb from "../../breadCrumbs/navigation";
import Handler from "../../components/handler";
import useAxiosApiRequest from "../../hook/useAxiosApiRequest";
import SuccessCreateModal from "../../modals/successModalCreate";
import "./ListFirmware.css";
import { useNavigate, useParams } from "react-router-dom";
import RichTextEditor from "./RichTextEditor";
import UploadModal from "./UploadModal";
import ListFirmwareFiles from "./ListFirmwareFiles";
import { Button } from "@mui/material";

export default function AddEditFirmware() {
  const { statusCode, errormsg, loading, apiRequest } = useAxiosApiRequest();
  const { userName, email } = useContext(AuthContext);
  const { firmwareId } = useParams();
  const tenantID = localStorage.getItem("TenantID");
  const tenant = tenantID ? JSON.parse(tenantID) : null;
  const navigate = useNavigate();

  const [rowIndex, setRowIndex] = useState(null); //this fetches and sets the row index that needs to be edited
  const [deviceTypes, setDeviceTypes] = useState([]); //this fetches and sets the options for device dropdown
  const [openDialog, setOpenDialog] = useState(false); //success dialog
  const [openUploadDialog, setOpenUploadDialog] = useState(false); //upload dialog
  const [isFormValid, setIsFormValid] = useState(false);
  const [modelNames, setModelNames] = useState([]);
  // const [errorMsg, setErrorMsg] = useState(false); //this is used in upload
  // const [isSuccess, setIsSuccess] = useState(false);
  const [fieldErrors, setFieldErrors] = useState({
    deviceType: "",
    deviceModel: "",
    firmwareVersion: "",
    comment: "",
    releaseNotes: "",
    files: {
      fileName: "",
      hashAlgo: "",
      digest: "",
      size: "",
      firmwareFile: "",
    },
  });

  //initializing state with required fields for device model creation
  const [releaseNotes, setReleaseNotes] = useState(""); //this is managed seperately to avoid sideeffect
  const [formData, updateFormData] = useState({
    deviceType: "",
    deviceModel: "",
    firmwareVersion: "",
    comment: "",
    files: [],
  });

  useEffect(() => {
    getDeviceType();
    if (firmwareId) {
      getFirmwareDetails();
      getModelNames();
    }
  }, [firmwareId]);

  useEffect(() => {
    const checkEmptyFields = (obj) => {
      for (let key in obj) {
        if (typeof obj[key] === "object" && obj[key] !== null) {
          if (checkEmptyFields(obj[key])) {
            return true;
          }
        } else {
          if (obj[key] === "" || obj[key] === null || obj[key] === undefined) {
            return true;
          }
        }
      }
      return false;
    };
    setIsFormValid(!checkEmptyFields(fieldErrors));
  }, [fieldErrors, formData]);
  const { deviceType, firmwareVersion, comment, deviceModel, files } =
    formData || {};
  // console.log(formData)
  const onHandleCloseDialog = () => {
    // setOpenDialog(false);
    navigate(`/listFirmware`);
  };

  //mandatory field validation
  const validateForm = () => {
    const newErrors = {};
    if (!formData.deviceType?.trim()) {
      newErrors.deviceType = "Select Device Type";
    }
    if (!formData.comment?.trim()) {
      newErrors.comment = "Enter comment";
    }
    if (!formData.deviceModel?.trim()) {
      newErrors.deviceModel = "Enter Model Name";
    }
    if (files === null) {
      newErrors.files = "Upload is required";
    }
    setFieldErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  //user input validations

  const onHandleDeviceTypeChange = (e) => {
    updateFormData({
      ...formData,
      [e.target.id]: e.target.value,
    });
    const selectedDeviceType = e.target.value;

    setFieldErrors({
      ...fieldErrors,
      [e.currentTarget.id]: "",
    });
    getModelNames("deviceType.name", selectedDeviceType, "null", "desc");
  };

  const onHandleModelNameChange = (e) => {
    const { value } = e.target;

    updateFormData({
      ...formData,
      [e.target.id]: value,
    });
    setFieldErrors({
      ...fieldErrors,
      [e.currentTarget.id]: "",
    });
  };

  const handleFirmwareVersion = (e) => {
    const { value } = e.target;

    if (value.length < 1) {
      setFieldErrors({
        ...fieldErrors,
        [e.currentTarget.id]: "This field cannot be empty",
      });
    } else {
      setFieldErrors({
        ...fieldErrors,
        [e.currentTarget.id]: "",
      });
    }
    updateFormData({
      ...formData,
      [e.target.id]: value,
    });
  };

  const handleUploadFirmware = (data) => {
    if (rowIndex !== null) {
      let updatedFiles = [...formData.files];
      updatedFiles[rowIndex] = data;
      updateFormData({
        ...formData,
        files: updatedFiles,
      });
      setRowIndex(null);
    } else {
      let updatedFiles = [...formData.files, data];
      updateFormData({
        ...formData,
        files: updatedFiles,
      });
    }
  };
  const handleComment = (e) => {
    const { value } = e.target;
    if (value.length < 1) {
      setFieldErrors({
        ...fieldErrors,
        [e.currentTarget.id]: "This field cannot be empty",
      });
    } else {
      setFieldErrors({
        ...fieldErrors,
        [e.currentTarget.id]: "",
      });
    }
    updateFormData({
      ...formData,
      [e.target.id]: value,
    });
  };
  const handleEditor = (value, text) => {
    if (text.length <= 1) {
      setFieldErrors({
        ...fieldErrors,
        releaseNotes: "This field cannot be empty",
      });
    } else {
      setFieldErrors({
        ...fieldErrors,
        releaseNotes: "",
      });
    }
    setReleaseNotes(value);
  };
  const getFirmwareDetails = async () => {
    const response = await apiRequest({
      endpoint: `/firmwareversionservice/firmwareversion/${firmwareId}`,
      method: "GET",
      additionalParams: {
        headers: {
          "tenant-id": tenant,
        },
      },
      body: {},
    });
    const filesWithoutId = response.files.map((file) => {
      // Destructure the file object, excluding _id
      // eslint-disable-next-line no-unused-vars
      const { _id, ...rest } = file;
      return rest; // Return the object without _id
    });
    setReleaseNotes(response.releaseNotes);
    updateFormData({
      deviceType: response.deviceType,
      firmwareVersion: response.firmwareVersion,
      deviceModel: response.deviceModel,
      files: filesWithoutId,
      comment: response.comment,
    });
  };

  const getDeviceType = async () => {
    const response = await apiRequest({
      endpoint: `/deviceservice/masterdata/deviceType`,
      method: "GET",
      additionalParams: {
        headers: {
          "tenant-id": tenant,
        },
      },
      body: {},
    });
    const Data = response?.deviceMasterData;
    if (Array.isArray(Data)) {
      setDeviceTypes(Data);
    }
  };
  const handleUploadModal = () => {
    setOpenUploadDialog(false);
  };

  const getModelNames = async (filterField, fieldValue, field, newOrder) => {
    let URL = `/deviceservice/devicemodel/allmodels/10/1/${filterField}/${fieldValue}/${field}/${newOrder}`;
    if (firmwareId) {
      URL = `/deviceservice/devicemodel/allmodels/10/1/deviceType.name/${deviceType}/null/desc`;
    }

    const response = await apiRequest({
      endpoint: URL,
      method: "GET",
      additionalParams: {
        headers: {
          "tenant-id": tenant,
        },
      },
      body: {},
    });
    const Data = response?.deviceModels.map((i) => i.modelName);
    if (Array.isArray(Data)) {
      setModelNames(Data);
    }
  };

  const submitFirmware = async () => {
    let body;
    if (firmwareId) {
      const { files, comment } = formData;
      body = {
        files,
        comment,
        updatedBy: { username: userName, email: email },
        releaseNotes: releaseNotes,
        id: firmwareId,
      };
    } else {
      body = {
        ...formData,
        createdBy: { username: userName, email: email },
        releaseNotes: releaseNotes,
      };
    }

    const response = await apiRequest({
      endpoint: `/firmwareversionservice/firmwareversion`,
      method: firmwareId ? "PATCH" : "POST",
      additionalParams: {
        headers: {
          "tenant-id": tenant,
        },
      },
      body: body,
    });
    if (response) {
      navigate(`/listFirmware`);
      setOpenDialog(true);
    }
  };
  const handleEditRow = (index) => {
    setOpenUploadDialog(true);
    setRowIndex(index);
  };
  const onHandleSubmit = (e) => {
    e.preventDefault();
    if (validateForm()) {
      submitFirmware();
      // updateFormData({
      //   deviceType: "",
      //   deviceModel: "",
      //   modelNumber: "",
      //   description: "",
      //   model_image: "",
      // });
      // setIsSuccess("");
    }
  };
  return (
    <Handler statusCode={statusCode} loading={loading} errormsg={errormsg}>
      <div>
        <Breadcrumb />
        {openDialog && (
          <SuccessCreateModal
            openDialog={openDialog}
            onHandleClose={onHandleCloseDialog}
          />
        )}
        <div className="addFirmware">
          {firmwareId ? <h4>Edit Firmware</h4> : <h4>Add New Firmware</h4>}
          {/* form for getting user inputs */}
          <form className="mt-3 mb-3" onSubmit={(e) => onHandleSubmit(e)}>
            <div className="form-group row">
              <div className="form-group col-md-6 mb-2">
                <label id="mandatorylabels">Select Device Type</label>
                <select
                  className="form-control"
                  id="deviceType"
                  value={deviceType}
                  disabled={!!firmwareId}
                  onChange={onHandleDeviceTypeChange}
                >
                  <option selected disabled value={""}>
                    Select Device Type
                  </option>
                  {deviceTypes &&
                    deviceTypes.map((getDeviceName) => (
                      <option key={getDeviceName.id}>
                        {getDeviceName.name}
                      </option>
                    ))}
                  {/* <option key={"getDeviceName.id"}>dummy</option> */}
                </select>
                {fieldErrors.deviceType && (
                  <p className="error-message">{fieldErrors.deviceType}</p>
                )}
              </div>

              <div className="form-group col-md-6 mb-2">
                <label id="mandatorylabels">Device Model Name</label>
                <select
                  className="form-control"
                  id="deviceModel"
                  value={deviceModel}
                  disabled={!!firmwareId}
                  onChange={onHandleModelNameChange}
                >
                  <option selected disabled value={""}>
                    Select Model Name
                  </option>
                  {modelNames &&
                    modelNames.map((item, index) => (
                      <option key={index}>{item}</option>
                    ))}
                  {/* <option key={"getDeviceName.id"}>dummy</option> */}
                </select>
                {fieldErrors && fieldErrors.deviceModel && (
                  <p className="error-message">{fieldErrors.deviceModel}</p>
                )}
              </div>
            </div>
            <div className="form-group row">
              <div className="form-group col-md-6 mb-2">
                <label id="mandatorylabels">Firmware version</label>
                <input
                  type="string"
                  className="form-control"
                  id="firmwareVersion"
                  value={firmwareVersion || ""}
                  disabled={firmwareId ? true : false}
                  placeholder="Enter Firmware version"
                  maxLength={100}
                  onChange={handleFirmwareVersion}
                />

                {fieldErrors && fieldErrors.firmwareVersion && (
                  <p className="error-message">{fieldErrors.firmwareVersion}</p>
                )}
              </div>
              <div className="form-group col-md-6 mb-2">
                <label id="mandatorylabels">Comment</label>
                <input
                  type="string"
                  className="form-control"
                  id="comment"
                  value={comment || ""}
                  placeholder="Enter Comment"
                  maxLength={100}
                  onChange={handleComment}
                />

                {fieldErrors && fieldErrors.comment && (
                  <p className="error-message">{fieldErrors.comment}</p>
                )}
              </div>
            </div>
            <div className="form-group row">
              <div className="form-group col-md-12 mb-2">
                <label>Release Note</label>
                <RichTextEditor
                  id="releaseNotes"
                  value={releaseNotes}
                  handleEditor={handleEditor}
                />
                {fieldErrors && fieldErrors.releaseNotes && (
                  <p className="error-message">{fieldErrors.releaseNotes}</p>
                )}
              </div>
            </div>
            <div className="col-sm-12 mb-2">
              <label>Upload Framework</label>
              <div className="col-sm-12">
                <Button
                  sx={{ border: "1px solid", color: "#1442cc" }}
                  onClick={() => setOpenUploadDialog(true)}
                >
                  Add file
                </Button>

                <UploadModal
                  openDialog={openUploadDialog}
                  handleUploadFirmware={handleUploadFirmware}
                  onHandleClose={handleUploadModal}
                  editData={rowIndex !== null ? formData.files[rowIndex] : null}
                />
                <br />
                {files?.length ? (
                  <ListFirmwareFiles
                    key={Math.random()}
                    formData={formData}
                    handleEditRow={handleEditRow}
                  />
                ) : null}
                <br />
              </div>
            </div>
            <div className="form-group row">
              <div className="col-sm-12">
                <button
                  disabled={isFormValid}
                  type="submit"
                  className="btn btn-dark btn-block"
                >
                  Submit
                </button>
              </div>
            </div>
          </form>
        </div>
      </div>
    </Handler>
  );
}
