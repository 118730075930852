import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import ArrowDropUpIcon from "@mui/icons-material/ArrowDropUp";
import VisibilityIcon from "@mui/icons-material/Visibility";
import { FormControl } from "@mui/material";
import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import React, { useEffect, useState } from "react";
import { FaBackward, FaForward } from "react-icons/fa";
import { FiEdit } from "react-icons/fi";
import ReactPaginate from "react-paginate";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import Handler from "../../components/handler";
import useAxiosApiRequest from "../../hook/useAxiosApiRequest";
import DeviceModelDetailsModal from "./deviceModelStatus";
import "./viewDeviceModel.css";

export default function ViewdeviceModel() {

  const userRole = useSelector((state) => state.tokens?.tokens?.userRole)
  const finalRoles = userRole ? userRole.split('/') : null;
  const CURRENT_USER_TYPE = finalRoles ? finalRoles[1] : null;

  const tenantID = localStorage.getItem('TenantID');
  const tenant = tenantID ? JSON.parse(tenantID) : null;
  const adminrole = "adminrole";

  //to handle data received
  const [deviceModels, setdeviceModels] = useState([]);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectedDeviceModal, setSelectedDeviceModal] = useState(null);
  const [sortField, setSortField] = useState("null");
  const [sortOrder, setSortOrder] = useState("desc");
  const [deviceTypeData, setDeviceTypeData] = useState("");
  const [pageNumber, setPageNumber] = useState(1);
  const [totalRecords, setTotalRecords] = useState(0);
  const [dataLength, setDataLength] = useState(0);
  const [formData, setFormData] = useState({
    deviceType: "",
  });

  useEffect(() => {
    getDeviceType();
    getdevice(1, "null", "null", "null", "desc");
  }, []);

  const navigate = useNavigate();
  const deviceModelsPerPage = 10;
  const { statusCode, loading, apiRequest } = useAxiosApiRequest();
  const { deviceType } = formData;

  const handleOpenModal = (deviceModel) => {
    setSelectedDeviceModal(deviceModel);
    setIsModalOpen(true);
  };

  const handleCloseModal = () => {
    setIsModalOpen(false);
  };

  const handleCreateOnClick = () => {
    navigate("/deviceModels/AddDeviceModel")
  }

  const handleUpdateOnClick = (modelStatus,modelNumber) => {
  if(modelStatus === "ACTIVE") {
    navigate(`/deviceModels/UpdatedeviceModel/${modelNumber}`)
  }
  }

  const handleDeviceTypeChange = (e) => {
    setFormData((prevState) => ({
      ...prevState,
      [e.target.id]: e.target.value,
    }));
    getdevice(1, `deviceType.name`, e.target.value, "null", "desc");
  };

  const changePage = (selected) => {
    const page = selected?.nextSelectedPage;
    if (page != undefined) {
      setPageNumber(page + 1);
      getdevice(page + 1, null, null, sortField, sortOrder);
    }
  };

  const handleSort = (field) => {
    let newOrder = "asc";
    if (sortField === field && sortOrder === "asc") {
      newOrder = "desc";
    }
    setSortField(field);
    setSortOrder(newOrder);
    getdevice(pageNumber, "null", "null", field, newOrder);
  };

  const getdevice = async (
    selected,
    filterField,
    fieldValue,
    field,
    newOrder
  ) => {
    const response = await apiRequest({
      endpoint: `/deviceservice/devicemodel/allmodels/${deviceModelsPerPage}/${selected}/${filterField}/${fieldValue}/${field}/${newOrder}`,
      method: "GET",
      additionalParams: {
        headers: {
          "tenant-id": tenant
        } 
      },
      body: {},
    });
    const Data = response?.deviceModels;
    const totalRecords = response?.totalPageCount;
    const length = response?.totalDocumentCount;
    if (Array.isArray(Data)) {
      setdeviceModels(Data);
      setTotalRecords(totalRecords);
      setDataLength(length);
    }
  };

  const getDeviceType = async () => {
    const response = await apiRequest({
      endpoint: `/deviceservice/masterdata/deviceType`,
      method: "GET",
      additionalParams: {
        headers: {
          "tenant-id": tenant
        } 
      },
      body: {},
    });
    const Data = response?.deviceMasterData;
    if (Array.isArray(Data)) {
      setDeviceTypeData(Data);
    }
  };

  return (
    <Handler statusCode={statusCode} loading={loading}>
      <div>
        <DeviceModelDetailsModal
          isOpen={isModalOpen}
          handleClose={handleCloseModal}
          DeviceModel={selectedDeviceModal}
          reloaDeviceData={getdevice}
        />
        <div className="heading">
          <h5>List of Available Device Models</h5>
          <FormControl style={{ width: "250px" }} size="small">
            <select
              style={{
                float: "right",
                padding: "5px 10px",
                border: "2px solid #080808",
              }}
              className="form-control"
              id="deviceType"
              value={deviceType}
              onChange={handleDeviceTypeChange}
              required
            >
              <option selected disabled value={""}>
                Filter By DeviceType
              </option>
              {deviceTypeData &&
                deviceTypeData.length &&
                deviceTypeData.map((deviceData) => (
                  <option key={deviceData.id}>{deviceData.name}</option>
                ))}
            </select>
          </FormControl>
        </div>
        <br></br>
        <Paper sx={{ width: "100%", overflow: "hidden" }}>
          <TableContainer sx={{ maxHeight: 440 }}>
            <Table stickyHeader aria-label="sticky table">
              <TableHead sx={{ maxHeight: 20, cursor: "pointer" }}>
                <TableRow>
                  <TableCell
                    sx={{ fontWeight: 700 }}
                    align="center"
                    onClick={() => handleSort("deviceType")}
                  >
                    Device Type
                    {sortField === "deviceType" && sortOrder === "asc" ? (
                      <ArrowDropUpIcon />
                    ) : (
                      <ArrowDropDownIcon />
                    )}
                  </TableCell>
                  <TableCell
                    sx={{ fontWeight: 700 }}
                    align="center"
                  >
                    DeviceID Type 
                  </TableCell>
                  <TableCell
                    sx={{ fontWeight: 700 }}
                    align="center"
                    onClick={() => handleSort("modelName")}
                  >
                    Model Name
                    {sortField === "modelName" && sortOrder === "asc" ? (
                      <ArrowDropUpIcon />
                    ) : (
                      <ArrowDropDownIcon />
                    )}
                  </TableCell>
                  <TableCell
                    sx={{ fontWeight: 700 }}
                    align="center"
                    onClick={() => handleSort("modelNumber")}
                  >
                    Model Number
                    {sortField === "modelNumber" && sortOrder === "asc" ? (
                      <ArrowDropUpIcon />
                    ) : (
                      <ArrowDropDownIcon />
                    )}
                  </TableCell>
                  <TableCell sx={{ fontWeight: 700 }} align="center">
                    Description
                  </TableCell>
                  {CURRENT_USER_TYPE &&
                    CURRENT_USER_TYPE.localeCompare(adminrole, undefined, {
                      sensitivity: "base",
                    }) === 0 && (
                      <>
                        <TableCell sx={{ fontWeight: 700 }} align="center">
                          Action
                        </TableCell>
                      </>
                    )}
                </TableRow>
              </TableHead>
              <TableBody>
                {deviceModels &&
                  deviceModels.map((deviceModel, index) => {
                    return (
                      <TableRow
                        style={
                          index % 2
                            ? { backgroundColor: "rgba(72, 34, 180, 0.04)" }
                            : { backgroundColor: "white" }
                        }
                        hover
                        role="checkbox"
                        tabIndex={-1}
                        key={deviceModel.id}
                      >
                        <TableCell align="center">
                          {deviceModel.deviceType?.name || "-"}
                        </TableCell>
                        <TableCell align="center">
                          {deviceModel.deviceType?.deviceIdType || "-"}
                        </TableCell>
                        <TableCell align="center">
                          {deviceModel.modelName || "-"}
                        </TableCell>
                        <TableCell align="center">
                          {deviceModel.modelNumber || "-"}
                        </TableCell>
                        <TableCell align="center">
                          {deviceModel.description || "-"}
                        </TableCell>
                        {CURRENT_USER_TYPE &&
                          CURRENT_USER_TYPE.localeCompare(
                            adminrole,
                            undefined,
                            {
                              sensitivity: "base",
                            }
                          ) === 0 && (
                            <>
                              <TableCell
                                sx={{
                                  display: "block",
                                }}
                              >
                                <FiEdit
                                  style={{
                                    marginRight: "10px",
                                    fontSize: "110%",
                                    color:
                                      deviceModel.modelStatus === "ACTIVE"
                                        ? "#4822B4"
                                        : "grey",
                                  }}
                                  title={"Update"}
                                  onClick={()=>handleUpdateOnClick(deviceModel.modelStatus,deviceModel.modelNumber)}
                                />
                                <VisibilityIcon
                                  style={{
                                    fontSize: "120%",
                                    color: "#4822B4",
                                  }}
                                  title={"View"}
                                  onClick={() => handleOpenModal(deviceModel)}
                                />
                              </TableCell>
                            </>
                          )}
                      </TableRow>
                    );
                  })}
              </TableBody>
            </Table>
          </TableContainer>
          <br></br>
          <div className="pagenate">
            <h6>Available Device Models</h6>
            <div>
              {/* pagination for table */}
              <ReactPaginate
                previousLabel={<FaBackward />}
                nextLabel={<FaForward />}
                breakLabel={"...."}
                marginPagesDisplayed={2}
                pageCount={totalRecords}
                // onPageChange={changePage}
                containerClassName={"paginationBttns"}
                previousLinkClassName={"previousBttns"}
                disabledClassName={"paginationDisabled"}
                activeClassName={"paginationActive"}
                onClick={changePage}
              ></ReactPaginate>
            </div>
            <h6>Total Records : {dataLength}</h6>
          </div>
        </Paper>
        <br></br>
        {CURRENT_USER_TYPE &&
          CURRENT_USER_TYPE.localeCompare(adminrole, undefined, {
            sensitivity: "base",
          }) === 0 && (
            <div>
              <button
                type="submit"
                className="btn btn-dark"
                style={{
                  minWidth: "30%",
                  margin: "auto",
                  display: "block",
                }}
                onClick={handleCreateOnClick}
              >
                Create Device Model
              </button>
            </div>
          )}
      </div>
    </Handler>
  );
}
