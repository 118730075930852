import React from "react";
import Lottie from "react-lottie";
import indicator from "../../assets/lottie/loader.json";
function Loader() {
  const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: indicator,
    rendererSettings: {
      preserveAspectRatio: "xMidYMid slice",
    },
  };
  return (
    <div
      style={{
        flex: 1,
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        height:"100%",
        width:"100%",
        position:"fixed",
        bottom:0,
        backgroundColor:'rgba(255,255,255,0.6)',
        zIndex:1000
      }}
    >
      <Lottie options={defaultOptions} height={50} width={50} style={{position:"fixed",top:'50%',left:'50%'}}/>
    </div>
  );
}

export default Loader;
