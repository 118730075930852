import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import ArrowDropUpIcon from "@mui/icons-material/ArrowDropUp";
import AppBar from "@mui/material/AppBar";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import log from "loglevel";
import React, { useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { AuthContext } from "../App";
import "./navbar.css";
import embLogo from "./pioneer_logo.svg";

var apigClientFactory = require("aws-api-gateway-client").default;

function Header({ username, onLogout }) {
  // localStorage.setItem("theme", "blue-green");

  // const tenantStagingData = process.env.REACT_APP_GET_TENANT_DATA_PROVISIONINGTABLE_URL;
  const tenant = JSON.parse(localStorage.getItem("TenantID"));

  const { cred } = useContext(AuthContext);
  const { tokens } = useContext(AuthContext);
  const accessKeyId = cred.accessKeyId;
  const SecretKey = cred.secretAccessKey;
  const SessionToken = cred.sessionToken;
  const region = tokens.region;

  const config = {
    invokeUrl: process.env.REACT_APP_SERVICE_URL_v2,
    region: region,
    accessKey: accessKeyId,
    secretKey: SecretKey,
    sessionToken: SessionToken,
  };

  const apigClient = apigClientFactory.newClient(config);

  const navigate = useNavigate();

  //defining state for handling menu when clicking on userAccount icon
  const [anchorEl, setAnchorEl] = useState(null);

  const [dropdownOpen, setDropdownOpen] = useState(false);

  const handleMenu = (event) => {
    setAnchorEl(event.currentTarget);
    setDropdownOpen(!dropdownOpen);
  };

  const handleClose = () => {
    setAnchorEl(null);
    setDropdownOpen(false);
  };

  const onHandleSignout = () => {
    onLogout();
    localStorage.clear();
    navigate("/");
    window.location.reload();
  };

  const [userLogo, setuserLogo] = useState("");

  const getUserLogo = async () => {
    var pathTemplate = "/tenantservice/tenant";
    var method = "GET";
    var pathParams = {
      //This is where path request params go.
      //userId: '1234',
    };
    var additionalParams = {
      headers: {
        "Content-Type": "application/json",
        "tenant-id": tenant,
      },
    };

    var body = {
      //This is where you define the body of the request
    };
    try {
      await apigClient
        .invokeApi(pathParams, pathTemplate, method, additionalParams, body)
        .then((resp) => {
          // const themeData = resp.data[0].
          const Data = resp.data[0].tenantLogoFilename;
          const themeData = resp.data[0].tenantTheme;
          localStorage.setItem("theme", themeData);
          setuserLogo(Data);
        });
    } catch (err) {
      log.error("Error", err);
    }
  };

  useEffect(() => {
    if (SessionToken && accessKeyId && SecretKey) {
      getUserLogo();
    }
  }, [accessKeyId && SecretKey]);

  useEffect(() => {
    const theme = "pioneer-default";
    document.documentElement.setAttribute("data-theme", theme);
  }, [userLogo]);

  return (
    <>
      <AppBar
        position="fixed"
        className="header"
        style={{ background: "var(--primary-color-header)" }}
        sx={{ zIndex: (theme) => theme.zIndex.drawer + 1 }}
      >
        <Toolbar sx={{ justifyContent: "space-between" }}>
          <Typography
            variant="h5"
            component="div"
            sx={{
              flexGrow: 1,
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <img
              src={embLogo}
              alt="User Logo"
              style={{ width: "150px", height: "40px", objectFit: "contain" }}
            />
          </Typography>
          <div
            style={{
              display: "flex",
              alignItems: "center",
              marginRight: "16px",
            }}
          >
            <Typography onClick={handleMenu} sx={{ color: "white" }}>
              {username}
            </Typography>
            {dropdownOpen ? (
              <ArrowDropUpIcon />
            ) : (
              <ArrowDropDownIcon sx={{ color: "white" }} />
            )}
          </div>
          <Menu
            className="usernameMenu"
            id="menu-appbar"
            anchorEl={anchorEl}
            anchorOrigin={{
              vertical: "bottom",
              horizontal: "right",
            }}
            keepMounted
            transformOrigin={{
              vertical: "top",
              horizontal: "right",
            }}
            open={Boolean(anchorEl)}
            onClose={handleClose}
          >
            <MenuItem>
              {/* onClick={() => navigate(`/PasswordChange`)} */}
              Edit Profile
            </MenuItem>
            <MenuItem onClick={onHandleSignout}>Logout</MenuItem>
          </Menu>
        </Toolbar>
      </AppBar>
    </>
  );
}
export default Header;
