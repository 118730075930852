import { getLocalStorageData } from "../utilities/helper";

const DELETE = "DELETE";
const GET = "GET";
const additionalParams = {
  headers: {
    "tenant-id": getLocalStorageData("TenantID"),
  },
};
const pathParams = {
  text: "hi",
};
const body = {};

const { innerWidth: width, innerHeight: height } = window;

const statusCodeMessage = {
  0: "Creating",
  200: "Successfully Added",
  400: "Bad Request",
  401: "Unauthorized",
  402: "Payment Required",
  403: "Forbidden",
  404: "Page Not Found",
  406: "Not Acceptable",
  408: "Request Timeout",
  429: "Too Many Request",
  500: "Internal Server Error OR Network Error",
};

const DRAFT = "DRAFT";
const INREVIEW = "INREVIEW";
const ADMIN_PERMISSIONS = ["Approver", "Editor"];

export {
  DRAFT,
  INREVIEW,
  ADMIN_PERMISSIONS,
  DELETE,
  GET,
  additionalParams,
  pathParams,
  body,
  width,
  height,
  statusCodeMessage,
};
