import * as type from '../type';

export function set_user_type(userType) {
    return {
        type:type.set_user_type,
        payload:userType
    }
}

export function set_cred(cred) {
    return {
        type:type.set_cred,
        payload:cred
    }
}

export function set_tokens(tokens) {
    return {
        type:type.set_tokens,
        payload:tokens
    }
}