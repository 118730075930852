import { withAuthenticator } from "@aws-amplify/ui-react";
import "@aws-amplify/ui-react/styles.css";
import { Amplify, Auth } from "aws-amplify";
import jwtDecode from "jwt-decode";
import queryString from "query-string";
import React, { createContext, useEffect, useState } from "react";
import "./App.css";
import "./index.css";
import Header from "./components/header";
import useCredentials from "./hook/useCredentials";
import NavbarRoutes from "./userRoles/navbar";

const { tenant_id, userpool_id, identity_pool_id, client_id, region } =
  queryString.parse(window.location.search);

const storedAmplifyConfig = localStorage.getItem("amplifyConfig");
const amplifyConfig = storedAmplifyConfig
  ? JSON.parse(storedAmplifyConfig)
  : null;

const isConfigChanged =
  amplifyConfig &&
  tenant_id &&
  (amplifyConfig.aws_project_region !== region ||
    amplifyConfig.aws_cognito_identity_pool_id !== identity_pool_id ||
    amplifyConfig.aws_user_pools_id !== userpool_id ||
    amplifyConfig.aws_user_pools_web_client_id !== client_id);

if (
  (userpool_id && identity_pool_id && client_id && region && tenant_id) ||
  isConfigChanged
) {
  Amplify.configure({
    aws_project_region: region,
    aws_cognito_identity_pool_id: identity_pool_id,
    aws_cognito_region: region,
    aws_user_pools_id: userpool_id,
    aws_user_pools_web_client_id: client_id,
  });

  localStorage.setItem("amplifyConfig", JSON.stringify(Amplify.configure()));
  localStorage.setItem("TenantID", JSON.stringify(tenant_id));
} else if (amplifyConfig) {
  Amplify.configure(amplifyConfig);
} else {
  Amplify.configure({
    aws_project_region: process.env.REACT_APP_DEFAULT_AWS_CLI,
    aws_cognito_identity_pool_id: process.env.REACT_APP_DEFAULT_IDENTITYPOOL_ID,
    aws_cognito_region: process.env.REACT_APP_DEFAULT_AWS_CLI,
    aws_user_pools_id: process.env.REACT_APP_DEFAULT_USERPOOL_ID,
    aws_user_pools_web_client_id: process.env.REACT_APP_DEFAULT_WEBCLIENT_ID,
  });
}

export const AuthContext = createContext();

function App({ signOut, user }) {

  const [ role, setRole ] = useState("");
  const [ tokens, setTokens ] = useState({});
  const [ cred, setCred ] = useState({});
  const [userName, setUserName] = useState("");
  const [email, setEmail] = useState("");
  const { setCredentials } = useCredentials();

  useEffect(() => {
    if (user) {
      setCredentials();
    }
  }, [user]);

  const refreshToken = async () => {
    try {
      const session = await Auth.currentSession();
      const userEmail = session.getIdToken().decodePayload().email;
      const accessToken = session.getAccessToken().getJwtToken();
      const idToken = session.getIdToken().getJwtToken();
      const decodedIdToken = jwtDecode(idToken);
      const region = decodedIdToken.iss.split(".")[1];
      console.log("Region:", region);
      const groups = decodedIdToken["cognito:roles"];
      const userRole = groups ? groups[0] : null;
      const finalRoles = userRole.split("/");
      console.log(finalRoles[1]);
      const userrole = finalRoles[1];
      setEmail(userEmail);
      setRole(userrole);
      setTokens({
        accessToken,
        idToken,
        userrole,
        region,
      });
    } catch (error) {
      console.log("Error refreshing token:", error);
    }
  };

  const getToken = async () => {
    try {
      const session = await Auth.currentSession();
      const userEmail = session.getIdToken().decodePayload().email;
      const accessToken = session.getAccessToken().getJwtToken();
      const idToken = session.getIdToken().getJwtToken();
      const refreshTokens = session.getRefreshToken().getToken();
      const decodedIdToken = jwtDecode(idToken);
      const region = decodedIdToken.iss.split(".")[1];
      console.log("Region:", region);
      const groups = decodedIdToken["cognito:roles"];
      const userRole = groups ? groups[0] : null;
      const finalRoles = userRole.split("/");
      console.log(finalRoles[1]);
      const userrole = finalRoles[1];
      setEmail(userEmail);
      setRole(userrole);
      setTokens({
        accessToken,
        idToken,
        userrole,
        refreshTokens,
        region,
      });

      // Set a timer to refresh the tokens before they expire
      const expiresAt = new Date(decodedIdToken.exp * 1000);
      const now = new Date();
      console.log(expiresAt);
      const refreshTime = expiresAt - now - 5 * 60 * 1000; // Refresh tokens 5 minutes before they expire
      setTimeout(() => {
        refreshToken();
        getCredentials();
      }, refreshTime);
    } catch (error) {
      console.log("Error retrieving token:", error);
    }
  };

  const getCredentials = async () => {
    try {
      const credentials = await Auth.currentCredentials();
      console.log(credentials);
      const accessKeyId = credentials.accessKeyId;
      const secretAccessKey = credentials.secretAccessKey;
      const sessionToken = credentials.sessionToken;
      setCred({
        accessKeyId,
        secretAccessKey,
        sessionToken,
      });
    } catch (error) {
      console.log("Error retrieving credentials:", error);
    }
  };

  useEffect(() => {
    if (user) {
      setUserName(user.username);
      getCredentials();
      getToken();
    }
  }, [user]);
  
  
  return (
    <div>
      <AuthContext.Provider value={{ cred, tokens, userName, email }}>
        <NavbarRoutes role={role} />

        <Header username={user.username} onLogout={signOut} />
      </AuthContext.Provider>
    </div>
  );
}

export default withAuthenticator(App, { hideSignUp: true });
