// CustomTooltip.js
import Tooltip from '@mui/material/Tooltip';
import { styled } from '@mui/material/styles';
import React from 'react';

const CustomTooltip = styled(({ className, ...props }) => (
  <Tooltip {...props} classes={{ popper: className }} placement="top" arrow />
))(({ theme }) => ({
  [`& .MuiTooltip-tooltip`]: {
    backgroundColor: 'black',
    color: 'white',
    boxShadow: theme.shadows[1],
    fontSize: 12,
    borderRadius: 5,
    padding: '10px 15px', // Increase padding to increase height
  },
  [`& .MuiTooltip-arrow`]: {
    color: 'black',
  },
}));

export default CustomTooltip;
