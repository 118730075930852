import React from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';
import '../pages/tenantOnboard/tenantData.css';
import { Paper } from '@mui/material';

export default function ErrorModal({openDialogError,onHandleCloseErrorDialog}) 
{
    const dialogStyle = {
        width: '350px',
        height: '230px',
      };

  return (
    <div>
      <Dialog 
        // sx={{backgroundColor: 'rgba(84, 84, 84, 0.8)'}}
        open={openDialogError}
        close={onHandleCloseErrorDialog}
        aria-labelledby="responsive-dialog-title"
      >
        <Paper style={dialogStyle}>
         <div className="success-icon-container">
          <ErrorOutlineIcon sx={{ color: "red", fontSize:"50px" }} />
        </div>
        <DialogContent style={{ justifyContent: 'center', display: 'flex', flexDirection: 'column' }}>  
           <h6>Failed to Perform the Requested Action</h6>
           <p style={{fontWeight:'bold', paddingLeft:'70px'}}>Please Try Again!!</p>      
        </DialogContent>
        <DialogActions style={{ justifyContent: 'center', display: 'flex' }}>
          <Button
            style={{
              backgroundColor: "#19172C",
            }}
            variant="contained"
            onClick={onHandleCloseErrorDialog}
          >
            OK
          </Button>
        </DialogActions>
        </Paper>
      </Dialog>
    </div>
  );
}