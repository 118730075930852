import { FormControl } from "@mui/material";
import { PieChart, pieArcLabelClasses } from '@mui/x-charts/PieChart';
import * as React from 'react';
import { useEffect, useState } from "react";
import Handler from "../../components/handler";
import useAxiosApiRequest from "../../hook/useAxiosApiRequest";
import './charts.css';

export function BasicBars() {
  const { statusCode, loading, apiRequest } = useAxiosApiRequest();
  const tenant = JSON.parse(localStorage.getItem('TenantID'));
  const [formData, setFormData] = useState({
    device_type: "",
  });
  const { device_type } = formData;
  const [deviceTypeData, setDeviceTypeData] = useState("");
  const [ deviceModels, setDeviceModels ] = useState([]);
  const [data, setData] = useState([]);

  const getDeviceType = async () => {
    const response = await apiRequest({
        endpoint:
          `/deviceservice/masterdata/deviceType`,
        method: "GET",
        additionalParams: {
          headers: {
            "tenant-id": tenant
          } 
        },
        body: {},
      })
          const Data = response?.deviceMasterData;
          console.log(Data);
          if(Array.isArray(Data)) {
            setDeviceTypeData(Data);
          }
  };

  const getDeviceModels = async(filterField,fieldValue) => {
    const [modelNames, deviceCount ] = await Promise.all([
      apiRequest({
        endpoint:
          `/deviceservice/devicemodel/allmodels/30/1/${filterField}/${fieldValue}/null/desc`,
        method: "GET",
        additionalParams: {
          headers: {
            "tenant-id": tenant
          } 
        },
        body: {},
      }),
      await apiRequest({
        endpoint:
          `/deviceservice/devicecount/modelName`,
        method: "GET",
        additionalParams: {
          headers: {
            "tenant-id": tenant
          } 
        },
        body: {},
      })
    ])
    const devices = deviceCount?.modelName;
    const pieChartData = modelNames?.deviceModels
      .filter((models) =>
      devices.some(
          (totalDevices) => totalDevices._id === models.modelName
        )
      )
      .map((models) => ({
        id: models._id,
        modelName: models.modelName,
        deviceCount:
        devices.find(
            (totalDevices) => totalDevices._id === models.modelName
          )?.count || 0,
      }))
    setData(pieChartData);
  }

  useEffect(() => {
    getDeviceType();
  },[]);

  useEffect(() => {
    if(data) {
          if (Array.isArray(data)) {
            const chartData = data?.map((model) => ({
              id: model?.id,
              value: model?.deviceCount,
              label: model?.modelName
            }));
            setDeviceModels(chartData);
          }
    }
  },[data]);

  const handleDeviceTypeChange = (e) => {
    setFormData({
      ...formData,
      [e.target.id]: e.target.value,
    });
    getDeviceModels("deviceType.name",e.target.value);
  };

  return (
    <Handler statusCode={statusCode} loading={loading} >
    <div style={{ border: '1px solid #000', width:'500px',borderRadius:'5px', padding: '20px', marginBottom: '20px' }}>
      <div className="dropdown">
      <h6> Active Devices</h6>
      <FormControl style={{ width: "250px" }} size="small">
              <select
                className="form-control"
                id="device_type"
                value={device_type}
                onChange={handleDeviceTypeChange}
                required
              >
                <option selected disabled value={""}>
                  Select Device Type
                </option>
                 {deviceTypeData && deviceTypeData.length &&
                  deviceTypeData.map((deviceData) => (
                    <option key={deviceData.id}>
                      {deviceData.name}
                    </option>
                  ))}
              </select>
            </FormControl>
      </div>
      <div>
      {deviceModels && deviceModels.length ? (
        <PieChart
        margin={{ right: 200 }}
        slotProps={{
          legend: {
            direction: 'column',
            position: { vertical: 'middle', horizontal: 'right' },
            padding: -15,
          },
        }}
        series={[
          {
            arcLabel: (item) => `(${item.value})`,
            // arcLabelMinAngle: 45,
            data:deviceModels
          },
          // {
          //   highlightScope: { faded: 'global', highlighted: 'item' },
          // faded: { innerRadius: 30, additionalRadius: -30, color: 'gray' },
          // },
        ]}
        sx={{
          [`& .${pieArcLabelClasses.root}`]: {
            fill: 'white',
            fontWeight: 'bold',
          },
        }}
        colors={['#49abc8', '#004969', '#00628e', '#266774', '#8884d8']}
        width={400}
        height={200}
      />
      ):(
        <div style={{width:'400px', height:'200px'}}>
          <p style={{display:'flex', justifyContent:'center',fontWeight:'bold',position:'relative'}}>Select DeviceType to Display Data </p>
        </div>
      )}
      </div>
    </div>
    </Handler>
  );
}
