import React from "react";
import UserRole from "./userRole";

const CustomButton = ({ onClick, children }) => {
  const { isAdmin } = UserRole();
  return (
    <div>
      {isAdmin ? (
        <button
          type="submit"
          className="btn btn-dark"
          style={{
            minWidth: "30%",
            margin: "auto",
            display: "block",
            marginBottom: "16px",
            padding: "12px 20px",
          }}
          onClick={onClick}
        >
          {children}
        </button>
      ) : null}
    </div>
  );
};

export default CustomButton;
