import "bootstrap/dist/css/bootstrap.min.css";
import React, { useContext, useEffect, useState } from "react";

import { AuthContext } from "../../App";
import Breadcrumb from "../../breadCrumbs/navigation";
import Handler from "../../components/handler";
import useAxiosApiRequest from "../../hook/useAxiosApiRequest";
import SuccessCreateModal from "../../modals/successModalCreate";
import "./createDeviceModel.css";

export default function AddDeviceModel() {
  const { statusCode, errormsg, loading, apiRequest } = useAxiosApiRequest();
  const { userName } = useContext(AuthContext);

  const tenantID = localStorage.getItem("TenantID");
  const tenant = tenantID ? JSON.parse(tenantID) : null;

  const [deviceTypes, setDeviceTypes] = useState([]);
  const [openDialog, setOpenDialog] = useState(false); //success dialog
  const [imageData, setImageData] = useState({}); //stores image details uploaded by user
  const [deviceTypeData, setDeviceTypeData] = useState({});
  const [isFormValid, setIsFormValid] = useState(false);
  const [modelNunmberError, setModelNumberError] = useState("");
  const [modelNameError, setModelNameError] = useState("");
  const [errorMsg, setErrorMsg] = useState(false);
  const [isSuccess, setIsSuccess] = useState(false);
  const [fieldErrors, setFieldErrors] = useState({});

  //initializing state with required fields for device model creation
  const [formData, updateFormData] = useState({
    deviceType: "",
    modelName: "",
    modelNumber: "",
    description: "",
    model_image: null,
  });

  useEffect(() => {
    getDeviceType();
  }, []);

  useEffect(() => {
    setIsFormValid(!modelNameError && !modelNunmberError && isSuccess);
  }, [modelNameError, modelNunmberError, errorMsg]);

  const { deviceType, modelNumber, modelName, description, model_image } =
    formData || {};

  const deviceModelData = {
    deviceType: deviceTypeData,
    modelName: modelName,
    modelNumber: modelNumber,
    description: description,
    modelImage: imageData,
    createdBy: userName,
    updatedBy: userName,
    modelStatus: "ACTIVE",
    tenantId: tenant,
  };

  const onHandleCloseDialog = () => {
    setOpenDialog(false);
  };

  //mandatory field validation
  const validateForm = () => {
    const newErrors = {};
    if (!formData.deviceType?.trim()) {
      newErrors.deviceType = "Select Device Type";
    }
    if (!formData.modelNumber?.trim()) {
      newErrors.modelNumber = "Enter Model Number";
    }
    if (!formData.modelName?.trim()) {
      newErrors.modelName = "Enter Model Name";
    }
    if (model_image === null) {
      newErrors.model_image = "Image upload is required";
    }
    setFieldErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  //user input validations
  const onHandleFileChange = (e) => {
    updateFormData({
      ...formData,
      [e.target.id]: e.target.value,
    });
    const image = e.target.files[0];
    const fileName = e.target.files[0].name;
    if (image) {
      const selectedFileSize = e.target.files[0].size;
      const reader = new FileReader();
      reader.readAsDataURL(image);
      reader.onloadend = () => {
        const baseImage = reader?.result;
        validateSelectedFile(selectedFileSize, fileName, baseImage);
      };
    }
    setFieldErrors({
      ...fieldErrors,
      [e.currentTarget.id]: "",
    });
  };

  const validateSelectedFile = (fileSize, fileName, baseImage) => {
    // const MIN_FILE_SIZE = 10 // 1MB
    const MAX_FILE_SIZE = 5 * 1024; //5mb in bytes
    const allowedFormats = ["jpeg", "png", "jpg", "svg"];
    const fileSizeKiloBytes = fileSize / 1024;
    if (fileSizeKiloBytes > MAX_FILE_SIZE) {
      setErrorMsg("File size is greater than maximum limit");
      setIsSuccess(false);
      return;
    }

    const fileExtension = fileName.split(".").pop().toLowerCase();
    if (!allowedFormats.includes(fileExtension)) {
      setErrorMsg(
        "File format is not supported. Upload files of only above mentioned formats.",
      );
      setIsSuccess(false);
      return;
    } else {
      setIsSuccess("File size and format is validated");
      setErrorMsg(true);
      const imageData = {
        fileName: fileName,
        fileSize: fileSize.toString(),
        imageFile: baseImage,
        fileExtension: fileExtension,
      };
      setImageData(imageData);
    }
  };

  const onHandleChange = (e) => {
    updateFormData({
      ...formData,
      [e.target.id]: e.target.value,
    });
    setFieldErrors({
      ...fieldErrors,
      [e.currentTarget.id]: "",
    });
  };

  const onHandleDeviceTypeChange = (e) => {
    updateFormData({
      ...formData,
      [e.target.id]: e.target.value,
    });
    const selectedDeviceType = e.target.value;
    const selectedDeviceTypeDescription = deviceTypes.find(
      (desc) => desc.name === selectedDeviceType,
    );
    if (selectedDeviceTypeDescription) {
      const description = selectedDeviceTypeDescription?.description;
      const deviceIDType = selectedDeviceTypeDescription?.deviceIdType;
      const deviceData = {
        name: selectedDeviceType,
        description: description,
        deviceIdType: deviceIDType,
      };
      setDeviceTypeData(deviceData);
    }
    setFieldErrors({
      ...fieldErrors,
      [e.currentTarget.id]: "",
    });
  };

  const onHandleModelNameChange = (e) => {
    const { value } = e.target;
    const regex = /^[a-zA-Z0-9_]+$/;
    if (regex.test(value)) {
      setModelNameError("");
    } else {
      setModelNameError("Special characters are not allowed for model name");
    }
    updateFormData({
      ...formData,
      [e.target.id]: e.target.value,
    });
    setFieldErrors({
      ...fieldErrors,
      [e.currentTarget.id]: "",
    });
  };

  const onHandleModelNumberChange = (e) => {
    const { value } = e.target;
    const regex = /^[a-zA-Z0-9_]+$/;
    if (regex.test(value)) {
      setModelNumberError("");
    } else {
      setModelNumberError(
        "Special characters are not allowed for model number",
      );
    }
    updateFormData({
      ...formData,
      [e.target.id]: e.target.value,
    });
    setFieldErrors({
      ...fieldErrors,
      [e.currentTarget.id]: "",
    });
  };

  const getDeviceType = async () => {
    const response = await apiRequest({
      endpoint: `/deviceservice/masterdata/deviceType`,
      method: "GET",
      additionalParams: {
        headers: {
          "tenant-id": tenant,
        },
      },
      body: {},
    });
    const Data = response?.deviceMasterData;
    if (Array.isArray(Data)) {
      setDeviceTypes(Data);
    }
  };

  //sending post request for creation of device model
  const submitDeviceModel = async () => {
    const response = await apiRequest({
      endpoint: `/deviceservice/devicemodel`,
      method: "POST",
      additionalParams: {
        headers: {
          "tenant-id": tenant,
        },
      },
      body: deviceModelData,
    });
    if (response && statusCode === 200) {
      setOpenDialog(true);
    }
  };

  const onHandleSubmit = (e) => {
    e.preventDefault();
    if (validateForm()) {
      submitDeviceModel();
      updateFormData({
        deviceType: "",
        modelName: "",
        modelNumber: "",
        description: "",
        model_image: "",
      });
      setIsSuccess("");
    }
  };

  return (
    <Handler statusCode={statusCode} loading={loading} errormsg={errormsg}>
      <div>
        <Breadcrumb />
        {openDialog && (
          <SuccessCreateModal
            openDialog={openDialog}
            onHandleClose={onHandleCloseDialog}
          />
        )}
        <h4 className="createVehicleHeading">Add New Device Model</h4>
        <div className="createvehicle">
          {/* form for getting user inputs */}
          <form className="mt-3 mb-3" onSubmit={(e) => onHandleSubmit(e)}>
            <div className="col-sm-8 mb-2">
              <label id="mandatorylabels">Select Device Type</label>
              <select
                className="form-control"
                id="deviceType"
                value={deviceType}
                onChange={onHandleDeviceTypeChange}
              >
                <option selected disabled value={""}>
                  Select Device Type
                </option>
                {deviceTypes &&
                  deviceTypes.map((getDeviceName) => (
                    <option key={getDeviceName.id}>{getDeviceName.name}</option>
                  ))}
              </select>
              {fieldErrors.deviceType && (
                <p className="error-message">{fieldErrors.deviceType}</p>
              )}
            </div>
            <div className="col-sm-8 mb-2">
              <label id="mandatorylabels">Model Name</label>
              <input
                type="string"
                className="form-control"
                id="modelName"
                value={modelName}
                placeholder="Enter Model Name"
                maxLength={100}
                onChange={onHandleModelNameChange}
              />
              {modelNameError && (
                <div className="error-message">{modelNameError}</div>
              )}
              {fieldErrors && fieldErrors.modelName && (
                <p className="error-message">{fieldErrors.modelName}</p>
              )}
            </div>
            <div className="col-sm-8 mb-2">
              <label id="mandatorylabels">Model Number</label>
              <input
                type="string"
                className="form-control"
                value={modelNumber}
                id="modelNumber"
                placeholder="Enter Model Number"
                maxLength={100}
                onChange={onHandleModelNumberChange}
              />
              {modelNunmberError && (
                <div className="error-message">{modelNunmberError}</div>
              )}
              {fieldErrors.modelNumber && (
                <p className="error-message">{fieldErrors.modelNumber}</p>
              )}
            </div>
            <div className="col-sm-8 mb-2">
              <label>Description</label>
              <textarea
                className="form-control"
                placeholder="Enter Description"
                value={description}
                id="description"
                maxLength={1000}
                rows={3}
                onChange={onHandleChange}
              />
              {fieldErrors && fieldErrors.description && (
                <p className="error-message">{fieldErrors.description}</p>
              )}
            </div>
            <div className="col-sm-8 mb-2">
              <label id="mandatorylabels">Upload Image</label>
              <div className="col-sm-12">
                <input
                  type="file"
                  className="form-control"
                  value={model_image}
                  id="model_image"
                  // accept="application/gzip"
                  onChange={onHandleFileChange}
                />
                <small id="passwordHelpBlock" className="form-text text-muted">
                  Please Upload File of Format JPEG/JPG, PNG, SVG and File Size
                  Below 5MB
                </small>
                {fieldErrors && fieldErrors.model_image && (
                  <p className="error-message">{fieldErrors.model_image}</p>
                )}
                {errorMsg && <p className="error-message">{errorMsg}</p>}
                {isSuccess && <p className="success-message">{isSuccess}</p>}
                <br />
                {/* <p style={{color:'red'}}>{fileErrorMessage}</p> */}
              </div>
            </div>
            <div className="form-group row">
              <div className="col-sm-12">
                <button
                  disabled={!isFormValid}
                  type="submit"
                  className="btn btn-dark btn-block"
                >
                  Submit
                </button>
              </div>
            </div>
          </form>
        </div>
      </div>
    </Handler>
  );
}
