import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import ArrowDropUpIcon from "@mui/icons-material/ArrowDropUp";
import ReplayIcon from "@mui/icons-material/Replay";
import SearchIcon from "@mui/icons-material/Search";
import VisibilityIcon from "@mui/icons-material/Visibility";
import EditIcon from "@mui/icons-material/Edit";
import { FormControl } from "@mui/material";
import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import * as React from "react";
import { useContext, useEffect, useState } from "react";
import ClipLoader from "react-spinners/ClipLoader";

import log from "loglevel";
import { FaBackward, FaForward } from "react-icons/fa";
import ReactPaginate from "react-paginate";
import { AuthContext } from "../../App";
import BasicModal from "../../modals/basicModal";
import UsrConfigErrorModal from "../../modals/userConfigError";
import "../deviceModel/viewDeviceModel.css";
import ViewDeviceData from "./viewDeviceData";

import Handler from "../../components/handler";
import useAxiosApiRequest from "../../hook/useAxiosApiRequest";
import { useNavigate } from "react-router-dom";

var apigClientFactory = require("aws-api-gateway-client").default;

export default function Getuser() {
  const { statusCode, errormsg, loading, apiRequest } = useAxiosApiRequest();
  const navigate = useNavigate();
  //tokens and user role should be imported for usage in api calls
  const { cred } = useContext(AuthContext);
  const { tokens } = useContext(AuthContext);
  const awsKey = cred.accessKeyId;
  const awsSecret = cred.secretAccessKey;
  const sessionToken = cred.sessionToken;
  const awsRegion = tokens.region;
  const CognitoRole = tokens.userrole;
  const CURRENT_USER_TYPE = CognitoRole;
  const superAdminRole = "superadminrole";
  const adminrole = "adminrole";
  const analystrole = "analystrole";

  const config = {
    invokeUrl: process.env.REACT_APP_SERVICE_URL_v2,
    region: awsRegion,
    accessKey: awsKey,
    secretKey: awsSecret,
    sessionToken: sessionToken,
  };

  const apigClient = apigClientFactory.newClient(config);
  const defaultTenantId = process.env.REACT_APP_USER_ROLE;
  const defaultRecords = process.env.REACT_APP_DEFAULT_RECORDS;
  const [dataLength, setDataLength] = useState(0);
  const [loadingIcon, setLoadingIcon] = useState(false);
  const [tenantData, setTenantData] = useState([]); //stores tenant data list incase of superadmin role
  const [userRole, setUserRole] = useState([]); //stores selected user role from the dropdown

  //when user clicks on any tenant name from dropdown, the tenant id of that selected tenant will be stored incase of superadmin
  //for admin it will be set from the configuration stored in local storage
  const [selectedTenantID, setSelectedTenantID] = useState(null);
  const [selectedUserRole, setSelectedUserRole] = useState(null);
  //for superadmin -- an api call will be sent to get userpoolid whenever user selects tenant name
  //for admin -- it will be set from the user information stored in local storage
  const [userPoolId, setUserPoolId] = useState("");
  const [formData, setFormData] = useState({
    tenant_name: "",
    user_role: "",
    auth_source: "",
    user_status: "",
  });
  const { tenant_name, user_role, auth_source, user_status } = formData;

  const [userListPaginationToken, setUerListPaginationToken] = useState(null);
  // eslint-disable-next-line no-unused-vars
  const [rolePaginationToken, setRolePaginationToken] = useState(null);
  //whenever user selects the tenant name from the dropdown, we are setting the tenant id of that selected tenant
  //this tenant id we will get from the data from the api call response
  //dispalying error dialog if the tenant id of that selected tenant name is not found in tenant data
  const handleTenantNameChange = (e) => {
    setFormData({
      ...formData,
      [e.target.id]: e.target.value,
    });
    const selectedTenantName = e.target.value;
    const selectedTenant = tenantData.find(
      (tenantData) => tenantData?.tenantName === selectedTenantName,
    );
    if (selectedTenant) {
      setSelectedTenantID(selectedTenant.tenantID);
    } else {
      setOpen(true); //opens dialog
      setChildren("Internal Server Error");
    }
  };

  const handleRoleChange = (e) => {
    setFormData({
      ...formData,
      [e.target.id]: e.target.value,
    });
    setSelectedUserRole(e.target.value);
    if (userListPaginationToken) {
      setUerListPaginationToken(null);
    }
  };

  //state variable for storing the user list which we got as response from api call to dispaly in table
  const [user, setuser] = useState([]);
  //state variable for handling search operation
  const [value, setvalue] = useState("");

  //pagination for the table
  const [pageNumber, setPagenumber] = useState(1);

  const userPerpage = 20; //numbers of records which we want to display per page

  const [selectedUserDeviceData, setSelectedUserDeviceData] = useState([]);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [totalRecords, setTotalRecords] = useState(0);
  const handleOpenModal = (userList) => {
    setSelectedUserDeviceData(userList);
    setIsModalOpen(true);
  };
  const handleEditPermission = (userName) => {
    navigate(`/user/editUser/${userName}`);
  };

  const hanldeCloseDeviceDataModal = () => {
    setIsModalOpen(false);
  };

  const [open, setOpen] = useState(false); //state variable for dispalying the dafult dialog box
  const [children, setChildren] = useState(""); //here we can pass the error message which we want to display in the dialog

  const onHandleClose = () => {
    setOpen(false); //closes dialog
  };

  // api call which will be sent for getting the tenant list in tenant name dropdown
  // this api call will be sent only if the user role is superadmin
  // here we are calling handleApiCall function and displaying the error dialog incase of error Response
  // passing method, api url and headers - in headers for now we need to pass some uuid as tenant id as the api
  // is expecting tenant id in headers for this apis and since superadmin will not have any tenant id we are passing
  // one uuid and the same uuidshould be used here and in backend
  const getTenantList = async () => {
    const pathTemplate1 = "/tenantservice/tenantrequest";
    const pathTemplate2 = "/tenantservice/tenant";
    const method = "GET";
    const pathParams = {
      //This is where path request params go.
      //userId: '1234',
    };
    const additionalParams = {
      headers: {
        "Content-Type": "application/json",
        "tenant-id": defaultTenantId,
      },
    };

    const body = {};

    try {
      setLoadingIcon(true);
      await apigClient
        .invokeApi(pathParams, pathTemplate1, method, additionalParams, body)
        .then(async (resp) => {
          const Res = resp?.data;
          await apigClient
            .invokeApi(
              pathParams,
              pathTemplate2,
              method,
              additionalParams,
              body,
            )
            .then((resp2) => {
              const Res2 = resp2?.data;
              if (Res && Res2) {
                const combinedData = Res.map((request) => ({
                  status: request.status,
                  tenantName: Res2.find(
                    (tenant) =>
                      tenant.tenantStagingId === request.tenantStagingId,
                  ).tenantName,
                  tenantID: Res2.find(
                    (tenant) =>
                      tenant.tenantStagingId === request.tenantStagingId,
                  ).tenantId,
                }));
                const filteredData = combinedData.filter(
                  (item) => item.status === "S",
                );
                setTenantData(filteredData);
                setLoadingIcon(false);
              }
            })
            .catch((error) => {
              setLoadingIcon(false);
              setOpen(true);
              setChildren("Error fetching tenant Data");
              log.error("Error fetching tenant Data", error);
            });
        })
        .catch((error) => {
          setLoadingIcon(false);
          setOpen(true);
          setChildren("Error fetching tenant Data");
          log.error("Error fetching tenant Data", error);
        });
    } catch (error) {
      setLoadingIcon(false);
      setOpen(true);
      setChildren("Error fetching tenant Data");
      log.error("Error fetching tenant Data", error);
    }
  };

  //this useeffect will run only for one time when the component mounts
  //this will fetch the tenant list if user is superadmin
  useEffect(() => {
    if (CURRENT_USER_TYPE.toLowerCase() === superAdminRole) {
      getTenantList();
    }
  }, []);

  // after user selects the tenant name from the tenant dropdown, an api call will be sent to get the userpoolid
  // of that selected tenant as we need userpool id for getting the user roles related to that selected
  // tenant userpool and displaying error in dialog if we get error response
  const GetTenantDataMongo = async () => {
    const pathParams = {};
    const pathTemplate = "/mongoservice/";
    const method = "GET";
    const additionalParams = {
      headers: {
        "tenant-id": selectedTenantID,
      },
    };
    const body = {};

    try {
      setLoadingIcon(true);
      await apigClient
        .invokeApi(pathParams, pathTemplate, method, additionalParams, body)
        .then((resp) => {
          const userpoolid = resp?.data[0]?.userPoolId;
          setUserPoolId(userpoolid);
          setLoadingIcon(false);
        });
    } catch (err) {
      setLoadingIcon(false);
      setOpen(true);
      setChildren("Internal Server Error");
      log.error("Error getting data from mongodb", err);
    }
  };

  // this useEffect will be run if the user is superadmin
  // and whenver user selects/changes the tenant name
  useEffect(() => {
    if (
      CURRENT_USER_TYPE.toLowerCase() === superAdminRole &&
      selectedTenantID
    ) {
      GetTenantDataMongo();
    }
  }, [selectedTenantID]);

  //this api call will be sent for both admin and superadmin roles
  //for superadmin
  //when the user selcts the tenant name and after getting userpoolid
  //then the api call will be sent to fetch the user roles
  //for admin
  //if the user role is admin we will get the userpoolid from local storage and this api call will be sent
  const getUserRoles = async () => {
    const response = await apiRequest({
      endpoint: `/cognitoservice/userpool/group/${defaultRecords}`,
      method: "GET",
      additionalParams: {
        headers: {
          "userpool-id": userPoolId,
          "next-token": rolePaginationToken || "null",
        },
      },
      body: {},
    });
    const Data = response?.Groups;
    // const paginationToken = response?.NextToken;
    if (Data) {
      // setRolePaginationToken(paginationToken);
      setUserRole(Data);
    }
  };

  //if the user is superadmin this will be run when user selects tenant name and userrole
  useEffect(() => {
    if (
      CURRENT_USER_TYPE.toLowerCase() === superAdminRole &&
      selectedTenantID &&
      selectedUserRole
    ) {
      getuser(1, "null", "null", "null", "null", "desc");
    }
  }, [selectedTenantID, selectedUserRole]);

  //////Admin role - all thses functions are specifically used for admin role only

  //state varible for managing error dialog dispaly incase any user info is missed from local storage
  const [userConfigErrorDialog, setUserConfigErrorDialog] = useState(false);
  const onHandleCloseUserConfigErrorDialog = () => {
    setUserConfigErrorDialog(false);
  };

  //the userpoolid and tenant id will be fetched from local storage and set to the respective variables
  //which will be used in api call for getting users list
  //diaplays an error dialog if any user info is not found in local storage
  const getUserPoolConfig = () => {
    try {
      const storedTenantId = localStorage.getItem("TenantID");
      const tenantId = storedTenantId ? JSON.parse(storedTenantId) : null;
      if (tenantId) {
        setSelectedTenantID(tenantId);
      } else {
        throw new Error("User ID not found in local storage");
      }
      const storedAmplifyConfig = localStorage.getItem("amplifyConfig");
      const amplifyConfig = storedAmplifyConfig
        ? JSON.parse(storedAmplifyConfig)
        : null;
      const UserPoolIDConfig = amplifyConfig.aws_user_pools_id;
      if (UserPoolIDConfig) {
        setUserPoolId(UserPoolIDConfig);
      } else {
        throw new Error("Userpool ID not found in local storage");
      }
    } catch (error) {
      console.error(error);
      setUserConfigErrorDialog(true);
    }
  };

  //this will be run if the user role is admin
  useEffect(() => {
    if (CURRENT_USER_TYPE.toLowerCase() === adminrole) {
      getUserPoolConfig();
    }
  }, []);

  useEffect(() => {
    if (CURRENT_USER_TYPE.toLowerCase() === analystrole) {
      getUserPoolConfig();
    }
  }, []);

  //this useeffect is for the api call for getting user roles if userpoolid is set
  useEffect(() => {
    if (userPoolId) {
      getUserRoles();
    }
  }, [userPoolId]);

  //if admin user selcts the user role then this use effect will be run
  useEffect(() => {
    if (CURRENT_USER_TYPE.toLowerCase() === adminrole && selectedUserRole) {
      getuser(1, "null", "null", "null", "null", "desc");
    }
  }, [selectedUserRole]);

  useEffect(() => {
    if (CURRENT_USER_TYPE.toLowerCase() === analystrole && selectedUserRole) {
      getuser(1, "null", "null", "null", "null", "desc");
    }
  }, [selectedUserRole]);

  const userStatus = {
    RESET_REQUIRED: "Reset Required",
    UNCONFIRMED: "Unconfirmed",
    ARCHIVED: "Archived",
    COMPROMISED: "Compromised",
    UNKNOWN: "Unknown",
    FORCE_CHANGE_PASSWORD: "Force Change Password",
    CONFIRMED: "Confirmed",
    EXTERNAL_PROVIDER: "External Provider",
  };

  //final api call for getting the user list based on the selected tenantname and role
  //or selected role in case of admin
  const getuser = async (
    pageNumber,
    filterField,
    filterValue,
    searchValue,
    sortField,
    sortorder,
  ) => {
    const response = await apiRequest({
      endpoint: `/cognitoservice/userprofileinfo/allUsers/${selectedUserRole}/${userPerpage}/${pageNumber}/${filterField}/${filterValue}/${searchValue}/${sortField}/${sortorder}`,
      method: "GET",
      additionalParams: {
        headers: {
          "tenant-id": selectedTenantID,
        },
      },
      body: {},
    });
    const Data = response?.userProfileDetails;
    const totalRecords = response?.totalPageCount;
    const length = response?.totalDocumentCount;
    if (Array.isArray(Data)) {
      setuser(Data);
      setTotalRecords(totalRecords);
      setDataLength(length);
    }
  };

  const [userAuthSource, setUserAuthSource] = useState([]);
  const getuserdetails = async () => {
    const response = await apiRequest({
      endpoint: `/cognitoservice/userprofileinfo/allUsers/MobileUserGroup/60/1/null/null/null/null/null/`,
      method: "GET",
      additionalParams: {
        headers: {
          "tenant-id": selectedTenantID,
        },
      },
      body: {},
    });
    const Data = response?.userProfileDetails;
    if (Array.isArray(Data)) {
      setUserAuthSource(Data);
    }
  };

  useEffect(() => {
    if (selectedUserRole) {
      getuserdetails();
    }
  }, [selectedUserRole]);

  const handleSelectAuthSource = (e) => {
    setFormData({
      ...formData,
      [e.target.id]: e.target.value,
    });
    getuser(1, "userAuthSource", e.target.value, "null", "null", "desc");
  };

  const handleSelectUserStatus = (e) => {
    setFormData({
      ...formData,
      [e.target.id]: e.target.value,
    });
    getuser(1, "cognitoUserStatus", e.target.value, "null", "null", "desc");
  };

  //if admin user selcts the user role then this use effect will be run
  useEffect(() => {
    if (
      CURRENT_USER_TYPE.toLowerCase() === (adminrole || analystrole) &&
      selectedUserRole
    ) {
      getuser(1, "null", "null", "null", "null", "desc");
    }
  }, [selectedUserRole]);

  const handleResetSearch = () => {
    setvalue("");
    setFormData({
      tenant_name: "",
      user_role: "",
      auth_source: "",
      user_status: "",
    });
    getuser(1, "null", "null", "null", "null", "desc");
  };

  function convertUTCDateToLocalDate(val) {
    var dateLocal = new Date(val);
    var timestamp = new Date(
      dateLocal.getTime() - dateLocal.getTimezoneOffset(),
    );
    var result = timestamp.toLocaleString();
    return result;
  }

  const handleInputChange = (e) => {
    setvalue(e.target.value);
  };

  const handleSearch = () => {
    getuser(1, "null", "null", value, "null", "desc");
  };

  const [sortField, setSortField] = useState("null");
  const [sortOrder, setSortOrder] = useState("desc");

  const handleSort = (field) => {
    let newOrder = "asc";
    if (sortField === field && sortOrder === "asc") {
      newOrder = "desc";
    }
    setSortField(field);
    setSortOrder(newOrder);
    getuser(pageNumber, "null", "null", "null", field, newOrder);
  };

  const changePage = async (selected) => {
    const page = selected?.nextSelectedPage;
    if (page !== undefined) {
      setPagenumber(page + 1);
      getuser(page + 1, "null", "null", "null", sortField, sortOrder);
    }
  };

  return (
    <div>
      <Handler statusCode={statusCode} loading={loading} errormsg={errormsg} />
      {loadingIcon && <div className="spinner-overlay"></div>}
      <div className="spinner-container">
        {loadingIcon && <ClipLoader color="#000000" size={50} />}
      </div>
      {isModalOpen && (
        <ViewDeviceData
          open={isModalOpen}
          deviceData={selectedUserDeviceData}
          handleClose={hanldeCloseDeviceDataModal}
        />
      )}
      {userConfigErrorDialog && (
        <UsrConfigErrorModal
          openDialogError={userConfigErrorDialog}
          onHandleCloseErrorDialog={onHandleCloseUserConfigErrorDialog}
        />
      )}
      {open && (
        <BasicModal open={open} handleClose={onHandleClose}>
          <p>{children}</p>
        </BasicModal>
      )}
      <div className="heading">
        <h5>User List</h5>
        <div
          style={{ display: "flex", alignItems: "center", maxWidth: "500px" }}
        >
          <div style={{ position: "relative", flex: 1 }}>
            <input
              style={{
                // flex: "1",
                maxWidth: "100%",
                border: "1px solid #080808",
                borderRadius: "5px 0 0 5px",
                padding: "5px 35px 5px 35px",
              }}
              type="text"
              className="form-control"
              placeholder="Search Username or ID..."
              value={value}
              onChange={handleInputChange}
            />
            <span
              style={{
                position: "absolute",
                left: "10px",
                top: "50%",
                transform: "translateY(-50%)",
                cursor: "pointer",
              }}
              // onClick={handleSearch}
            >
              <SearchIcon />
            </span>
          </div>
          <button
            style={{
              marginRight: "10px",
              padding: "5px 10px",
              border: "1px solid #080808",
              backgroundColor: "#080808",
              color: "#ffffff",
              borderRadius: "0 5px 5px 0",
            }}
            onClick={handleSearch}
          >
            Search
          </button>
          <button className="resetButton" onClick={handleResetSearch}>
            <ReplayIcon titleAccess="Reset Search/Filters" />
          </button>
        </div>
      </div>
      <br />
      <Paper
        elevation={4}
        style={{
          padding: "20px",
          border: "1px solid #ccc",
          marginBottom: "20px",
        }}
      >
        <div>
          <h6 style={{ fontWeight: "bold", marginBottom: "20px" }}>
            Select Filters To Display Data
          </h6>
        </div>
        <div
          style={{
            display: "flex",
            flexWrap: "wrap",
            gap: "20px",
            alignItems: "center",
          }}
        >
          <div>
            {CURRENT_USER_TYPE &&
              CURRENT_USER_TYPE.toLowerCase() === superAdminRole && (
                <FormControl style={{ width: "250px" }} size="small">
                  <select
                    className="form-control"
                    id="tenant_name"
                    value={tenant_name}
                    required
                    onChange={handleTenantNameChange}
                  >
                    <option disabled selected value={""}>
                      Select Tenant Name
                    </option>
                    {tenantData &&
                      tenantData.map((getTenantName) => (
                        <option key={getTenantName.id}>
                          {getTenantName.tenantName}
                        </option>
                      ))}
                  </select>
                </FormControl>
              )}
          </div>
          <div>
            <FormControl style={{ width: "250px" }} size="small">
              <select
                className="form-control"
                id="user_role"
                value={user_role}
                onChange={handleRoleChange}
                required
              >
                <option selected disabled value={""}>
                  Select User Role
                </option>
                {userRole &&
                  userRole.map((getUserRole) => (
                    <option key={getUserRole.id}>
                      {getUserRole.GroupName}
                    </option>
                  ))}
              </select>
            </FormControl>
          </div>
          <div>
            {selectedUserRole && selectedUserRole === "MobileUserGroup" && (
              <FormControl style={{ width: "250px" }} size="small">
                <select
                  className="form-control"
                  id="auth_source"
                  value={auth_source}
                  onChange={handleSelectAuthSource}
                  required
                >
                  <option selected disabled value={""}>
                    Select Authentication Source
                  </option>
                  {userAuthSource &&
                    userAuthSource.map((getAuthSource) => (
                      <option key={getAuthSource.id}>
                        {getAuthSource.userAttributes?.userAuthSource}
                      </option>
                    ))}
                </select>
              </FormControl>
            )}
          </div>
          <div>
            {selectedUserRole && (
              <FormControl style={{ width: "250px" }} size="small">
                <select
                  className="form-control"
                  id="user_status"
                  value={user_status}
                  onChange={handleSelectUserStatus}
                  required
                >
                  <option selected disabled value={""}>
                    Select User Status
                  </option>
                  {userAuthSource &&
                    userAuthSource.map((getAuthSource) => (
                      <option key={getAuthSource.id}>
                        {getAuthSource.userAttributes?.cognitoUserStatus}
                      </option>
                    ))}
                </select>
              </FormControl>
            )}
          </div>
        </div>
      </Paper>
      <Paper sx={{ width: "100%", overflow: "hidden" }}>
        <TableContainer sx={{ maxHeight: 440 }}>
          <Table stickyHeader aria-label="sticky table">
            <TableHead sx={{ maxHeight: 20 }}>
              <TableRow>
                {/* <TableCell sx={{ fontWeight: 700 }}>Sl.No</TableCell> */}
                <TableCell sx={{ fontWeight: 700 }}>User Name</TableCell>
                <TableCell sx={{ fontWeight: 700 }}>User ID</TableCell>
                <TableCell sx={{ fontWeight: 700 }}>ID Verified</TableCell>
                <TableCell sx={{ fontWeight: 700 }}>User Status</TableCell>
                <TableCell sx={{ fontWeight: 700 }}>Account Verified</TableCell>
                <TableCell sx={{ fontWeight: 700 }}>
                  Authentication Source
                </TableCell>
                <TableCell
                  sx={{
                    fontWeight: 700,
                    whiteSpace: "nowrap",
                    overflow: "hidden",
                    textOverflow: "ellipsis",
                  }}
                  align="center"
                  onClick={() => handleSort("createdAt")}
                >
                  Created At
                  {sortField === "createdAt" && sortOrder === "asc" ? (
                    <ArrowDropUpIcon />
                  ) : (
                    <ArrowDropDownIcon />
                  )}
                </TableCell>
                <TableCell
                  sx={{
                    fontWeight: 700,
                    whiteSpace: "nowrap",
                    overflow: "hidden",
                    textOverflow: "ellipsis",
                  }}
                  align="center"
                  onClick={() => handleSort("updatedAt")}
                >
                  Updated At
                  {sortField === "updatedAt" && sortOrder === "asc" ? (
                    <ArrowDropUpIcon />
                  ) : (
                    <ArrowDropDownIcon />
                  )}
                </TableCell>
                {selectedUserRole && selectedUserRole === "MobileUserGroup" ? (
                  <TableCell sx={{ fontWeight: 700 }}>Devices</TableCell>
                ) : null}
                {selectedUserRole &&
                selectedUserRole.toLowerCase() === "admin" ? (
                  <TableCell sx={{ fontWeight: 700 }}>Devices</TableCell>
                ) : null}
              </TableRow>
            </TableHead>
            <TableBody>
              {user &&
                user.map((userList, index) => {
                  return (
                    <TableRow
                      style={
                        index % 2
                          ? { backgroundColor: "rgba(72, 34, 180, 0.04)" }
                          : { backgroundColor: "white" }
                      }
                      hover
                      role="checkbox"
                      tabIndex={-1}
                      key={userList.id}
                    >
                      <TableCell align="center">
                        {userList.userAttributes?.userName || "-"}
                      </TableCell>
                      <TableCell align="center">
                        {userList.userAttributes?.userAuthSource === "Google"
                          ? userList.userAttributes?.email
                          : userList.userAttributes?.userAuthSource === "OTP"
                            ? userList.userAttributes?.phoneNumber
                            : userList.userAttributes?.userAuthSource ===
                                "Web-app"
                              ? userList.userAttributes?.email
                              : "-"}
                      </TableCell>
                      <TableCell align="center">
                        {userList.userAttributes?.phoneNumberVerified === true
                          ? "Yes"
                          : // eslint-disable-next-line no-constant-binary-expression, no-constant-condition
                            "No" ||
                              userList.userAttributes?.emailVerified === true
                            ? "Yes"
                            : // eslint-disable-next-line no-constant-binary-expression
                              "No" || "-"}
                      </TableCell>
                      <TableCell align="center">
                        {userStatus[
                          userList.userAttributes?.cognitoUserStatus
                        ] || "-"}
                      </TableCell>
                      <TableCell align="center">
                        {userList.userAttributes?.enabled === true
                          ? "Yes"
                          : // eslint-disable-next-line no-constant-condition, no-constant-binary-expression
                            "No" ||
                              userList.userAttributes?.accountConfirmed === true
                            ? "Yes"
                            : // eslint-disable-next-line no-constant-binary-expression
                              "No" || "-"}
                      </TableCell>
                      <TableCell align="center">
                        {userList.userAttributes?.userAuthSource || "-"}
                      </TableCell>
                      <TableCell align="center">
                        {convertUTCDateToLocalDate(userList.createdAt) || "-"}
                      </TableCell>
                      <TableCell align="center">
                        {convertUTCDateToLocalDate(userList.updatedAt) || "-"}
                      </TableCell>
                      {selectedUserRole &&
                      selectedUserRole === "MobileUserGroup" ? (
                        <TableCell align="center">
                          <VisibilityIcon
                            style={{
                              fontSize: "120%",
                              color: "#4822B4",
                            }}
                            title={"View Device Data"}
                            onClick={() =>
                              handleOpenModal(userList?.deviceDetails)
                            }
                          />
                        </TableCell>
                      ) : null}
                      {selectedUserRole &&
                      selectedUserRole.toLowerCase() === "admin" ? (
                        <TableCell align="center">
                          <EditIcon
                            style={{
                              fontSize: "120%",
                              color: "#4822B4",
                            }}
                            title={"Edit Permissions"}
                            onClick={() =>
                              handleEditPermission(
                                userList.userAttributes.userName,
                              )
                            }
                          />
                        </TableCell>
                      ) : null}
                    </TableRow>
                  );
                })}
            </TableBody>
          </Table>
        </TableContainer>
        <br></br>
        <div className="pagenate">
          <h6>Available User List</h6>
          <div>
            <ReactPaginate
              previousLabel={<FaBackward />}
              nextLabel={<FaForward />}
              breakLabel={"...."}
              marginPagesDisplayed={2}
              pageCount={totalRecords}
              onClick={changePage}
              containerClassName={"paginationBttns"}
              previousLinkClassName={"previousBttns"}
              disabledClassName={"paginationDisabled"}
              activeClassName={"paginationActive"}
            ></ReactPaginate>
          </div>
          <h6>Total Records : {dataLength}</h6>
        </div>
      </Paper>
    </div>
  );
}
