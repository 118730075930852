import { DialogContent, Typography } from "@mui/material";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import "bootstrap/dist/css/bootstrap.min.css";
import log from "loglevel";
import React, { useContext, useState } from "react";
// import { useNavigate } from "react-router-dom";
import { AuthContext } from "../../App";
import TenantModal from "../../modals/tenantModal";
import "./onboard.css";

var apigClientFactory = require("aws-api-gateway-client").default;

export default function TenantOnboard() {
  const { cred } = useContext(AuthContext);
  const { tokens } = useContext(AuthContext);
  const accessKeyId = cred.accessKeyId;
  const SecretKey = cred.secretAccessKey;
  const SessionToken = cred.sessionToken;
  const region = tokens.region;

  const config = {
    invokeUrl: process.env.REACT_APP_SERVICE_URL_v2,
    region: region,
    accessKey: accessKeyId,
    secretKey: SecretKey,
    sessionToken: SessionToken,
  };

  const apigClient = apigClientFactory.newClient(config);

  //accessing URLs required for tenant onboarding from .env
  // const socketURL = process.env.REACT_APP_SCOKET_URL;
  // const awsKey = process.env.REACT_APP_AWS_KEY;
  // const awsSecret = process.env.REACT_APP_AWS_SECRET;
  // const awsRegion = process.env.REACT_APP_AWS_CLI;

  // const [messages, setMessages] = useState([]); //state for updating messages from websocket
  // const [open, setOpen] = useState(false);  //handling dialog box
  // const [tenantDialog,setTenantDialog] = useState(false);
  const [openDialog, setOpenDialog] = useState(false); //success dialog
  // const [isValidForm, setIsValidForm] = useState(false);

  // eslint-disable-next-line no-unused-vars
  const [email, setEmail] = useState("");
  const [emailError, setEmailError] = useState("");

  const handleEmailChange = (e) => {
    updateFormdata({
      ...formdata,
      [e.currentTarget.id]: e.currentTarget.value,
    });
    const emailValue = e.target.value;
    setEmail(emailValue);

    if (emailValue && !validateEmail(emailValue)) {
      setEmailError("Invalid email format. Please enter valid Email");
    } else {
      setEmailError("");
    }
  };

  const validateEmail = (emailValue) => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailRegex.test(emailValue);
  };

  // const navigate = useNavigate();

  const onHandleCloseDialog = () => {
    setOpenDialog(false);
  };

  // const onHandleClose = () => {
  //   setTenantDialog(false); //closes dialog box
  //   localStorage.removeItem("TenantID");
  // }

  // const onHandleCloseProgressDialog = () => {
  //   setOpen(false); //closes dialog box
  //   setTenantDialog(true);
  // }

  // const SOCKET_URL = `${socketURL}`;
  //to receive messges from webSocket on successful connection
  // const onMessageReceived = (msg) => {
  //   setMessages(messages.concat(msg));
  //   console.log(messages);
  // };

  // //called if webaSocket is connected
  // let onConnected = () => {
  //   log.info("Connected!!");
  // };

  // let onDisconnect = () => {
  //   console.log("Disconnected!");
  // }

  const [selectedFile, setSelectedFile] = useState({
    tenant_logo_filename: "", //state for handling tenant logo file
  });
  // eslint-disable-next-line no-unused-vars
  const [fileSize, setFileSize] = useState();
  const [fileName, setFileName] = useState();
  // const { tenant_logo_filename } = selectedFile;
  // const { tenant_theme } = themeFile;
  const [errorMsg, setErrorMsg] = useState(false);
  const [isSuccess, setIsSuccess] = useState(false);

  const onHandleFile = (e) => {
    const file = e.target.files[0];
    const filename = e.target.files[0].name;
    if (file) {
      const selectedFileSize = e.target.files[0].size;
      setFileSize(selectedFileSize);
      validateSelectedFile(selectedFileSize);
      setFileName(filename);
    }
    if (file) {
      const reader = new FileReader(); //to convert file format to base64 String format
      reader.readAsDataURL(file); //will be useful when passing files to backend
      reader.onloadend = () => {
        setSelectedFile(reader.result);
      };
    }
  };

  //to validate the tenant logoFile whether it is as per size mentioned or not
  const validateSelectedFile = (fileSize) => {
    // const MIN_FILE_SIZE = 10 // 1MB
    const MAX_FILE_SIZE = 100;
    const fileSizeKiloBytes = fileSize / 1024;
    // console.log(fileSizeKiloBytes);
    if (fileSizeKiloBytes > MAX_FILE_SIZE) {
      setErrorMsg("File size is greater than maximum limit");
      setIsSuccess(false);
      return;
    } else {
      setIsSuccess("File size and format is validated");
      // setIsValidForm(true)
      setErrorMsg(true);
    }
  };

  //to display responses when user uploading file(logo)
  const [responsemsg, setResponseMsg] = useState(false);
  const [successresponsemsg, setSuccessResponseMsg] = useState(false);
  const [confirmDialog, setConfirmDialog] = useState({
    isOpen: false,
    title: "",
    subtitle1: "",
    subtitle2: [""],
  });

  //initializing formdata with required fields for tenant onboarding
  const [formdata, updateFormdata] = useState({
    tenant_domain: "",
    tenant_name: "",
    tenant_user_name: "",
    tenant_owner_email_id: "",
    tenant_description: "",
    tenant_logo_filename: "",
    tenant_theme: "",
    tenant_temp_pass_expiry: 1,
    tenant_password_length: 6,
    tenant_password_require_number: false,
    tenant_password_require_special: false,
    tenant_password_require_uppercase: false,
    tenant_password_require_lowercase: false,
    tenant_mfa: "OPTIONAL",
    tenant_remember_device: "false",
    tenant_web_client_access: "true",
  });

  const {
    tenant_domain,
    tenant_name,
    tenant_user_name,
    tenant_owner_email_id,
    tenant_description,
    tenant_theme,
    tenant_temp_pass_expiry,
    tenant_password_length,
    tenant_password_require_number,
    tenant_password_require_special,
    tenant_password_require_uppercase,
    tenant_password_require_lowercase,
    tenant_mfa,
    tenant_remember_device,
    tenant_web_client_access,
  } = formdata;

  //this data will be passed to backend for tenant onboarding with all required fields
  //this will ensure that data is in its original format
  const tenantStagingRequest = {
    tenant_name: `${tenant_name}`,
    tenant_user_name: `${tenant_user_name}`,
    tenant_description: `${tenant_description}`,
    tenant_domain: `${tenant_domain}`,
    tenant_owner_email_id: `${tenant_owner_email_id}`,
    tenant_logo_filename: selectedFile,
    tenant_theme: `${tenant_theme}`,
    callback_url: process.env.REACT_APP_REDIRECT_URL,
    logout_url: process.env.REACT_APP_REDIRECT_URL,
    tenant_password_length: parseInt(tenant_password_length),
    tenant_password_require_number: tenant_password_require_number
      ? true
      : false,
    tenant_password_require_special: tenant_password_require_special
      ? true
      : false,
    tenant_password_require_lowercase: tenant_password_require_lowercase
      ? true
      : false,
    tenant_password_require_uppercase: tenant_password_require_uppercase
      ? true
      : false,
    tenant_temp_pass_expiry: parseInt(tenant_temp_pass_expiry),
    tenant_mfa: `${tenant_mfa}`,
    tenant_remember_device: tenant_remember_device === "true" ? true : false,
    tenant_web_client_access:
      tenant_web_client_access === "true" ? true : false,
  };

  const onHandleChange = (e) => {
    //handles input entered by user
    updateFormdata({
      ...formdata,
      [e.currentTarget.id]: e.currentTarget.value,
    });
  };

  /* Adding headers for Websocket client 
    Date : 03/07/2023
    Modified By : Abhijit Roy */

  // const customHeaders = {
  //   "Access-Control-Allow-Origin": "https://embfotaconnect.info"

  // };
  //checks if tenant domain given by user is available or not
  const checkAvailability = async () => {
    var pathParams = {};
    // Template syntax follows url-template https://www.npmjs.com/package/url-template
    var pathTemplate = `/cognitoservice/userpooldomain/${tenant_domain}`;
    var method = "GET";
    var additionalParams = {
      headers: {
        "aws-key": accessKeyId,
        "aws-secret": SecretKey,
        "cli-region": region,
        "aws-session": SessionToken,
      },
    };

    //var additionalParams = {};
    var body = {};
    try {
      const specialCharsRegex = /[^a-zA-Z0-9-]/;
      if (specialCharsRegex.test(tenant_domain)) {
        setSuccessResponseMsg(false);
        setResponseMsg("Domain name should not contain special characters");
        return;
      }

      if (tenant_domain.startsWith("-") || tenant_domain.endsWith("-")) {
        setSuccessResponseMsg(false);
        setResponseMsg(
          "Domain name should not contain hyphens at the start or end",
        );
        return;
      }

      await apigClient
        .invokeApi(pathParams, pathTemplate, method, additionalParams, body)
        .then((resp) => {
          // console.log(resp);
          const responseData = resp.data;
          if (
            responseData.userPool?.DomainDescription?.Domain === tenant_domain
          ) {
            setResponseMsg(
              "Domain Not Available,Please Enter Another Domain Name",
            );
            setSuccessResponseMsg(false);
          } else if (responseData.message) {
            const responsemsg1 = responseData.message;
            const responseList = responsemsg1
              .split(",")
              .map((sentence, index) => <li key={index}>{sentence}</li>);
            setResponseMsg(responseList);
            setSuccessResponseMsg(false);
          } else {
            setSuccessResponseMsg(
              "Domain Available,Please continue With Other Fields",
            );
            setResponseMsg(false);
          }
        });
    } catch (err) {
      // console.log(err);
      // const responsemsg1 = err.response.data.message
      // const responseList = responsemsg1.split(',').map((sentence, index) => (
      //   <li key={index}>{sentence}</li>
      // ));
      // setResponseMsg(responseList);
      // setSuccessResponseMsg(false);
      log.error("Error fetching domain details", err);
    }
  };

  //sends all the details required for tenant onboarding and displays responses to user
  const onboardTenantRequest = async () => {
    try {
      // console.log(tenantStagingRequest);
      await apigClient
        .invokeApi({}, "/tenant/tenantid", "GET", {}, {})
        .then(async (resp2) => {
          const tenantId = resp2.data;
          // localStorage.setItem("TenantID", JSON.stringify(tenantId));
          var pathParams = {};
          // Template syntax follows url-template https://www.npmjs.com/package/url-template
          var pathTemplate = "/tenantservice/tenant";
          var method = "POST";
          var additionalParams = {
            headers: {
              "Content-Type": "application/json",
              "tenant-id": tenantId,
            },
          };

          //var additionalParams = {};
          var body = tenantStagingRequest;
          await apigClient
            .invokeApi(pathParams, pathTemplate, method, additionalParams, body)
            .then(async (resp) => {
              // const tenantStagingId = resp.data;
              // console.log(tenantStagingId);
              setOpenDialog(true);
            });
        });
    } catch (err) {
      log.error("Error sending requests for onboarding tenant", err);
    }
  };

  const [selectedCheckboxes, setSelectedCheckboxes] = useState([]);
  const [checkboxError, setCheckboxerror] = useState("");

  const handleCheckboxChange = (e) => {
    const { id, checked } = e.target;

    updateFormdata({
      ...formdata,
      [id]: checked,
    });

    if (checked) {
      setSelectedCheckboxes([...selectedCheckboxes, id]);
      setCheckboxerror("");
    } else {
      setSelectedCheckboxes(
        selectedCheckboxes.filter((checkbox) => checkbox !== id),
      );
    }
  };

  const handleconfirmDialog = (e) => {
    if (selectedCheckboxes.length === 0) {
      setCheckboxerror("Please select at least one checkbox.");
      return;
    }
    setConfirmDialog({
      isOpen: true,
      title: "Confirm Action",
      subtitle1: "Are You Sure To Submit Request",
      onConfirm: () => {
        onHandleSubmit(e);
      },
    });
  };

  //handles all actions on submitting form
  const onHandleSubmit = (e) => {
    e.preventDefault();
    setConfirmDialog({
      ...confirmDialog,
      isOpen: false,
    });
    onboardTenantRequest();
    // updateFormdata({
    //   tenant_description: ''
    // });
    // e.target.reset();
    // navigate('../dashboard');
  };

  return (
    <div>
      {openDialog && (
        <TenantModal
          openDialog={openDialog}
          onHandleClose={onHandleCloseDialog}
        />
      )}
      {/* initializing web socket after form submission to get status of onboarding process */}
      {/* <SockJsClient
        url={SOCKET_URL}
        headers= {customHeaders}
        topics={["/topic/group"]}
        onConnect={onConnected}
        onDisconnect={onDisconnect}
        onMessage={(msg) => onMessageReceived(msg)}
        debug={false}
      /> */}

      <form>
        <h2>Branding</h2>
        <div className="brand">
          <div className="form-group row">
            <label className="col-sm-2 col-form-label">Domain</label>
            <div className="input-group mb-3">
              <div className="input-group-prepend">
                <span className="input-group-text" id="basic-addon3">
                  https://embfota-
                </span>
              </div>
              <div className="col-sm-3">
                <input
                  type="string"
                  className="form-control"
                  id="tenant_domain"
                  value={tenant_domain}
                  placeholder="Enter Domain"
                  maxLength={50}
                  required
                  onChange={onHandleChange}
                />
              </div>
              <div className="input-group-append">
                <span className="input-group-text" id="basic-addon2">
                  .auth.ap.south-1.amazoncognito.com
                </span>
              </div>
              <div className="check">
                <div className="col-sm-2">
                  <button
                    type="submit"
                    onClick={(e) => checkAvailability(e)}
                    className="btn btn-dark"
                  >
                    Check Availability
                  </button>
                </div>
              </div>
              <br />
              <div>
                <ul>
                  <p></p>
                  <p className="error-message">{responsemsg}</p>
                  <p className="success-message">{successresponsemsg}</p>
                </ul>
              </div>
            </div>
          </div>
          <br></br>
          <div className="form-group row">
            <div className="form-group col-md-8">
              <label>User Name</label>
              <input
                type="string"
                className="form-control"
                value={tenant_user_name}
                id="tenant_user_name"
                placeholder="Enter User Name"
                maxLength={50}
                required
                onChange={onHandleChange}
              />
            </div>
            <div className="form-group col-md-6">
              <label>Brand Name</label>
              <input
                type="string"
                className="form-control"
                value={tenant_name}
                id="tenant_name"
                placeholder="Enter Brand Name"
                maxLength={50}
                required
                onChange={onHandleChange}
              />
            </div>
            <div className="form-group col-md-6">
              <label>Mail ID</label>
              <input
                type="email"
                className="form-control"
                value={tenant_owner_email_id}
                id="tenant_owner_email_id"
                placeholder="Enter Brand Owner Mail ID"
                required
                onChange={handleEmailChange}
              />
              {emailError && <div className="text-danger">{emailError}</div>}
            </div>
          </div>
          {/* <br></br> */}
          <div className="form-group row">
            <label>Description</label>
            <div className="col-sm-12">
              <textarea
                className="form-control"
                placeholder="Enter Description"
                value={tenant_description}
                id="tenant_description"
                required
                maxLength={100}
                rows={3}
                onChange={onHandleChange}
              />
            </div>
          </div>
          {/* <br></br> */}
          <div className="form-group row">
            <label>Brand Logo</label>
            <div className="col-sm-9">
              <input
                type="file"
                className="form-control"
                required
                // value={tenant_logo_filename}
                id="tenant_logo_filename"
                accept=".jpeg,.png"
                onChange={onHandleFile}
              />
              <small id="passwordHelpBlock" className="form-text text-muted">
                Please Upload File of Format JPEG/PNG and File Size Below 100KB
              </small>
              <p className="error-message">{errorMsg}</p>
              <p className="success-message">{isSuccess}</p>
            </div>
          </div>
          {/* <br></br> */}
          <fieldset className="form-group">
            <div className="row">
              <legend className="col-form-label">Theme</legend>
              <div className="col-sm-10">
                <div className="form-check form-check-inline">
                  <input
                    className="form-check-input"
                    type="radio"
                    name="gridRadios"
                    id="tenant_theme"
                    value={"red-black"}
                    onChange={onHandleChange}
                  />
                  <label className="form-check-label">Red & Black</label>
                </div>
                <div className="form-check form-check-inline">
                  <input
                    className="form-check-input"
                    type="radio"
                    name="gridRadios"
                    id="tenant_theme"
                    value={"blue-green"}
                    onChange={onHandleChange}
                  />
                  <label className="form-check-label">Blue & Green</label>
                </div>
                <div className="form-check form-check-inline">
                  <input
                    className="form-check-input"
                    type="radio"
                    name="gridRadios"
                    id="tenant_theme"
                    value={"ocean-green-yellow"}
                    onChange={onHandleChange}
                  />
                  <label className="form-check-label">
                    Ocean Green & Yellow
                  </label>
                </div>
              </div>
            </div>
          </fieldset>
        </div>
        <br></br>
        <h2>Access Policy</h2>
        <br></br>
        <div className="policy">
          <div className="form-group row">
            <label className=" col-form-label">
              Password Length (Characters)
            </label>
            <div className="col-sm">
              <input
                type="number"
                min={6}
                max={99}
                className="form-control"
                value={tenant_password_length}
                id="tenant_password_length"
                placeholder="Enter password character length"
                required
                onChange={onHandleChange}
              />
            </div>
            <small id="passwordHelpBlock" className="form-text text-muted">
              Password length should be minimum of 6 characters to maximum of 99
              characters
            </small>
          </div>
          <br></br>
          <div className="form-group row">
            <div className="col-sm-10">
              <div className="form-check form-check-inline">
                <input
                  className="form-check-input"
                  type="checkbox"
                  id="tenant_password_require_number"
                  // value={true}
                  checked={formdata.tenant_password_require_number}
                  onChange={handleCheckboxChange}
                />
                <label className="form-check-label">Numbers</label>
              </div>
              <div className="form-check form-check-inline">
                <input
                  className="form-check-input"
                  type="checkbox"
                  id="tenant_password_require_special"
                  // value={true}
                  checked={formdata.tenant_password_require_special}
                  onChange={handleCheckboxChange}
                />
                <label className="form-check-label">Special Characters</label>
              </div>
              <div className="form-check form-check-inline">
                <input
                  className="form-check-input"
                  type="checkbox"
                  id="tenant_password_require_uppercase"
                  // value={true}
                  checked={formdata.tenant_password_require_uppercase}
                  onChange={handleCheckboxChange}
                />
                <label className="form-check-label">Uppercase letters</label>
              </div>
              <div className="form-check form-check-inline">
                <input
                  className="form-check-input"
                  type="checkbox"
                  id="tenant_password_require_lowercase"
                  // value={true}
                  checked={formdata.tenant_password_require_lowercase}
                  onChange={handleCheckboxChange}
                />
                <label className="form-check-label">Lowercase letters</label>
              </div>
            </div>
            <small id="passwordHelpBlock" className="form-text text-muted">
              Password should contain atleast one of the above given options
            </small>
            {checkboxError && (
              <div className="text-danger">{checkboxError}</div>
            )}
          </div>
          <br></br>
          <div className="form-group row">
            <label className="col-form-label">
              Temporary Password Expiration (Days)
            </label>
            <div className="col-sm">
              <input
                type="number"
                min={1}
                className="form-control"
                value={tenant_temp_pass_expiry}
                id="tenant_temp_pass_expiry"
                placeholder="Enter Duration of Temporary Password in Days"
                required
                onChange={onHandleChange}
              />
            </div>
          </div>
          <br></br>
          <div className="form-group row">
            <div className="form-group col-md-6">
              <label className="col-form-label">
                Multi Factor Authentication
              </label>
              <div className="col-sm-8">
                <div className="form-check form-check-inline">
                  <input
                    className="form-check-input"
                    type="radio"
                    name="inlineRadioOptions1"
                    id="tenant_mfa"
                    value={"OPTIONAL"}
                    defaultChecked
                    onChange={onHandleChange}
                  />
                  <label className="form-check-label">Optional</label>
                </div>
                <div className="form-check form-check-inline">
                  <input
                    className="form-check-input"
                    type="radio"
                    name="inlineRadioOptions1"
                    id="tenant_mfa"
                    value={"ON"}
                    onChange={onHandleChange}
                  />
                  <label className="form-check-label">Required</label>
                </div>
              </div>
            </div>
            <div className="form-group col-md-6">
              <label className="col-form-label">Remember user device</label>
              <div className="col-sm-8">
                <div className="form-check form-check-inline">
                  <input
                    className="form-check-input"
                    type="radio"
                    name="inlineRadioOptions2"
                    id="tenant_remember_device"
                    value={true}
                    onChange={onHandleChange}
                  />
                  <label className="form-check-label" htmlFor="inlineRadio1">
                    Required
                  </label>
                </div>
                <div className="form-check form-check-inline">
                  <input
                    className="form-check-input"
                    type="radio"
                    name="inlineRadioOptions2"
                    id="tenant_remember_device"
                    value={false}
                    defaultChecked
                    onChange={onHandleChange}
                  />
                  <label className="form-check-label" htmlFor="inlineRadio2">
                    Not Required
                  </label>
                </div>
              </div>
            </div>
          </div>
          <br></br>
          <div className="form-group col-md-6">
            <label className="col-form-label">Access To Web Client</label>
            <div className="col-sm-8">
              <div className="form-check form-check-inline">
                <input
                  className="form-check-input"
                  type="radio"
                  name="inlineRadioOptions3"
                  id="tenant_web_client_access"
                  value="true"
                  defaultChecked
                  onChange={onHandleChange}
                />
                <label className="form-check-label">Required</label>
              </div>
              <div className="form-check form-check-inline">
                <input
                  className="form-check-input"
                  type="radio"
                  name="inlineRadioOptions3"
                  id="tenant_web_client_access"
                  value={false}
                  onChange={onHandleChange}
                />
                <label className="form-check-label">Not Required</label>
              </div>
            </div>
          </div>
        </div>
        <br></br>
        <div className="form-group row">
          <div className="col-sm-3">
            <div>
              <button
                type="button"
                className="btn btn-dark"
                // opens confirmation dialog before initializing onbording with all details
                // entered by user for user confirmation
                onClick={(e) => handleconfirmDialog(e)}
              >
                Submit
              </button>
            </div>
          </div>
        </div>
      </form>
      <Dialog
        open={confirmDialog.isOpen}
        confirmDialog={confirmDialog}
        setConfirmDialog={setConfirmDialog}
      >
        <DialogContent>
          <Typography variant="h6">
            Please confirm Onboarding Details
          </Typography>
          <p>Tenant Domain : {tenant_domain}</p>
          <p>Brand Name : {tenant_name}</p>
          <p>Mail ID : {tenant_owner_email_id}</p>
          <p>Description : {tenant_description}</p>
          <p>Brand Logo : {fileName}</p>
          <p>Theme: {tenant_theme}</p>
          <h6>Password Policy</h6>
          <p>Password length : {tenant_password_length}</p>
          <p>Numbers : {tenant_password_require_number ? "true" : "false"}</p>
          <p>
            Special Characters :{" "}
            {tenant_password_require_special ? "true" : "false"}
          </p>
          <p>
            Uppercase letters :{" "}
            {tenant_password_require_uppercase ? "true" : "false"}
          </p>
          <p>
            Lowercase Letters :{" "}
            {tenant_password_require_lowercase ? "true" : "false"}
          </p>
          <p>Temporary Password Expiration : {tenant_temp_pass_expiry}</p>
          <p>Multi factor Authentication : {tenant_mfa}</p>
          <p>Remember Device : {tenant_remember_device}</p>
          <p>Access To Web client : {tenant_web_client_access}</p>
        </DialogContent>
        <DialogActions>
          <Button
            style={{
              color: "#19172C",
            }}
            variant="outlined"
            onClick={() =>
              setConfirmDialog({ ...confirmDialog, isOpen: false })
            }
          >
            Cancel
          </Button>
          <Button
            style={{
              backgroundColor: "#19172C",
            }}
            variant="contained"
            onClick={confirmDialog.onConfirm}
          >
            Submit
          </Button>
        </DialogActions>
      </Dialog>
      {/* opens progress dialog to dispaly status of onboarding based on responses from web Socket */}
      {/* {open && <ViewProgress open={open} handleClose={onHandleCloseProgressDialog} messages={messages} />}
      {tenantDialog && <ViewTenantData open={tenantDialog} handleClose={onHandleClose}/>} */}
    </div>
  );
}
