import React, { forwardRef, useImperativeHandle, useState } from 'react'

function SideComponent(props,ref) {
    const [toggle,setToggle] = useState(true)
    useImperativeHandle(ref,()=>({
        isToggled:()=>{
            setToggle(!toggle)
        }
    }))
  return (
    <div style={{width:toggle?"15%":"5%"}}/>
  )
}

export default forwardRef(SideComponent)