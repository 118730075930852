import React from 'react'
import Breadcrumb from '../../breadCrumbs/navigation'

const CreateHeader = ({pagehd}) => {
  return (
    <div>
        <Breadcrumb/>
        <h4>{pagehd}</h4>
    </div>
  )
}

export default CreateHeader