import queryString from "query-string";

const getCredentials = () => {
  const { tenant_id, userpool_id, identity_pool_id, client_id, region } =
    queryString.parse(window.location.search);

  return { tenant_id, userpool_id, identity_pool_id, client_id, region };
};

const getLocalStorageData = (key) => {
  const data = localStorage.getItem(key);
  if (data) {
    return JSON.parse(data);
  } else {
    null;
  }
};

const setLocalStorage = async (key, data) => {
  localStorage.setItem(key, JSON.stringify(data));
};

const getRefreshTime = (exp, before) => {
  const expiresAt = new Date(exp * 1000);
  const now = new Date();
  const refreshTime = expiresAt - now - before * 60 * 1000; // Refresh tokens 5 minutes before they expire
  return refreshTime;
};

const filterPagesByRole = (pages, rules) => {
  const visibleRules = rules.filter((rule) => rule.visible);

  return pages
    .map((page) => {
      const rule = visibleRules.find((rule) => rule.id === page.id);

      if (rule) {
        const editable = rule.editable;
        const visibleChild =
          rule.child && rule.child.filter((rule) => rule.visible);
        const childRule =
          visibleChild?.length &&
          pages.filter((p) =>
            rule.child.some((child) => p.id === child.id && child.visible)
          );

        return {
          ...page,
          editable,
          child: childRule || false,
        };
      } else {
        return false
      }
    })
    .filter((page) => page.editable !== undefined);
};

export {
  getCredentials,
  getLocalStorageData,
  setLocalStorage,
  getRefreshTime,
  filterPagesByRole,
};
